import classNames from "classnames/bind";
import { FaPhoneAlt, FaRegClock } from "react-icons/fa"
import { IoCalendarOutline } from "react-icons/io5"

import styles from "./InfoContact.module.scss"

const cx = classNames.bind(styles);

function InfoContactHorizontal({className}) {
    return (
      <div className={cx("d-flex", className)}>
        <div className={cx("d-flex align-items-center me-5")}>
          <FaPhoneAlt className={cx("me-3")} />
          <div className={cx("")}>
            <div className={cx("fw-6")}>0866.678.665</div>
            <div>hotro@gmail.com</div>
          </div>
        </div>
        <div className={cx("d-flex align-items-center me-5")}>
          <FaRegClock className={cx("me-3")} />
          <div className={cx("")}>
            <div className={cx("fw-6")}>08h - 17h</div>
            <div>Thứ 2 - Thứ 7</div>
          </div>
        </div>
        <div className={cx("d-flex align-items-center")}>
          <IoCalendarOutline className={cx("me-3")} />
          <div className={cx("")}>
            <div className={cx("fw-6")}>Đặt lịch</div>
            <div>Đặt lịch xem nhà trước</div>
          </div>
        </div>
      </div>
    )
}

export default InfoContactHorizontal;