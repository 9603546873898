import { useState, useEffect } from "react"
import CandidateList from "./CandidateLists"
import { LoadingSpinner } from "../../../components/Loading"
import SearchUserLists from "./SearchUserLists"

function ManagerUserLists() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [hasMoreData, setHasMoreData] = useState(true)
  console.log()
  const limit = 20

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        process.env.REACT_APP_APIGetDataMarketUser +
          `?quantity=${limit}&offset=${(page - 1) * limit}`,
      )
      const dataRes = await response.json()
      setData(dataRes)
      setLoading(false)
      if (dataRes.length === 0) {
        setHasMoreData(false)
      }
    }
    fetchData()
  }, [page])

  // Xử lý load more.
  const handleScroll = () => {
    const container = document.getElementById("managerUserLists")
    const totaHeight = container.scrollHeight
    const setHeight = container.offsetHeight
    const scrollY = container.scrollTop
    if (setHeight + scrollY + 1 >= totaHeight && hasMoreData) {
      setTimeout(() => {
        setPage((old) => old + 1)
      }, 500)
    }
  }

  useEffect(() => {
    const container = document.getElementById("managerUserLists")
    if (container) {
      container.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll)
      }
    }
  }, [page])

  //   Data search.
  const handleGetDataSearch = (dataSearch) => {
    console.log(dataSearch)
    setData(dataSearch)
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      <div className="col-xl-6 col-md-12 d-flex justify-content-end px-3">
        <SearchUserLists result={handleGetDataSearch} />
      </div>
      {data.length > 0 && (
        <CandidateList
          data={data}
          id="managerUserLists"
          height="calc(100vh - 12rem)"
        />
      )}
    </div>
  )
}

export default ManagerUserLists
