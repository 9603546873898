import io from "socket.io-client"
import { useEffect, useLayoutEffect, useState } from "react"
import classNames from "classnames/bind"
import moment from "moment"
import Tippy from "@tippyjs/react/headless"
import { PiEyeThin, PiCursorClickLight, PiTimer } from "react-icons/pi"
import { GrTransaction } from "react-icons/gr"
import { IoCalendarOutline } from "react-icons/io5"
import { CiEdit } from "react-icons/ci"

import ListBooking from "./ListBooking"
import styles from "../../Dashboard.module.scss"
import SliderImage from "../../../../../components/Slider"
import {
  handleFormatPrice,
  handleLimitTitle,
} from "../../../../../handle-reuses/reuses"
import ModalReact from "../../../../../components/Modal/ModalReact"
import FormEditRealEstateFast from "../../../../../components/Form/FormEditRealEstateFast"
import TimeExtension from "./TimeExtension"
import DetailProduct from "./DetailProduct"
import { handleMethodEditData } from "../../../../../handle-reuses/reuses/method"
import { images } from "../../../../../assets/images"

const cx = classNames.bind(styles)

function ListProduct({ data, dataBooking }) {
  const [dataImage, setDataImage] = useState([])
  const [loadingImages, setLoadingImages] = useState(true)
  const fetchData = async () => {
    let dataResImage = []
    // Get image.
    for (let i = 0; i < data.length; i++) {
      const responseImage = await fetch(
        process.env.REACT_APP_APIGetDataMarketSimpleImages +
          `?only=${data[i].id}`,
      )
      const dataRes = await responseImage.json()
      dataResImage.push(...dataRes)
      setTimeout(() => {
        setLoadingImages(false)
      }, 500)
    }

    setTimeout(() => {
      setDataImage(dataResImage)
    }, 100)
  }

  // Get list image
  useEffect(() => {
    if (data.length > 0) {
      fetchData()
    }
  }, [])

  // giới hạn title
  useLayoutEffect(() => {
    handleLimitTitle("product-title", 33)
    handleLimitTitle("product-description", 66)
  }, [])

  // Xử lý edit data.
  const [modalEdit, setModalEdit] = useState(false)
  const [idPro, setIdPro] = useState()
  const handleModalEditPro = (id_) => {
    setModalEdit(true)
    setIdPro(id_) // truyền id cho form
  }
  const handleClose = () => {
    setModalEdit(false)
    setIdPro() // trả về giá trị ban đầu
  }

  // handle transaction.
  const handleModalEditTransactionPro = (id_) => {
    const checkTransaction = window.confirm(
      "Chuyển trạng thái giao dịch cho sản phẩm?",
    )
    if (checkTransaction) {
      handleEditTransaction(id_)
    }
  }

  // Modal detail real estate.
  const [dataDetail, setDataDetail] = useState([])
  const [dataImagesDetail, setDataImagesDetail] = useState([])
  const [modalDetail, setModalDetail] = useState(false)
  const handleModalDetailEstate = (id) => {
    const dataFilter = data.filter((item) => {
      return item.id === id
    })
    const listImages = dataImage.filter((item) => {
      return item.simpleid === id
    })
    setDataDetail(dataFilter)
    setDataImagesDetail(listImages)
    setModalDetail(true)
  }
  const handleCloseDetail = () => {
    setModalDetail(false)
  }
  if (!data) {
    return "Chưa có sản phẩm"
  }

  return (
    <div className={cx("row mb-3")}>
      {data.map((item) => {
        const filterImages = dataImage.filter((row) => {
          return row.simpleid === item.id
        })
        const filterDataBooking = dataBooking.filter((row) => {
          return row.simpleid === item.id
        })
        // Format time.
        let endDate = moment(item.endtime)
        let currentDate = moment()
        let diffDate = endDate.diff(currentDate, "days")
        return (
          <div
            className={cx(
              "col-xxl-3",
              "col-xl-6",
              "col-lg-6",
              "col-md-6",
              "col-sm-12",
              "mb-4",
            )}
            key={item.id}
          >
            <div className={cx("custom-card", "product-card", "border-1")}>
              <div>
                {/* Hiển thị list images */}
                {/* loading images */}
                {loadingImages && (
                  <div
                    className="placeholder-glow"
                    style={{ height: "283px", marginBottom: "0.33rem" }}
                  >
                    <div
                      className={cx("card-img", "placeholder")}
                      style={{ borderRadius: "15px", height: "100%" }}
                    ></div>
                  </div>
                )}
                {/* show images */}
                {filterImages.length > 0 && !loadingImages ? (
                  <SliderImage data={filterImages} />
                ) : (
                  !loadingImages && (
                    <img
                      src={images.noImagePro}
                      className="d-block w-100"
                      alt="..."
                      height={283}
                      style={{ borderRadius: "15px" }}
                    />
                  )
                )}

                {/* Icon ad */}
                {item.status === 1 && diffDate > 0 ? (
                  <div
                    className="position-absolute"
                    style={{ left: "9px", top: "12px" }}
                  >
                    <span className="badge bg-success op-8 rounded-5 me-2">
                      Quảng cáo
                    </span>
                    <span
                      className="d-flex bg-warning align-items-center fs-12 mt-2 px-1 rounded-3 op-9"
                      // style={{
                      //   backgroundColor: "#ffffff8a",
                      //   padding: "0 6px",
                      //   borderRadius: "21px",
                      // }}
                    >
                      <PiTimer />
                      {moment(item.endtime).diff(moment(), "days")} ngày nữa
                    </span>
                    {/* Tình trạng giao dịch */}
                    <span>
                      {item.transaction === 1 ? (
                        <div className="badge bg-danger op-8 mt-2 rounded-3">
                          Đã giao dịch
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ) : (
                  <span
                    className="badge bg-danger position-absolute op-8 rounded-5"
                    style={{ left: "9px", top: "12px" }}
                  >
                    {item.status === 1 ? "Hết hạn" : ""}
                  </span>
                )}

                {/* Hiển thị thông tin */}
                <div className={cx("product-icons")}>
                  {/* Sửa thông tin */}
                  <Tippy
                    interactive
                    delay={[0, 0]}
                    placement="left"
                    render={() => (
                      <div
                        style={{
                          background: "black",
                          padding: "0 9px",
                          color: "white",
                          borderRadius: "9px",
                        }}
                      >
                        Sửa thông tin
                      </div>
                    )}
                  >
                    <a
                      href="#"
                      className={cx("rounded-5", "edit")}
                      onClick={() => handleModalEditPro(item.id)}
                    >
                      <CiEdit />
                    </a>
                  </Tippy>
                  {/* Điều kiện hiển thị nút gia hạn */}
                  {item.status === 1 && diffDate > 0 ? (
                    ""
                  ) : (
                    <Tippy
                      interactive
                      delay={[0, 0]}
                      placement="bottom-end"
                      render={() => (
                        <div
                          style={{
                            background: "white",
                            padding: "0 9px",
                            color: "black",
                            borderRadius: "9px",
                          }}
                        >
                          <TimeExtension
                            id={item.id}
                            admoneyOld={item.admoney}
                            status={item.status}
                          />
                        </div>
                      )}
                    >
                      <a href="#" className={cx("rounded-5", "moretime")}>
                        <PiTimer />
                      </a>
                    </Tippy>
                  )}
                  {/* kiểm tra giao dịch */}
                  <Tippy
                    interactive
                    delay={[0, 0]}
                    placement="left"
                    render={() => (
                      <div
                        style={{
                          background: "black",
                          padding: "0 9px",
                          color: "white",
                          borderRadius: "9px",
                        }}
                      >
                        Xác nhận giao dịch
                      </div>
                    )}
                  >
                    <a
                      href="#"
                      className={cx("rounded-5", "transaction")}
                      onClick={() => handleModalEditTransactionPro(item.id)}
                    >
                      <GrTransaction />
                    </a>
                  </Tippy>
                </div>
                <div className="mt-2">
                  <div
                    className="cursor-pointer"
                    onClick={() => handleModalDetailEstate(item.id)}
                  >
                    <p className="product-name fs-15 fw-semibold mb-0 d-flex align-items-center justify-content-between">
                      <span className="product-title">
                        {item.address}, {item.districts}, {item.city}
                      </span>
                    </p>
                    <p className="product-description fs-15 text-muted fw-4 mb-1">
                      {item.title}
                    </p>
                  </div>
                </div>
                <p className="fs-14 mb-0 d-flex align-items-center mb-2">
                  <span className="text-success me-3">{item.cate}</span>{" "}
                  <span className="text-muted">
                    {moment(item.addtime).format("DD/MM/YYYY HH:mm")}
                  </span>
                </p>
                <p className="mb-1 fs-16 d-flex align-items-center justify-content-between">
                  <span>
                    <span className="fw-6 me-1 text-h">
                      {item.donvi !== "Thỏa thuận"
                        ? handleFormatPrice(Number(item.price))
                        : ""}
                    </span>
                    {item.donvi === "Thỏa thuận"
                      ? item.donvi
                      : " / " + item.donvi}
                  </span>
                  <span className="float-end fs-14 text-h d-flex">
                    <span className="me-3 d-flex align-items-center">
                      <PiEyeThin className="me-1" />{" "}
                      {/* {handleFormatNumberUnit(item.countview)} */}
                      <CountViewEstate
                        countview={item.countview}
                        idpro={item.id}
                      />
                    </span>
                    <span className="me-3 d-flex align-items-center">
                      <PiCursorClickLight className="me-1" />{" "}
                      <CountClickPhoneEstate
                        countclick={item.countclick}
                        idpro={item.id}
                      />
                    </span>
                    <Tippy
                      interactive
                      delay={[200, 0]}
                      placement="top-start"
                      render={() => (
                        <ListBooking
                          dataBooking={filterDataBooking}
                          dataPro={data}
                        />
                      )}
                    >
                      <span className="d-flex align-items-center">
                        <IoCalendarOutline className="me-1" />{" "}
                        {Number(filterDataBooking.length).toLocaleString()}
                      </span>
                    </Tippy>
                  </span>
                </p>
              </div>
            </div>
          </div>
        )
      })}
      {/* Modal edit product */}
      {idPro ? (
        <ModalReact
          id="editProductFast"
          size="modal-fade"
          theme={<FormEditRealEstateFast idPro={idPro} />}
          modalTitle="Sửa thông tin sản phẩm"
          showModal={modalEdit}
          handleClose={handleClose}
        />
      ) : (
        ""
      )}
      {/* Modal detail real estate */}
      {modalDetail ? (
        <ModalReact
          id="detailRealEstate"
          theme={
            <DetailProduct data={dataDetail} dataImages={dataImagesDetail} />
          }
          modalTitle="Chi tiết sản phẩm"
          showModal={modalDetail}
          handleClose={handleCloseDetail}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default ListProduct

// count view.
function CountViewEstate({ countview, idpro }) {
  const [updateView, setUpdateView] = useState(countview)

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER)
    socket.on("server-click-views", (data) => {
      const filter = data.find((item) => {
        return item.id === Number(idpro)
      })
      if (filter) {
        setUpdateView(filter.countview)
      }
    })
  }, [])
  return <span>{Number(updateView).toLocaleString().replace(/[.]/g, ",")}</span>
}

// count click phone.
function CountClickPhoneEstate({ countclick, idpro }) {
  const [updateClickPhone, setUpdateClickPhone] = useState(countclick)

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER)
    socket.on("server-click-phone", (dataUpdate) => {
      const filter = dataUpdate.find((item) => {
        return item.id === Number(idpro)
      })
      if (filter) {
        setUpdateClickPhone(filter.countclick)
      }
    })
  }, [])
  return (
    <span>
      {Number(updateClickPhone).toLocaleString().replace(/[.]/g, ",")}
    </span>
  )
}

// Xử lý transaction.
async function handleEditTransaction(id) {
  const response = await fetch(
    process.env.REACT_APP_APIGetDataMarketSimple + `?only=${id}`,
  )
  const dataRes = await response.json()
  if (dataRes[0].transaction === 0) {
    const object = {
      id: id,
      transaction: 1,
    }
    handleMethodEditData(
      object,
      () => {
        alert("Sản phẩm được xác nhận: Đã Giao Dịch.")
      },
      process.env.REACT_APP_APIEditTransactionMarketSimple,
    )
  } else {
    const object = {
      id: id,
      transaction: 0,
    }
    handleMethodEditData(
      object,
      () => {
        alert("Sản phẩm được xác nhận: Hủy Giao Dịch.")
      },
      process.env.REACT_APP_APIEditTransactionMarketSimple,
    )
  }
}
