import { useState } from "react"
import moment from "moment"
import { PiDotOutlineThin } from "react-icons/pi"

import { handleLimitString } from "../../../../../handle-reuses/reuses"
import ModalReact from "../../../../../components/Modal/ModalReact"
import StatusBooking from "./StatusBooking"

function Lichhen({ data, dataPro, result }) {
  // Quản lý chuyển trạng thái.
  const [idPro, setIdPro] = useState()
  const [mStatus, setMStatus] = useState(false)
  const handleStatus = (id_) => {
    setIdPro(id_)
    setMStatus(true)
  }
  const handleClose = () => {
    setIdPro()
    setMStatus(false)
  }
  // Kiểm tra trạng thái nếu có chuyển đổi.
  const handleCheckStatus = (res) => {
    result(res)
  }

  if (data.length === 0) {
    return <div>Chưa có dữ liệu.</div>
  }
  return (
    <>
      <div className="card-body p-0">
        <div className="table-responsive rounded-4">
          <table className="table text-nowrap">
            <tbody>
              {data.map((item) => {
                const filterPro = dataPro.find((row) => {
                  return row.id === item.simpleid
                })
                // addtime.
                let addtime = moment(item.addtime)
                let currentTime = moment()
                let diffDays = currentTime.diff(addtime, "days")
                if (diffDays >= 90) {
                  diffDays = moment(item.addtime).format("DD/MM/YYYY")
                } else {
                  diffDays = moment(item.addtime)
                    .locale("vi")
                    .startOf()
                    .fromNow()
                }
                // status.
                let status_ = item.status
                if (status_ === 0) {
                  status_ = (
                    <button className="badge bg-warning text-dark border-0 op-8 btn-wave">
                      Chờ xử lý
                    </button>
                  )
                } else if (status_ === 1) {
                  status_ = (
                    <button className="badge bg-secondary border-0 op-8 btn-wave">
                      Đang xử lý
                    </button>
                  )
                } else if (status_ === 2) {
                  status_ = (
                    <button className="badge bg-success border-0 op-8 btn-wave">
                      Đang xử lý
                    </button>
                  )
                }
                return (
                  <tr key={item.id} onClick={() => handleStatus(item.id)}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="lh-1">
                          <span className="avatar avatar-xs avatar-rounded me-2">
                            <img src="../assets/images/faces/4.jpg" alt="" />
                          </span>
                        </div>
                        <div className="align-items-center">
                          <p className="mb-0 fw-semibold text-dark-1">
                            {item.fullname}
                            <i className="bi bi-patch-check-fill text-success ms-2"></i>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>{status_}</td>
                    <td>
                      <div className="align-items-center">
                        <p className="mb-0">
                          <span className="text-muted">
                            <span
                              style={{ color: "#3f5189" }}
                              className="fs-15"
                            >
                              {item.fullphone} <PiDotOutlineThin />
                            </span>{" "}
                            <span
                              style={{ color: "rgb(248 113 113)" }}
                              className="fw-6"
                            >
                              Hẹn ngày{" "}
                              {moment(item.booking).format("DD/MM/YYYY HH:mm")}
                            </span>{" "}
                            <PiDotOutlineThin />{" "}
                          </span>
                          <span className="fs-14 fw-semibold text-dark-1">
                            {handleLimitString(item.discription, 36)}
                            <PiDotOutlineThin />
                          </span>
                          <span className="text-muted">
                            {handleLimitString(filterPro.title, 63)}
                          </span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-1">{diffDays}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      {idPro ? (
        <ModalReact
          modalTitle="Nội dung yêu cầu"
          showModal={mStatus}
          handleClose={handleClose}
          theme={
            <StatusBooking
              id={idPro}
              dataPro={dataPro}
              result={handleCheckStatus}
            />
          }
        />
      ) : (
        ""
      )}
    </>
  )
}

export default Lichhen
