import { useState, useContext } from "react"
import classNames from "classnames/bind"
import moment from "moment"

import styles from "../../pc/Dashboard/Dashboard.module.scss"
import SearchSimple from "../../../components/Search/SearchSimple"
import ListProduct from "../../pc/Dashboard/Component/Sanpham/ListProduct"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"

const cx = classNames.bind(styles)

function ListProducts({ data, dataBooking }) {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [activeTab, setActiveTab] = useState("home")
  const [dataEstates, setDataEstates] = useState(data)
  // Get value search.
  const handleGetValue = (valueText) => {
    if (valueText !== "") {
      const fetchData = async () => {
        const response = await fetch(
          process.env.REACT_APP_APIGetDataMarketSimple +
            `?keyword=${valueText}&phone=${dataUser[0].sodienthoai}`,
        )
        const dataRes = await response.json()
        if (dataRes.length > 0) {
          setDataEstates(dataRes)
        } else {
          alert("Không có kết quả phù hợp.")
        }
      }
      fetchData()
    } else {
      // Trả về mảng ban đầu
      setDataEstates(data)
    }
  }

  // Data không quảng cáo
  const dataNoAd = dataEstates.filter((item) => {
    return item.status === 0
  })
  // data hết hạn.
  const dataExpired = dataEstates.filter((item) => {
    let endDate = moment(item.endtime)
    let currentDate = moment()
    let diffTime = endDate.diff(currentDate, "days")
    return item.status === 1 && diffTime <= 0
  })
  // data quảng cáo.
  const dataAd = dataEstates.filter((item) => {
    let endDate = moment(item.endtime)
    let currentDate = moment()
    let diffTime = endDate.diff(currentDate, "days")
    return item.status === 1 && diffTime > 0
  })

  const handleTabSelect = (key) => {
    setActiveTab(key)
  }

  return (
    <div className="tab-style-2">
      <div className={cx("wrapper_search_cate")}>
        <div className="col-xl-4 col-12 d-flex justify-content-end mb-3">
          <SearchSimple result={handleGetValue} />
        </div>
        <div className={cx("cateProducts")}>
          <ul className="d-flex" style={{ width: "550px" }}>
            <li className="me-2">
              <button
                onClick={() => handleTabSelect("home")}
                className="btn btn-outline-dark"
              >
                Tất cả ({Number(data.length).toLocaleString()})
              </button>
            </li>

            <li className="me-2">
              <button
                onClick={() => handleTabSelect("hethan")}
                className="btn btn-outline-dark"
              >
                Hết hạn ({Number(dataExpired.length).toLocaleString()})
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => handleTabSelect("quangcao")}
                className="btn btn-outline-dark"
              >
                Quảng cáo ({Number(dataAd.length).toLocaleString()})
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => handleTabSelect("no-ad")}
                className="btn btn-outline-dark"
              >
                Không quảng cáo ({Number(dataNoAd.length).toLocaleString()})
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Nội dung của tab được hiển thị dựa trên activeTab */}
      <div className={cx("mt-5")}>
        {activeTab === "home" && (
          <ListProduct data={dataEstates} dataBooking={dataBooking} />
        )}
        {activeTab === "no-ad" && (
          <ListProduct data={dataNoAd} dataBooking={dataBooking} />
        )}
        {activeTab === "hethan" && (
          <ListProduct data={dataExpired} dataBooking={dataBooking} />
        )}
        {activeTab === "quangcao" && (
          <ListProduct data={dataAd} dataBooking={dataBooking} />
        )}
      </div>
    </div>
  )
}
export default ListProducts
