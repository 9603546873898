import { Link } from "react-router-dom"
import { useState, useContext, useEffect, useRef } from "react"
import io from "socket.io-client"
import { Dropdown } from "react-bootstrap"
import {
  HiOutlineCalendarDays,
  HiOutlineCreditCard,
  HiOutlineCog,
  HiOutlineSquare3Stack3D,
} from "react-icons/hi2"
import { HiOutlineLogout } from "react-icons/hi"

import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"
import ModalReact from "../../../../components/Modal/ModalReact"
import { WalletMain } from "../../../pc/Component/Wallet"

function InfoUserMobile({ height, width }) {
  const { dataUser } = useContext(AppContext)
  const socket = useRef()
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
  }, [])
  // Quản lý thao tác người dùng
  useEffect(() => {
    setTimeout(() => {
      let userId = 0
      if (dataUser.length > 0) {
        userId = dataUser[0].id
      }
      socket.current.emit("client-connect-app", userId)
    }, 1000) // 3s cập nhật trạng thái 1 lần
    return () => {
      socket.current.off("client-connect-app")
    }
  }, [])

  return (
    <div className="header-element">
      {/* <!-- Start::header-link|dropdown-toggle --> */}
      <div className="d-flex align-items-center">
        <div className="me-sm-2 me-0 position-relative">
          {dataUser.length > 0 ? (
            <HandleDisplayDropdown
              dataUser={dataUser}
              height={height}
              width={width}
            />
          ) : (
            <i className="fi fi-rr-circle-user fs-27"></i>
          )}
        </div>
      </div>
    </div>
  )
}

export default InfoUserMobile

// Xử lý điều kiện hiển thị
const HandleDisplayDropdown = ({ dataUser, height, width }) => {
  // Modal payment.
  const [showHideWallet, setShowHideWallet] = useState(false)
  const handleShowWallet = () => {
    setShowHideWallet(true)
  }
  const handleCloseWallet = () => setShowHideWallet(false)

  // Delete localStorage.
  const handleDeleteLocalStorage = () => {
    localStorage.setItem("object", "")
  }
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-line border-0 p-0" // class mặc định
          id="dropdown-basic"
          bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
        >
          <img
            src={dataUser[0].avatar}
            width={width ? width : 32}
            height={height ? height : 32}
            className="rounded-5"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Link to="/dashboard" className="dropdown-item d-flex">
            <HiOutlineSquare3Stack3D className="fs-18 me-2" />
            Trang quản lý
          </Link>
          <Link to="/lich-hen" className="dropdown-item d-flex">
            <HiOutlineCalendarDays className="fs-18 me-2" />
            Lịch hẹn
            {/* <span className="badge bg-success-transparent ms-auto">25</span> */}
          </Link>
          <Link
            to=""
            className="dropdown-item d-flex"
            onClick={handleShowWallet}
          >
            <HiOutlineCreditCard className="fs-18 me-2" />
            Ví của bạn
          </Link>
          {/* <Link to="" className="dropdown-item d-flex">
              <HiOutlineInformationCircle className="fs-18 me-2" />
              Hỗ trợ
            </Link> */}
          {dataUser[0].roleid === 1 || dataUser[0].roleid === 2 ? (
            <Link to="/setting" className="dropdown-item d-flex">
              <HiOutlineCog className="fs-18 me-2" />
              Cài đặt
            </Link>
          ) : (
            ""
          )}
          <Link
            to="/login"
            className="dropdown-item d-flex"
            onClick={handleDeleteLocalStorage}
          >
            <HiOutlineLogout className="fs-18 me-2" />
            Đăng xuất
          </Link>
        </Dropdown.Menu>
      </Dropdown>
      {/* Modal payment */}
      <ModalReact
        modalTitle="Ví của bạn"
        showModal={showHideWallet}
        handleClose={handleCloseWallet}
        theme={<WalletMain />}
      />
    </>
  )
}
