import classNames from "classnames/bind"
import { useEffect, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"

import { FiHome } from "react-icons/fi"
import { TfiHeadphoneAlt } from "react-icons/tfi"
import { SlCompass } from "react-icons/sl"
import { HiOutlineSquare3Stack3D } from "react-icons/hi2"
import { IoAddCircleOutline } from "react-icons/io5"


import styles from "../Mobile.module.scss"
import FormSupport from "../../pc/Component/Support/FormSupport"
import ModalReact from "../../../components/Modal/ModalReact"
import { HandleResult } from "../../../components/Phongthuy/Phongthuy"
import FormRealEstateFast from "../../../components/Form/FromRealEstateFast"

const cx = classNames.bind(styles)
// const listMenu = [
//   { id: 1, icon: <FiHome className="fs-21" />, title: "Trang chủ", path: "/" },
//   { id: 2, icon: <TfiHeadphoneAlt className="fs-21" />, title: "Hỗ trợ", path: "/" },
//   {
//     id: 3,
//     icon: <HiOutlineSquare3Stack3D className="fs-21" />,
//     title: "Quản lý",
//     path: "/dashboard"
//   },
//   {
//     id: 4,
//     icon: <VscHeart className="fs-21" />,
//       title: "Đã thích",
//     path: "/"
//   },
//   {
//     id: 5,
//     icon: <SlCompass className="fs-21" />,
//       title: "Phong thủy",
//     path: "/"
//   },
// ]
function MenuFooter() {
    // Get path.
    const location = useLocation()
    const currentPath = location.pathname;
    const {slug} = useParams()
    const [activeMenu, setActiveMenu] = useState(currentPath === "/" ? "home" : "")
    const handleActiveMenu = (key) => {
        setActiveMenu(key)
    }
    useEffect(() => {
        if (slug) {
            setActiveMenu("")
        }
    }, [])

    // Đăng tin.
    const [modalAddProduct, setModalAddProduct] = useState(false)
    const handleAddProduct = () => {
        setModalAddProduct(true)
    }
    const handleCloseAddProduct = () => setModalAddProduct(false)

    // Modal support.
    const [modalSupport, setModalSupport] = useState(false)
    const handleModalSupport = () => {
        setModalSupport(true)
    }
    const handleCloseModalSupport = () => setModalSupport(false)

    // Modal phong thuy.
    const [showHidePT, setShowHidePT] = useState(false);
    const handleModalPhongthuy = () => {
        setShowHidePT(true)
    }
    const handleCloseModalPT = () => setShowHidePT(false)
    
    return (
      <>
        <div className={cx("menu_footer", "justify-content-between")}>
          <Link
            to="/"
            onClick={() => handleActiveMenu("home")}
            className={cx(activeMenu === "home" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
            >
              <FiHome className="fs-21" />
              <div className="mt-1">Trang chủ</div>
            </div>
          </Link>
          <Link
            to="#"
            onClick={handleModalSupport}
            className={cx(activeMenu === "hotro" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
              onClick={() => handleActiveMenu("hotro")}
            >
              <TfiHeadphoneAlt className="fs-21" />
              <div className="mt-1">Hỗ trợ</div>
            </div>
          </Link>
          <Link
            to="#"
            onClick={() => handleActiveMenu("dangtin")}
            className={cx(activeMenu === "dangtin" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
              onClick={handleAddProduct}
            >
              <IoAddCircleOutline className="fs-25" />
              <div className="mt-0">Đăng tin</div>
            </div>
          </Link>
          <Link
            to="/dashboard"
            className={cx(currentPath === "/dashboard" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
            >
              <HiOutlineSquare3Stack3D className="fs-21" />
              <div className="mt-1">Quản lý</div>
            </div>
          </Link>
          {/* <Link
            to="/"
            className={cx(currentPath === "/saved" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
            >
              <VscHeart className="fs-21" />
              <div className="mt-1">Đã thích</div>
            </div>
          </Link> */}
          <Link
            to="#"
            onClick={() => handleActiveMenu("phongthuy")}
            className={cx(activeMenu === "phongthuy" ? "activeMenu" : "")}
          >
            <div
              className={cx(
                "d-flex justify-content-center flex-direction-column align-items-center",
              )}
              onClick={handleModalPhongthuy}
            >
              <SlCompass className="fs-21" />
              <div className="mt-1">Hướng</div>
            </div>
          </Link>
        </div>
        {/* Modal Add Product */}
        <ModalReact
          // size="modal-lg"
          showModal={modalAddProduct}
          handleClose={handleCloseAddProduct}
          theme={<FormRealEstateFast />}
          modalTitle="Đăng tin"
        />
        {/* Modal support */}
        <ModalReact
          modalTitle="Hỗ trợ giải đáp 0378.052.118"
          showModal={modalSupport}
          handleClose={handleCloseModalSupport}
          theme={<FormSupport />}
        />
        {/* Modal Phong thủy*/}
        <ModalReact
          modalTitle="Bạn hợp với hướng ?"
          showModal={showHidePT}
          handleClose={handleCloseModalPT}
          theme={<HandleResult />}
        />
      </>
    )
}

export default MenuFooter