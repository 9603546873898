import { useContext } from "react"
import FormSample from "../../../../components/Form/FormSample"
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"
import { handleMethodCreateData } from "../../../../handle-reuses/reuses/method"

function FormSupport() {
  const data = useContext(AppContext)
  const dataUser = data.dataUser
  const handleGetObjectInput = (object, reset) => {
    // xây dựng đối tượng đầu vào.
    object.author = dataUser[0].id
    object.fullname = dataUser[0].hoten
    object.fullphone = dataUser[0].sodienthoai

    // Xử lý thêm mới.
    handleMethodCreateData(
      object,
      () => {
        alert(
          "Cảm ơn bạn đã đóng góp ý kiến, chúng tôi sẽ xử lý yêu cầu của bạn sớm nhất có thể.",
        )
        reset()
      },
      process.env.REACT_APP_APICreateDataSupport,
    )
  }
  return <FormSample result={handleGetObjectInput} buttonText="Gửi yêu cầu" />
}

export default FormSupport
