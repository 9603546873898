import React, { useState } from "react"
import classNames from "classnames/bind"
import styles from "./Date.module.scss"

import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import vi from "date-fns/locale/vi"
registerLocale("vi", vi)
const cx = classNames.bind(styles)

function DateMonth() {
  const [selectedDate, setSelectedDate] = useState(new Date())

  return (
    <DatePicker
      locale="vi"
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      dateFormat="MM/yyyy"
      showMonthYearPicker // chỉ cho chọn tháng
      isClearable // nút clear
      // showFullMonthYearPicker // hiển thị full name month
      showFourColumnMonthYearPicker
      placeholderText="Chọn tháng"
      className={cx("dateInput")}
    />
  )
}

export default DateMonth
