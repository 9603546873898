import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import DashboardPageMobile from "./DashboardPageMobile"
import MenuFooter from "../MenuFooter/MenuFooter"

function DashboardMobile({ loading, dataEstates, dataBooking, dataCustomer }) {
  return (
    <>
      {/* Header */}
      <HeaderDashboardMobile namePage="Bảng điều khiển" />
      {/* Main */}
      <DashboardPageMobile
        loading={loading}
        dataEstates={dataEstates}
        dataBooking={dataBooking}
        dataCustomer={dataCustomer}
      />
      {/* MenuFooter */}
      <MenuFooter />
    </>
  )
}
export default DashboardMobile
