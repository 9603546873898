import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import ProductLike from "./ProductLike"
import MenuFooter from "../MenuFooter/MenuFooter"

function PageProductLike() {
    return (
      <>
        {/* Header */}
        <HeaderDashboardMobile namePage="Yêu thích"/>
        {/* Main */}
        <ProductLike />
        {/* Footer */}
        <MenuFooter />
      </>
    )
}

export default PageProductLike