import LoginCover from "./Login-cover";

function LoginPage() {

    
    return ( 
        <LoginCover />
     );
}

export default LoginPage;