import HomePageLeftRight from "./LayoutLeftRight"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import PageHomeMobile from "../../mobile/Home"

function HomePage() {
  return (
    <CheckPCMobile>
      {(isMobile) => (!isMobile ? <HomePageLeftRight /> : <PageHomeMobile />)}
    </CheckPCMobile>
  )
}

export default HomePage
