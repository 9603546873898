
// Các footer phụ
import FooterSimple from "./FooterSimple";

// Footer chính
function Footer() {
    return (
      <>
        <section className="section landing-footer text-fixed-white">
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="px-4">
                  <p className="fw-semibold mb-3">
                    <a href="index.html">
                      <img
                        src="../assets/images/brand-logos/desktop-dark.png"
                        alt=""
                      />
                    </a>
                  </p>
                  <p className="mb-2 op-6 fw-normal">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Reprehenderit et magnam, fuga est mollitia eius, quo illum
                    illo inventore optio aut quas omnis rem. Dolores accusantium
                    aspernatur minus ea incidunt.
                  </p>
                  <p className="mb-0 op-6 fw-normal">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Autem ea esse ad
                  </p>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="px-4">
                  <h6 className="fw-semibold mb-3">PAGES</h6>
                  <ul className="list-unstyled op-6 fw-normal landing-footer-list">
                    <li>
                      <a href="#" className="text-fixed-white">
                        Email
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        Profile
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        Timeline
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        Projects
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        Portfolio
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="px-4">
                  <h6 className="fw-semibold">INFO</h6>
                  <ul className="list-unstyled op-6 fw-normal landing-footer-list">
                    <li>
                      <a href="#" className="text-fixed-white">
                        Our Team
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        Contact US
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        About
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        Services
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        Blog
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white">
                        Terms & Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="px-4">
                  <h6 className="fw-semibold">CONTACT</h6>
                  <ul className="list-unstyled fw-normal landing-footer-list">
                    <li>
                      <a href="#" className="text-fixed-white op-6">
                        <i className="ri-home-4-line me-1 align-middle"></i> New
                        York, NY 10012, US
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white op-6">
                        <i className="ri-mail-line me-1 align-middle"></i>{" "}
                        info@fmail.com
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white op-6">
                        <i className="ri-phone-line me-1 align-middle"></i>{" "}
                        +(555)-1920 1831
                      </a>
                    </li>
                    <li>
                      <a href="#" className="text-fixed-white op-6">
                        <i className="ri-printer-line me-1 align-middle"></i>{" "}
                        +(123) 1293 123
                      </a>
                    </li>
                    <li className="mt-3">
                      <p className="mb-2 fw-semibold op-8">FOLLOW US ON :</p>
                      <div className="mb-0">
                        <div className="btn-list">
                          <button className="btn btn-sm btn-icon btn-primary-light btn-wave waves-effect waves-light">
                            <i className="ri-facebook-line fw-bold"></i>
                          </button>
                          <button className="btn btn-sm btn-icon btn-secondary-light btn-wave waves-effect waves-light">
                            <i className="ri-twitter-line fw-bold"></i>
                          </button>
                          <button className="btn btn-sm btn-icon btn-warning-light btn-wave waves-effect waves-light">
                            <i className="ri-instagram-line fw-bold"></i>
                          </button>
                          <button className="btn btn-sm btn-icon btn-success-light btn-wave waves-effect waves-light">
                            <i className="ri-github-line fw-bold"></i>
                          </button>
                          <button className="btn btn-sm btn-icon btn-danger-light btn-wave waves-effect waves-light">
                            <i className="ri-youtube-line fw-bold"></i>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End:: Section-11 --> */}

        <div className="text-center landing-main-footer py-3">
          <span className="text-muted fs-15">
            {" "}
            Copyright © <span id="year"></span>{" "}
            <a href="#" className="text-primary fw-semibold">
              <u>ynex</u>
            </a>
            . Designed with <span className="fa fa-heart text-danger"></span> by{" "}
            <a href="#" className="text-primary fw-semibold">
              <u>Spruko</u>
            </a>{" "}
            All rights reserved
          </span>
        </div>

      </>
    )
}

export default Footer;


export {FooterSimple}