import {
  handleMethodCreateData,
  handleMethodEditData,
} from "../../../../../../handle-reuses/reuses/method"

export async function handleAddData(data, reset) {
  // format price.
  data.giasanpham = data.giasanpham.replace(/[., ]/g, "")
  // Xử lý thêm mới khách hàng
  await handleAddDataCustomer(data)
  // Xử lý thêm mới tên đường phố, dự án(street_project)
  await handleAddDataStreetProject(data, reset)
}

// Xử lý thêm mới khách hàng.
async function handleAddDataCustomer(data) {
  handleMethodCreateData(
    data,
    () => {},
    process.env.REACT_APP_APICreateDataMarketBuyer,
  )
}

// Xử lý thêm mới street_project.
async function handleAddDataStreetProject(data, reset) {
  const response = await fetch(
    process.env.REACT_APP_APIGetDataStreetProject +
      `?streets=${data.tuyenduong}`,
  )
  const dataRes = await response.json(data)
  // Check trùng nhau.
  const alike = dataRes.filter((item) => {
    return (
      item.street_project.trim() === data.tuyenduong.trim() &&
      item.districts.trim() === data.quanhuyen &&
      item.citys.trim() === data.thanhpho
    )
  })
  if (alike.length === 0) {
    // set object.
    const objectInput = {
      street_project: data.tuyenduong,
      districts: data.quanhuyen,
      citys: data.thanhpho,
    }
    handleMethodCreateData(
      objectInput,
      () => {
        alert("Thêm khách hàng thành công")
        reset()
      },
      process.env.REACT_APP_APICreateDataStreetProject,
    )
  }
}

// Xử lý thêm  mới ghi chú.
export function handleAddNoteCustomer(data, reset, handleCheckAddSuccess) {
  handleMethodCreateData(
    data,
    () => {
      alert("Ghi chú đã được thêm mới.")
      reset()
      handleCheckAddSuccess()
    },
    process.env.REACT_APP_APICreateDataMarketBuyerNote,
  )
}

// Xử lý edit data customer.
export function handleEditDataCustomer(data, callback) {
  handleMethodEditData(
    data,
    () => {
      alert("Cập nhật dữ liệu thành công.")
      callback()
    },
    process.env.REACT_APP_APIEditDataMarketBuyer,
  )
}
