import { HeaderSample } from "../../../components/Header"
import LayoutMain from "../../../components/Layout"
import { sidebarSimple, SidebarSimple } from "../../../components/Sidebar"
import { FooterSimple } from "../../../components/Footer"
import ManagerUserLists from "../ListUser"

function ListUserPage() {
  return (
    <>
      {/* Header */}
      <HeaderSample />
      {/* Main */}
      <LayoutMain>
        <div className="row">
          <div className="col-3 px-3">
            <SidebarSimple data={sidebarSimple} />
          </div>
          <div className="col-9">
            {/* <ListUser /> */}
            <ManagerUserLists />
          </div>
        </div>
      </LayoutMain>
      {/* Footer */}
      <FooterSimple />
    </>
  )
}

export default ListUserPage
