import moment from "moment"
import classNames from "classnames/bind"
import styles from "./ListUser.module.scss"
import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import Tippy from "@tippyjs/react"

import {
  HiOutlinePencilSquare,
  HiOutlineEllipsisHorizontalCircle,
  HiOutlineLockClosed,
} from "react-icons/hi2"
import { CiMobile3 } from "react-icons/ci"

import FormEditUser from "./FormEditUser"
import ModalReact from "../../../components/Modal/ModalReact"
import FormEditPassword from "./FromEditPassword"
import { handleMethodEditData } from "../../../handle-reuses/reuses/method"
import { images } from "../../../assets/images"
import { LoadingSpinner } from "../../../components/Loading"
import { TextLimit } from "../../../handle-reuses/reuses"
const cx = classNames.bind(styles)

function CandidateList({ data, id, height }) {
  const [dataUsers, setDataUsers] = useState(data)
  const [loading, setLoading] = useState(true)

  // Edit data
  const [valueId, setValueId] = useState()
  const [showModal, setShowModal] = useState(false)
  // Edit password.
  const [openModal, setOpenModal] = useState(false)
  const [idDelete, setIdDelete] = useState()

  // Set data.
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setDataUsers(data)
    }, 500)
  }, [data])

  // Xử lý kích hoạt tài khoản
  const handleSwitchChange_ = async (e) => {
    const getId = e.target.id.replace(/\D/g, "")
    const response = await fetch(
      process.env.REACT_APP_APIGetDataSearchUser + `?only=${getId}`,
    )
    const dataRes = await response.json()
    const object = dataRes.find((value) => value.id === Number(getId))
    if (object) {
      if (object.id === 1 || object.id === 2) {
        alert("Không thể khóa tài khoản admin")
      } else {
        let valueInput = {
          id: Number(getId),
          kiemduyet: object.kiemduyet === 1 ? 0 : 1,
        }

        handleMethodEditData(
          valueInput,
          () => {
            if (object.kiemduyet === 1) {
              alert("Đã hủy kích hoạt.")
              document.getElementById(
                "custom-switch" + object.id + "",
              ).checked = false
            } else {
              alert("Đã được kích hoạt.")
              document.getElementById(
                "custom-switch" + object.id + "",
              ).checked = true
            }
          },
          process.env.REACT_APP_APIEditRowMarketKiemduyetUser,
        )
      }
    }
  }

  // Get value id edit user.
  const handleEditUser = (id_) => {
    setValueId(id_)
    setShowModal(true)
  }
  const handleCloseModal = () => setShowModal(false)

  // Edit password.
  const handleEditPasswordUser = (id_) => {
    setOpenModal(true)
    setIdDelete(id_)
  }
  const handleCloseModalPass = () => setOpenModal(false)

  if (dataUsers.length === 0) {
    return <div>Hiện tại chưa có dữ liệu.</div>
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div
      className="col-12 p-3"
      id={id}
      style={{ height: height, overflowY: "auto", overflowX: "hidden" }}
    >
      <div className={cx("row")}>
        {dataUsers.map((item) => {
          let checkKD = item.kiemduyet
          if (checkKD === 1) {
            checkKD = true
          } else {
            checkKD = false
          }
          return (
            <div className={cx("col-xl-4 col-md-6")} key={item.id}>
              <div className={cx("card p-3 rounded-4")}>
                <div className={cx("d-flex align-items-center")}>
                  <div className={cx("avatar-lg", "flex-shrink-0")}>
                    <img
                      src={
                        item.avatar === "" || item.avatar === null
                          ? images.noImage
                          : "/" + item.avatar
                      }
                      alt=""
                      className={cx("img-fluid")}
                    />
                  </div>
                  <div className={cx("ms-3 col-7", "flex-grow-1")}>
                    <div className={cx("d-flex justify-content-between")}>
                      <a href="#" className={cx("text-title")}>
                        <h5
                          className={cx(
                            "fs-15 mb-1 fw-6",
                            item.roleid === 1 || item.roleid === 2
                              ? "text-primary-1"
                              : "",
                          )}
                        >
                          {item.hoten}
                        </h5>
                      </a>
                      <div className="hstack gap-2 flex-wrap">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-dark p-0 border-0" // class mặc định
                            id="dropdown-basic"
                            bsPrefix="custom-dropdown-toggle" // đặt tên cho class fix loại bỏ class dropdown-toggle
                          >
                            <HiOutlineEllipsisHorizontalCircle className="fs-21" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Link
                              to=""
                              className="dropdown-item d-flex fs-14"
                              onClick={() => handleEditUser(item.id)}
                            >
                              <HiOutlinePencilSquare className="me-2 fs-18" />
                              <span>Sửa thông tin</span>
                            </Link>
                            <Link
                              to=""
                              className="dropdown-item d-flex fs-14"
                              onClick={() => handleEditPasswordUser(item.id)}
                            >
                              <HiOutlineLockClosed className="fs-18 me-2" />
                              Đổi mật khẩu
                            </Link>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div
                      className={cx(
                        "text-muted-2 fs-14 d-flex align-items-center",
                      )}
                    >
                      <CiMobile3 className="me-1" /> {item.sodienthoai}
                    </div>
                    <Tippy content={item.email}>
                      <div
                        className={cx(
                          "text-muted-2 fs-14 d-flex align-items-center titleEmail",
                        )}
                      >
                        <TextLimit text={item.email} number={15} />
                      </div>
                    </Tippy>

                    <div
                      className={cx(
                        "d-flex justify-content-between text-muted-2 mt-3",
                      )}
                    >
                      <div>{moment(item.addtime).format("DD/MM/YYYY")}</div>
                      <div>
                        {/* <Form className={cx("center")}>
                          <Form.Check
                            type="switch"
                            id={"custom-switch" + item.id}
                            // label={textKD}
                            checked={checkKD}
                            onChange={handleSwitchChange_}
                            inputprops={{
                              className: "form-checked-success op-8",
                            }}
                          />
                        </Form> */}
                        <div class="form-check form-switch mb-2">
                          <input
                            class="form-check-input form-checked-success op-8"
                            type="checkbox"
                            role="switch"
                            id={"custom-switch" + item.id}
                            onChange={handleSwitchChange_}
                            checked={checkKD}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {/* Modal edit data */}
      {showModal && (
        <ModalReact
          key={valueId} // dùng key để bắt modal render lại khi key thay đổi
          id="modalEdit"
          theme={<FormEditUser idUser={valueId} />}
          modalTitle="Sửa thông tin"
          showModal={showModal}
          handleClose={handleCloseModal}
        />
      )}
      {/* Modal edit password */}
      <ModalReact
        key={idDelete}
        theme={<FormEditPassword idUser={idDelete} />}
        modalTitle="Thay đổi mật khẩu"
        showModal={openModal}
        handleClose={handleCloseModalPass}
      />
    </div>
  )
}

export default CandidateList
