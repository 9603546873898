
// Hà đồ lạc thư.
const hadoLacthu = [
  {
    id: 1,
    huong: "Đông",
    nhom: "Đông tứ trạch",
    menh: "Mộc",
    number: 3,
    cung: "Chấn",
  },
  {
    id: 2,
    huong: "Tây",
    nhom: "Tây tứ trạch",
    menh: "Kim",
    number: 7,
    cung: "Đoài",
  },
  {
    id: 3,
    huong: "Nam",
    nhom: "Đông tứ trạch",
    menh: "Hỏa",
    number: 9,
    cung: "Ly",
  },
  {
    id: 4,
    huong: "Bắc",
    nhom: "Đông tứ trạch",
    menh: "Thủy",
    number: 1,
    cung: "Khảm",
  },
  {
    id: 5,
    huong: "Đông nam",
    nhom: "Đông tứ trạch",
    menh: "Mộc",
    number: 4,
    cung: "Tốn",
  },
  {
    id: 6,
    huong: "Tây bắc",
    nhom: "Tây tứ trạch",
    menh: "Kim",
    number: 6,
    cung: "Càn",
  },
  {
    id: 7,
    huong: "Đông bắc",
    nhom: "Tây tứ trạch",
    menh: "Thổ",
    number: 8,
    cung: "Cấn",
  },
  {
    id: 8,
    huong: "Tây nam",
    nhom: "Tây tứ trạch",
    menh: "Thổ",
    number: 2,
    cung: "Khôn",
  },
]
// Quái số. (cộng tất cả các số trong năm sinh lại đến khi còn 1 chữ số)
// const quaiSo = [
//   { id: 1, cungmenh: "Khảm", number: 1, sex: "Nam" },
//   { id: 2, cungmenh: "Ly", number: 2, sex: "Nam" },
//   { id: 3, cungmenh: "Cấn", number: 3, sex: "Nam" },
//   { id: 4, cungmenh: "Đoài", number: 4, sex: "Nam" },
//   { id: 5, cungmenh: "Càn", number: 5, sex: "Nam" },
//   { id: 6, cungmenh: "Khôn", number: 6, sex: "Nam" },
//   { id: 7, cungmenh: "Tốn", number: 7, sex: "Nam" },
//   { id: 8, cungmenh: "Chấn", number: 8, sex: "Nam" },
//   { id: 9, cungmenh: "Khôn", number: 9, sex: "Nam" },
//   { id: 10, cungmenh: "Cấn", number: 1, sex: "Nữ" },
//   { id: 11, cungmenh: "Càn", number: 2, sex: "Nữ" },
//   { id: 12, cungmenh: "Đoài", number: 3, sex: "Nữ" },
//   { id: 13, cungmenh: "Cấn", number: 4, sex: "Nữ" },
//   { id: 14, cungmenh: "Ly", number: 5, sex: "Nữ" },
//   { id: 15, cungmenh: "Khảm", number: 6, sex: "Nữ" },
//   { id: 16, cungmenh: "Khôn", number: 7, sex: "Nữ" },
//   { id: 17, cungmenh: "Chấn", number: 8, sex: "Nữ" },
//   { id: 18, cungmenh: "Tốn", number: 9, sex: "Nữ" },
// ]
// Quẻ bát trạch.
const quetBatTrach = [
  { id: 1, cungmenh: "Càn", description: "Phục vị", que: "Tây bắc" },
  { id: 2, cungmenh: "Khảm", description: "Lục sát", que: "Tây bắc" },
  { id: 3, cungmenh: "Cấn", description: "Thiên y", que: "Tây bắc" },
  { id: 4, cungmenh: "Chấn", description: "Ngũ quỉ", que: "Tây bắc" },
  { id: 5, cungmenh: "Tốn", description: "Họa hại", que: "Tây bắc" },
  { id: 6, cungmenh: "Ly", description: "Tuyệt mạng", que: "Tây bắc" },
  {
    id: 7,
    cungmenh: "Khôn",
    description: "Phước đức, Diên niên",
    que: "Tây bắc",
  },
  { id: 8, cungmenh: "Đoài", description: "Sinh khí", que: "Tây bắc" },
  { id: 9, cungmenh: "Càn", description: "Lục sát", que: "Bắc" },
  { id: 10, cungmenh: "Khảm", description: "Phục vị", que: "Bắc" },
  { id: 11, cungmenh: "Cấn", description: "Ngũ quỉ", que: "Bắc" },
  { id: 12, cungmenh: "Chấn", description: "Thiên y", que: "Bắc" },
  { id: 13, cungmenh: "Tốn", description: "Sinh khí", que: "Bắc" },
  { id: 14, cungmenh: "Ly", description: "Phước đức, Diên niên", que: "Bắc" },
  {
    id: 15,
    cungmenh: "Khôn",
    description: "Tuyệt mạng",
    que: "Bắc",
  },
  { id: 16, cungmenh: "Đoài", description: "Họa hại", que: "Bắc" },
  { id: 17, cungmenh: "Càn", description: "Thiên y", que: "Đông bắc" },
  { id: 18, cungmenh: "Khảm", description: "Ngũ quỉ", que: "Đông bắc" },
  { id: 19, cungmenh: "Cấn", description: "Phục vị", que: "Đông bắc" },
  { id: 20, cungmenh: "Chấn", description: "Lục sát", que: "Đông bắc" },
  { id: 21, cungmenh: "Tốn", description: "Tuyệt mạng", que: "Đông bắc" },
  { id: 22, cungmenh: "Ly", description: "Họa hại", que: "Đông bắc" },
  {
    id: 23,
    cungmenh: "Khôn",
    description: "Sinh khí",
    que: "Đông bắc",
  },
  {
    id: 24,
    cungmenh: "Đoài",
    description: "Phước đức, Diên niên",
    que: "Đông bắc",
  },
  { id: 25, cungmenh: "Càn", description: "Ngũ quỉ", que: "Đông" },
  { id: 26, cungmenh: "Khảm", description: "Thiên y", que: "Đông" },
  { id: 27, cungmenh: "Cấn", description: "Lục sát", que: "Đông" },
  { id: 28, cungmenh: "Chấn", description: "Phục vị", que: "Đông" },
  { id: 29, cungmenh: "Tốn", description: "Phước đức, Diên niên", que: "Đông" },
  { id: 30, cungmenh: "Ly", description: "Sinh khí", que: "Đông" },
  {
    id: 31,
    cungmenh: "Khôn",
    description: "Họa hại",
    que: "Đông",
  },
  {
    id: 32,
    cungmenh: "Đoài",
    description: "Tuyệt mạng",
    que: "Đông",
  },
  { id: 33, cungmenh: "Càn", description: "Họa hại", que: "Đông nam" },
  { id: 34, cungmenh: "Khảm", description: "Sinh khí", que: "Đông nam" },
  { id: 35, cungmenh: "Cấn", description: "Tuyệt mạng", que: "Đông nam" },
  {
    id: 36,
    cungmenh: "Chấn",
    description: "Phước đức, Diên niên",
    que: "Đông nam",
  },
  { id: 37, cungmenh: "Tốn", description: "Phục vị", que: "Đông nam" },
  { id: 38, cungmenh: "Ly", description: "Thiên y", que: "Đông nam" },
  {
    id: 39,
    cungmenh: "Khôn",
    description: "Ngũ quỉ",
    que: "Đông nam",
  },
  { id: 40, cungmenh: "Đoài", description: "Lục sát", que: "Đông nam" },
  { id: 41, cungmenh: "Càn", description: "Tuyệt mạng", que: "Nam" },
  { id: 42, cungmenh: "Khảm", description: "Phước đức, Diên niên", que: "Nam" },
  { id: 43, cungmenh: "Cấn", description: "Họa hại", que: "Nam" },
  { id: 44, cungmenh: "Chấn", description: "Sinh khí", que: "Nam" },
  { id: 45, cungmenh: "Tốn", description: "Thiên y", que: "Nam" },
  { id: 46, cungmenh: "Ly", description: "Phục vị", que: "Nam" },
  {
    id: 47,
    cungmenh: "Khôn",
    description: "Lục sát",
    que: "Nam",
  },
  { id: 48, cungmenh: "Đoài", description: "Ngũ quỉ", que: "Nam" },
  {
    id: 49,
    cungmenh: "Càn",
    description: "Phước đức, Diên niên",
    que: "Tây nam",
  },
  { id: 50, cungmenh: "Khảm", description: "Tuyệt mạng", que: "Tây nam" },
  { id: 51, cungmenh: "Cấn", description: "Sinh khí", que: "Tây nam" },
  { id: 52, cungmenh: "Chấn", description: "Họa hại", que: "Tây nam" },
  { id: 53, cungmenh: "Tốn", description: "Ngũ quỷ", que: "Tây nam" },
  { id: 54, cungmenh: "Ly", description: "Lục sát", que: "Tây nam" },
  {
    id: 55,
    cungmenh: "Khôn",
    description: "Phục vị",
    que: "Tây nam",
  },
  { id: 56, cungmenh: "Đoài", description: "Thiên y", que: "Tây nam" },
  { id: 1, cungmenh: "Càn", description: "Sinh khí", que: "Tây" },
  { id: 2, cungmenh: "Khảm", description: "Họa hại", que: "Tây" },
  { id: 3, cungmenh: "Cấn", description: "Phước đức, Diên niên", que: "Tây" },
  { id: 4, cungmenh: "Chấn", description: "Tuyệt mạng", que: "Tây" },
  { id: 5, cungmenh: "Tốn", description: "Lục sát", que: "Tây" },
  { id: 6, cungmenh: "Ly", description: "Ngũ quỉ", que: "Tây" },
  {
    id: 7,
    cungmenh: "Khôn",
    description: "Thiên y",
    que: "Tây",
  },
  { id: 8, cungmenh: "Đoài", description: "Phục vị", que: "Tây" },
]
// Bảng ý nghĩa
const ynghiaPhuhop = [
  {
    id: 1,
    title: "Phước đức, Diên niên",
    description: "Mọi sự ổn định",
    detail:
      "Sự hòa thuận, êm đẹp trong các mối quan hệ tình cảm, gia đình và công việc. Hoạt động kinh doanh cũng gặp nhiều tiến triển",
    good: "Thượng cát",
  },
  {
    id: 2,
    title: "Sinh khí",
    description: "Phúc lộc vẹn toàn",
    detail:
      "Mang ý nghĩa sinh sôi, phát triển. Hướng nhà tượng trưng cho sự hanh thông, thuận lợi, đạt được nhiều thành công trong cuộc sống",
    good: "Thượng cát",
  },
  {
    id: 3,
    title: "Thiên y",
    description: "Gặp thiên thời được che chở",
    detail:
      "Biểu trưng cho cát khí, nhận được nhiều tài lộc, may mắn, luôn có sự phù trợ của quý nhân",
    good: "Trung cát",
  },
  {
    id: 4,
    title: "Phục vị",
    description: "Được sự giúp đỡ",
    detail:
      "Hóa giải những điều không may mắn, giúp cuộc sống luôn được thuận lợi, từ đó gặp nhiều may mắn",
    good: "Tiểu cát",
  },
  {
    id: 5,
    title: "Tuyệt mạng",
    description: "Chết chóc",
    detail:
      "Mang nhiều hung khí, có ý nghĩa về sự chia lìa, bệnh tật và trắc trở. Đây là hướng nhà xấu nhất nên tránh",
    good: "Đại hung",
  },
  {
    id: 6,
    title: "Họa hại",
    description: "Nhà có hung khí",
    detail:
      "Mưu sự khó thành, dễ hao tài tán lộc, tình duyên trắc trở, dễ đối mặt với những điều không may mắn",
    good: "Tiểu hung",
  },
  {
    id: 7,
    title: "Lục sát",
    description: "Nhà có sát khí",
    detail:
      "Hướng về sự thiệt hại, mất mát, dễ bị đứt đoạn trong các mối quan hệ, bị trì hoãn công việc làm ăn",
    good: "Thứ hung",
  },
  {
    id: 8,
    title: "Ngũ quỉ",
    description: "Gặp tai hoạ",
    detail:
      "Dễ bị quấy rối bởi những điều không đâu, cuộc sống lận đận khó khăn. Cãi vả, thị phi là những điều khó tránh khỏi",
    good: "Thứ hung",
  },
]

export { hadoLacthu, quetBatTrach, ynghiaPhuhop}