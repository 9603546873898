
import { Link } from "react-router-dom"
import classNames from "classnames/bind"

import styles from "../Mobile.module.scss"
import { LoadingSpinner } from "../../../components/Loading"
import { imagesCate, images } from "../../../assets/images"
import { SlideBanner } from "../../../components/Slider"
import { CardListEvent } from "../../../components/Card"

const cx = classNames.bind(styles)

const dataBanner = [
  {
    id: 1,
    title: "Không gian 3D",
    info: "View360.vn",
    info1: "Tài trợ",
    class: "bg-card",
    image: images.bannerPrView360,
    width: 250,
    path: "https://view360.com.vn",
  },
  {
    id: 2,
    title: "Chuyển đổi số",
    info: "Team Tech",
    info1: "Tài trợ",
    class: "bg-card-warning",
    image: images.bannerPrView360,
    width: 250,
    path: "#",
  },
  {
    id: 3,
    title: "Bất động sản",
    info: "muanhahaiphong.com",
    info1: "Tài trợ",
    class: "bg-card",
    image: images.bannerPrView360,
    width: 250,
    path: "https://muanhahaiphong.com",
  },
]
function DashboardPageMobile({
  loading,
  dataEstates,
  dataBooking,
  dataCustomer,
}) {
  // Kiểm tra xem đã loading dữ liệu chưa
  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <>
      <div className={cx("wrapper_dash")} style={{ overflow: "hidden" }}>
        {/* Danh mục */}
        <ListCategoryDashboardMobile />
        {/* Tài trợ */}
        <div className="col-12 mt-3">
          <h6 className="p-2">Tài trợ</h6>
          <SlideBanner data={dataBanner} />
        </div>
        {/* Lịch hẹn */}
        <CalendarDashMobile
          dataEstates={dataEstates}
          dataBooking={dataBooking}
          dataCustomer={dataCustomer}
        />
      </div>
    </>
  )
}

export default DashboardPageMobile

// List danh mục
function ListCategoryDashboardMobile() {
  return (
    <div className="mt-5">
      <h6 className="p-2">Danh mục</h6>
      <ul className={cx("box-cate", "mt-3")}>
        <li>
          <Link to="/san-pham">
            <div className={cx("icon-box", "bg-cate-1")}>
              <img src={imagesCate.product} alt="" width={30} height={30} />
              {/* <HiOutlineHomeModern
                    className={cx("fs-25", "text-warning")}
                  /> */}
            </div>
            Sản phẩm
          </Link>
        </li>
        <li>
          <Link to="/lich-hen">
            <div className={cx("icon-box", "bg-cate-2")}>
              {/* <IoCalendarOutline className={cx("fs-25", "text-warning")} /> */}
              <img src={imagesCate.calendar} alt="" width={30} height={30} />
            </div>
            Lịch hẹn
          </Link>
        </li>
        <li>
          <Link to="/khach-hang">
            <div className={cx("icon-box", "bg-cate-3")}>
              <img src={imagesCate.customer} alt="" width={30} height={30} />
              {/* <HiOutlineUsers className={cx("fs-25", "text-warning")} /> */}
            </div>
            Khách hàng
          </Link>
        </li>
        <li>
          <Link to="/product-like">
            <div className={cx("icon-box", "bg-cate-4")}>
              <img src={imagesCate.love} alt="" width={30} height={30} />
              {/* <HiOutlineUsers className={cx("fs-25", "text-warning")} /> */}
            </div>
            Yêu thích
          </Link>
        </li>
      </ul>
    </div>
  )
}

// Thống kê.

function CalendarDashMobile({ dataEstates, dataBooking, dataCustomer }) {
  return (
    <div className="mt-5">
      <CardListEvent dataPro={dataEstates} dataBooking={dataBooking} />
    </div>
  )
}
