import { handleMethodCreateData } from "../../../../../handle-reuses/reuses/method"

export function handleAddPayment(data, reset, handleSuccess) {
  const fetchData = async () => {
    const response = await fetch(
      process.env.REACT_APP_APIGetUserPay + `?only=${data.userid}`,
    )
    const dataRes = await response.json()
    data.userpayis = dataRes[0].id
    data.title = "Yêu cầu nạp tiền"
    data.discription = "Nạp tiền sử dụng dịch vụ."
    data.deposit = data.deposit.replace(/[,. ]/g, "")
    handleCreatePayment(data, reset, handleSuccess)
  }
  fetchData()
}

// Xử lý thêm mới.
function handleCreatePayment(data, reset, handleSuccess) {
  handleMethodCreateData(
    data,
    () => {
      reset()
      handleSuccess()
    },
    process.env.REACT_APP_APICreateUserPayHis,
  )
}
