import nlp from "compromise"

// Phân tích ý định người dùng
export async function handleNLP(inputText) {
  if (inputText) {
    const doc = nlp(inputText) // khởi tạo
    const arrInput = doc.terms().out("array") // chuyển chuỗi thành mảng
    // console.log(arrInput)
    // Đào tạo NLP.
    const intent = handleTraining(arrInput, inputText)
    if (await intent) {
      const resultIntent = await removeDuplicates(intent)
      return resultIntent
    }
  }
}



// Loại bỏ 2 từ giống nhau và liên tiếp trong cùng 1 chuỗi
async function removeDuplicates(str) {
  let string = await str
  var words = string.split(/\s+/) // Tách chuỗi thành một mảng các từ
  var result = [words[0]] // Bắt đầu với từ đầu tiên trong mảng
  for (var i = 1; i < words.length; i++) {
    // Nếu từ hiện tại không giống với từ trước đó, thêm vào kết quả
    if (words[i] !== words[i - 1]) {
      result.push(words[i])
    }
  }
  // Gộp các từ lại thành một chuỗi và trả về
  return result.join(" ")
}

// Đào tạo NLP.
async function handleTraining(arrInput, inputText) {
  let intent = ""
  // Xác định danh mục.
  let cate = handleCate(arrInput)
  if (cate === "bán" || cate === "cần bán" || cate === "muốn bán") {
    cate = "bán"
  }

  // Xác định loại hình (nhà ở, căn hộ...).
  let type = handleType(arrInput)
  if (type === "căn nhà" || type === "nhà" || type === "bán nhà") {
    type = "nhà"
  }

  // Vị trí
  const location = handleLocation(inputText)
  const formatLoca = await location
  // Check intent.
  if (formatLoca.length > 0) {
    const street = trainingProcessing(arrInput, "đường", 3)
    if (street.after !== "") {
      intent = (
        cate +
        " " +
        type +
        " " +
        "đường " +
        formatLoca.toLowerCase()
      ).trim()
    } else {
      intent = (cate + " " + type + " " + formatLoca.toLowerCase()).trim()
    }
  } else {
    intent = (cate + " " + type).trim()
  }

  return intent
}

//Xử lý xác định danh mục (xác định chủ đề mua bán)
function handleCate(arrInput) {
  let cate = ""
  // Chủ đề mua bán
  const cateSell = trainingProcessing(arrInput, "bán", 1)
  const cateRent = trainingProcessing(arrInput, "thuê", 1)
  const cateChange = trainingProcessing(arrInput, "nhượng", 2)
  const cateBuy = trainingProcessing(arrInput, "mua", 1)

  // Chủ đề mua bán
  if (cateSell.before) {
    cate = cateSell.before
  }
  if (cateRent.before) {
    cate = cateRent.before
  }
  if (cateChange.before) {
    cate = cateChange.before
  }
  if (cateBuy.before) {
    cate = cateBuy.before

    if (cateBuy.after === "mua nhà") {
      cate = cateBuy.before + " " + cateBuy.after
    }
  }

  return cate
}



// Xử lý xác định loại hình
function handleType(arrInput) {
  let type = ""
  const nhapho = trainingProcessing(arrInput, "nhà", 1)
  const datnen = trainingProcessing(arrInput, "đất", 1)
  const canho = trainingProcessing(arrInput, "hộ", 1)
  const chungcu = trainingProcessing(arrInput, "cư", 1)

  if (nhapho.before) {
    type = nhapho.before
  }
  if (datnen.before) {
    type = datnen.before
  }
  if (canho.before) {
    type = canho.before
  }
  if (chungcu.before) {
    type = chungcu.before
  }
  return type
}

// Xác đinh vị trí (tuyến đường).
async function handleLocation(inputText) {
  const formatText = inputText.toLowerCase()
  const response = await fetch(
    process.env.REACT_APP_API_STREET + `?keyword=${inputText}`,
  )
  const dataRes = await response.json()
  // console.log(dataRes)
  if (dataRes.length > 0) {
    const loca = dataRes.find((item) => {
      return formatText.includes(item.tuyenduong.toLowerCase())
    })
    // console.log(loca)
    return loca.tuyenduong
  } else {
    return []
  }
}

/** ============================ Xử lý đạo tạo ====================== */
// Xử lý đào tạo tìm những từ đằng trước, đằng sau của mảng.
/**
 *
 * @param {*} arrInput mảng các từ được phân tách đầu vào
 * @param {*} keyword từ khóa cần tìm trong vị trí mảng đầu vào
 * @param {*} limit giới hạn vị trí cần lấy
 * @returns
 */
export function trainingProcessing(arrInput, keyword, limit) {
  const response = {}

  // const nha = "nhà", duong = "đường", canho = "căn hộ", chungcu = "chung cư", phongtro = "phòng trọ", datnen = "đất nền"

  // Xác định index trong mảng
  let index = arrInput.indexOf(keyword)
  let before = ""
  let after = ""
  let strBefore = "",
    strAfter = ""
  if (index > -1) {
    // Tìm những từ đằng trước
    if (index >= 0) {
      for (let i = 0; i <= limit; i++) {
        before +=
          " " +
          (arrInput[index - (limit - i)] ? arrInput[index - (limit - i)] : "")
      }
      strBefore = before.trim()
    }

    // Tìm những từ đằng sau.
    if (index < arrInput.length - 1) {
      for (let i = 0; i <= limit; i++) {
        after +=
          " " +
          (arrInput[index + (limit + i - limit)]
            ? arrInput[index + (limit + i - limit)]
            : "")
      }
      strAfter = after.trim()
    }
    response.before = strBefore
    response.after = strAfter
    // if (strBefore && !strAfter) {
    //     response.before = strBefore
    // } else if (strBefore && strAfter) {
    //     response.before = strBefore;
    //     response.after = strAfter;
    // } else if (!strBefore && strAfter) {
    //     response.after = strAfter
    // } else if (!strBefore && !strAfter) {
    //     response.before = ""
    //     response.after = ""
    // }
  }
  return response
}

// Kiểm tra tỷ lệ giống nhau.
function handleSimilarRatio(doc1, doc2) {
  const doc1_ = nlp(doc1)
  const doc2_ = nlp(doc2)
  // Kiểm tra tỷ lệ giống nhau.
  const match = doc1_.match(doc2_)
  const similarity = match.confidence() * 100 // Tính tỷ lệ giống nhau

  return similarity
}

/** ============================ *Xử lý đạo tạo ====================== */
