import { useEffect, useState } from "react";

function LayoutLeft({ dataCountCate }) {
  const [loading, setLoading] = useState(true)
  const [totalCus, setTotalCus] = useState({
    total: 0,
    totalbuyer: 0,
    totalrent: 0,
    totalinvester: 0,
    dataStatus: [],
    totalStatus: 0,
    totalBuyerStatus: 0,
    totalRentStatus: 0,
    totalInvestStatus: 0,
    dataStatusSuc: [],
    totalStatusSuc: 0,
    totalBuyerStatusSuc: 0,
    totalRentStatusSuc: 0,
    totalInvestStatusSuc: 0
  })
  useEffect(() => {
    if (dataCountCate.length > 0) {
      // total customer.
      const totalCustomer = handleDataTotalCus(dataCountCate)
      // customer status = 0 (Khách chưa giao dịch)
      const filterStatus = dataCountCate.filter((item) => item.tinhtrang === 0);
      const dataCusStatus = handleDataTotalCus(filterStatus);
      // customer status = 1 (Khách hàng đã giao dịch thành công)
      const filterStatusSuccess = dataCountCate.filter((item) => item.tinhtrang === 1);
      const dataCusStatusSuccess = handleDataTotalCus(filterStatusSuccess)

      setTotalCus({
        total: totalCustomer.total,
        totalbuyer: totalCustomer.cusbuyer,
        totalrent: totalCustomer.cusrent,
        totalinvester: totalCustomer.cusinvester,
        dataStatus: filterStatus,
        totalStatus: dataCusStatus.total,
        totalBuyerStatus: dataCusStatus.cusbuyer,
        totalRentStatus: dataCusStatus.cusrent,
        totalInvestStatus: dataCusStatus.cusinvester,
        dataStatusSuc: filterStatusSuccess,
        totalStatusSuc: dataCusStatusSuccess.total,
        totalBuyerStatusSuc: dataCusStatusSuccess.cusbuyer,
        totalRentStatusSuc: dataCusStatusSuccess.cusrent,
        totalInvestStatusSuc: dataCusStatusSuccess.cusinvester,
      });
    }

  }, [dataCountCate])


  return (
    <>
      <div
        className="card custom-card rounded-4 mb-0"
        style={{ height: "calc(100vh - 10rem)", overflowY: "auto", overflowX: "hidden", boxShadow: "none" }}
      >
        <div className="card-body p-0">
          <div className="row">
            <div className="col-xl-3 col-md-12 border-end border-inline-end-dashed">
              <div className="d-flex flex-wrap align-items-top p-3">
                <div className="flex-fill">
                  <h5 className="fw-semibold mb-1">
                    {Number(totalCus.total)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </h5>
                  <p className="text-muted mb-0 fs-13">Tổng khách</p>
                </div>
                <div>
                  <span className="badge bg-success-transparent fs-14 rounded-5">
                    <i className="ri-arrow-up-s-line align-middle me-1 d-inline-block"></i>
                    {totalCus.total > 0 ? "100%" : "0%"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-12 border-end border-inline-end-dashed">
              <div className="d-flex flex-wrap align-items-top p-3">
                <div className="flex-fill">
                  <h5 className="fw-semibold mb-1">
                    {(Number(totalCus.totalbuyer) + Number(totalCus.totalinvester))
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </h5>
                  <p className="text-muted mb-0 fs-13">Khách mua</p>
                </div>
                <div>
                  <span className="badge bg-primary op-8 fs-12 rounded-5">
                    <i className="ri-arrow-down-s-line align-middle me-1"></i>
                    {totalCus.total > 0 ? (((totalCus.totalbuyer / totalCus.total) * 100).toFixed(1)) : "0"}%
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-12 border-end border-inline-end-dashed">
              <div className="d-flex flex-wrap align-items-top p-3">
                <div className="flex-fill">
                  <h5 className="fw-semibold mb-1">
                    {Number(totalCus.totalrent)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </h5>
                  <p className="text-muted mb-0 fs-13">Khách thuê</p>
                </div>
                <div>
                  <span className="badge bg-warning op-8 fs-12 rounded-5">
                    <i className="ri-arrow-up-s-line align-middle me-1 d-inline-block"></i>
                    {totalCus.total > 0 ? ((totalCus.totalrent / totalCus.total) * 100).toFixed(1) : "0"}%
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-12 border-end border-inline-end-dashed">
              <div className="d-flex flex-wrap align-items-top p-3">
                <div className="flex-fill">
                  <h5 className="fw-semibold mb-1">
                    {Number(totalCus.totalStatusSuc)
                      .toLocaleString()
                      .replace(/[.]/g, ",")}
                  </h5>
                  <p className="text-muted mb-0 fs-13">Khai thác</p>
                </div>
                <div>
                  <span className="badge bg-success op-8 fs-12 rounded-5">
                    <i className="ri-arrow-up-s-line align-middle me-1 d-inline-block"></i>
                    {totalCus.total > 0 ? ((totalCus.totalStatusSuc / totalCus.total) * 100).toFixed(1) : "0"}%
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Danh sách thống kê*/}
          <div className="border-bottom border-inline-bottom-dashed mb-3"></div>
          <div className="row col-xl-12 col-md-12">
            <div className="col-xxl-6 col-xl-6">
              <div className="">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    <h4 className="fw-bold mb-0">
                      {Number(totalCus.totalStatus)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </h4>
                    <div className="ms-2">
                      <span className="text-muted ms-1">Chưa giao dịch</span>
                    </div>
                  </div>
                  <div className="progress progress-lg mb-4 rounded-5">
                    {/* <div
                      className="progress-bar bg-secondary"
                      role="progressbar"
                      style={{ width: "10%" }}
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      10%
                    </div> */}
                    <div
                      className="progress-bar bg-primary op-8"
                      role="progressbar"
                      style={{
                        width: totalCus.totalStatus > 0 ? (
                            (totalCus.totalBuyerStatus / totalCus.totalStatus) *
                            100
                          ).toFixed() + "%" : "0"
                      }}
                      aria-valuenow="20"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {(
                        (totalCus.totalBuyerStatus / totalCus.totalStatus) *
                        100
                      ).toFixed()}
                      %
                    </div>
                    <div
                      className="progress-bar bg-warning op-8"
                      role="progressbar"
                      style={{
                        width:totalCus.totalStatus > 0 ? (
                            (totalCus.totalRentStatus / totalCus.totalStatus) *
                            100
                          ).toFixed() + "%" : "0"
                      }}
                      aria-valuenow="60"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {(
                        (totalCus.totalRentStatus / totalCus.totalStatus) *
                        100
                      ).toFixed()}
                      %
                    </div>
                    <div
                      className="progress-bar bg-success op-8"
                      role="progressbar"
                      style={{
                        width:totalCus.totalStatus > 0 ? (
                            (totalCus.totalInvestStatus /
                              totalCus.totalStatus) *
                            100
                          ).toFixed(1) + "%" : "0"
                      }}
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {(
                        (totalCus.totalInvestStatus / totalCus.totalStatus) *
                        100
                      ).toFixed()}
                      %
                    </div>
                  </div>
                  <ul className="list-unstyled mb-0 pt-2 crm-deals-status">
                    {totalCus.dataStatus.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            item.loaisanpham
                              .toLowerCase()
                              .includes("mua nhà") ||
                            item.loaisanpham.toLowerCase().includes("mua đất")
                              ? "primary"
                              : item.loaisanpham.toLowerCase().includes("thuê")
                              ? "warning"
                              : "success"
                          }
                        >
                          <div className="d-flex align-items-center justify-content-between fs-13">
                            <div>{item.loaisanpham}</div>
                            <div className="fs-13 text-muted">
                              {item.total} khách
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* Giao dịch thành công */}
            <div className="col-xxl-6 col-xl-6">
              <div className="">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    <h4 className="fw-bold mb-0">
                      {Number(totalCus.totalStatusSuc)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </h4>
                    <div className="ms-2">
                      <span className="text-muted ms-1">
                        Giao dịch thành công
                      </span>
                    </div>
                  </div>
                  <div className="progress progress-lg mb-4 rounded-5">
                    {/* <div
                      className="progress-bar bg-secondary"
                      role="progressbar"
                      style={{ width: "10%" }}
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      10%
                    </div> */}
                    <div
                      className="progress-bar bg-warning op-8"
                      role="progressbar"
                      style={{
                        width: totalCus.totalStatusSuc > 0 ? ((
                            (totalCus.totalRentStatusSuc /
                              totalCus.totalStatusSuc) *
                            100
                          ).toFixed() + "%") : "0"
                      }}
                      aria-valuenow="60"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {(
                        (totalCus.totalRentStatusSuc /
                          totalCus.totalStatusSuc) *
                        100
                      ).toFixed()}
                      %
                    </div>
                    <div
                      className="progress-bar bg-success op-8"
                      role="progressbar"
                      style={{
                        width: totalCus.totalStatusSuc > 0 ? (
                            (totalCus.totalInvestStatusSuc /
                              totalCus.totalStatusSuc) *
                            100
                          ).toFixed() + "%" : "0"
                      }}
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {(
                        (totalCus.totalInvestStatusSuc /
                          totalCus.totalStatusSuc) *
                        100
                      ).toFixed()}
                      %
                    </div>
                    <div
                      className="progress-bar bg-primary op-8"
                      role="progressbar"
                      style={{
                        width: totalCus.totalStatusSuc > 0 ? ((
                            (totalCus.totalBuyerStatusSuc /
                              totalCus.totalStatusSuc) *
                            100
                          ).toFixed() + "%") : "0"
                      }}
                      aria-valuenow="20"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {(
                        (totalCus.totalBuyerStatusSuc /
                          totalCus.totalStatusSuc) *
                        100
                      ).toFixed()}
                      %
                    </div>
                  </div>
                  <ul className="list-unstyled mb-0 pt-2 crm-deals-status">
                    {totalCus.dataStatusSuc.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            item.loaisanpham
                              .toLowerCase()
                              .includes("mua nhà") ||
                            item.loaisanpham.toLowerCase().includes("mua đất")
                              ? "primary"
                              : item.loaisanpham.toLowerCase().includes("thuê")
                              ? "warning"
                              : "success"
                          }
                        >
                          <div className="d-flex align-items-center justify-content-between fs-13">
                            <div>{item.loaisanpham}</div>
                            <div className="fs-13 text-muted">
                              {item.total} khách
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LayoutLeft;

// Xử lý tính tổng khách hàng.
function handleTotalCustomer(data) {
  let totalCustomer = 0
  if (data.length > 0) {
    // tổng khách.
    for (let i = 0; i < data.length; i++) {
      totalCustomer += data[i].total
    }
  }
  return totalCustomer
}

function handleDataTotalCus(dataCountCate) {
  // Tổng khách.
  const totalCustomer = handleTotalCustomer(dataCountCate)
  // Tổng khách mua.
  const dataCusBuyer = dataCountCate.filter((item) => {
    return (
      item.loaisanpham.toLowerCase().includes("mua nhà") ||
      item.loaisanpham.toLowerCase().includes("mua đất")
    )
  })
  const totalCusBuyer = handleTotalCustomer(dataCusBuyer)
  // tổng khách thuê.
  const dataCusRent = dataCountCate.filter((item) =>
    item.loaisanpham.toLowerCase().includes("thuê"),
  )
  const totalCusRent = handleTotalCustomer(dataCusRent)

  // tổng khách đầu tư.
  const dataCusInvester = dataCountCate.filter((item) =>
    item.loaisanpham.toLowerCase().includes("đầu tư"),
  )
  const totalCusInvester = handleTotalCustomer(dataCusInvester)

  return {total: totalCustomer, cusbuyer: totalCusBuyer, cusrent: totalCusRent, cusinvester: totalCusInvester}
}