import { useState, useEffect} from "react";
import classNames from "classnames/bind";
import styles from "../Statistical.module.scss"
import {
  HiOutlineCreditCard,
  HiOutlineMegaphone,
  HiOutlineWallet,
} from "react-icons/hi2"

const cx = classNames.bind(styles);

function Statistical() {
  const [data, setData] = useState([])
  const phone = localStorage.getItem("sodienthoai")
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        process.env.REACT_APP_APIGetUserPayHis + `?keyword=${phone}&quantity=1`,
      )
      const dataRes = await response.json()
      if (dataRes.length > 0) {
        setData(dataRes)
      }
    }
    fetchData()
  }, [])

  if (data.length === 0) {
    return
  }

  // Tổng chi cho quảng cáo
  const totalAdMoney = (Number(data[0].total_deposit) - Number(data[0].total_balance)).toLocaleString().replace(/[.]/g, ",")

  return (
    <div className="row">
      <div className="col">
        <div className="card mb-2">
          <div className="card-body">
            <div className="d-flex align-items-top">
              <HiOutlineCreditCard
                className={cx(
                  "me-2",
                  "font-4",
                  "bg-pink",
                  "rounded-3 p-2",
                  "colorW",
                )}
              />
              <div className="flex-fill">
                <h5 className={cx("font-1")}>
                  {Number(data[0].total_deposit)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                </h5>
                <p className={cx("font", "op-6 mb-0")}>TỔNG NẠP</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card mb-2">
          <div className="card-body">
            <div className="d-flex align-items-top">
              <HiOutlineMegaphone
                className={cx(
                  "me-2",
                  "font-4",
                  "bg-teal rounded-3 p-2 op-8",
                  "colorW",
                )}
              />
              <div className="flex-fill">
                <h5 className={cx("font-1")}>{totalAdMoney}</h5>
                <p className={cx("font", "op-6 mb-0")}>TỔNG CHI</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card mb-2">
          <div className="card-body">
            <div className="d-flex align-items-top">
              <HiOutlineWallet
                className={cx(
                  "me-2",
                  "font-4",
                  "bg-warning rounded-3 p-2 op-8",
                  "colorW",
                )}
              />
              <div className="flex-fill">
                <h5 className={cx("font-1")}>
                  {Number(data[0].total_balance)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                </h5>
                <p className={cx("font", "op-6 mb-0")}>CÒN LẠI</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Statistical;