import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import PageCalendar from "./PageCalendar"
import MenuFooter from "../MenuFooter/MenuFooter"

function PageProductMobile() {
    return (
      <>
        {/* Header */}
        <HeaderDashboardMobile namePage="Lịch hẹn" />
        {/* Main */}
        <PageCalendar />
        {/* MenuFooter */}
        <MenuFooter />
      </>
    )
}

export default PageProductMobile