import LoadingSample from "./LoadingSample";
import LoadingPlaceholder from "./LoadingPlaceholder";
import LoadingText from "./LoadingText";
import LoadingSpinner from "./LoadingSpinner";

function Loading() {
    return ( 
        <LoadingSample />
     );
}

function LoadingPlace({ height, width, className }) {
  return <LoadingPlaceholder height={height} width={width} className={className} />
}

export default Loading
export {LoadingPlace, LoadingText, LoadingSpinner}

