import { useState, useEffect, useContext } from "react"
import HeaderDashboardPage from "./Header"
import classNames from "classnames/bind"
import styles from "./Dashboard.module.scss"
import Sanpham from "./Component/Sanpham"

import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { LoadingSpinner } from "../../../components/Loading"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import PageProductMobile from "../../mobile/PageProduct"

const cx = classNames.bind(styles)

function SanphamPage() {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [dataEstates, setDataEstates] = useState([])
  const [dataBooking, setDataBooking] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    const response = await fetch(
      process.env.REACT_APP_APIGetDataMarketSimple +
        `?phone=${dataUser[0].sodienthoai}`,
    )
    const dataRes = await response.json()

    // data booking
    const responseBooking = await fetch(
      process.env.REACT_APP_APIGetForUserBooking + `?only=${dataUser[0].id}`,
    )
    const dataResBooking = await responseBooking.json()

    setDataBooking(dataResBooking)
    setDataEstates(dataRes)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <CheckPCMobile>
        {(isMobile) =>
          isMobile ? (
            <PageProductMobile
              loading={loading}
              dataEstates={dataEstates}
              dataBooking={dataBooking}
            />
          ) : (
            <ListProductPC
              loading={loading}
              dataEstates={dataEstates}
              dataBooking={dataBooking}
            />
          )
        }
      </CheckPCMobile>
    </>
  )
}

export default SanphamPage

// Danh sách sản phẩm được hiển thị trên máy tính
function ListProductPC({ loading, dataEstates, dataBooking }) {
  return (
    <div className={cx("page justify-content-start", "bg-page")}>
      <div className="mt-3">
        <HeaderDashboardPage />
        <div className={cx("mt-3")}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Sanpham data={dataEstates} dataBooking={dataBooking} />
          )}
        </div>
      </div>
    </div>
  )
}
