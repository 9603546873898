import { FaRegClock } from "react-icons/fa"
import { IoCalendarOutline } from "react-icons/io5"
import { useState } from "react"
import classNames from "classnames/bind"
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2"

import styles from "./Support.module.scss"
import ModalReact from "../../../../components/Modal/ModalReact"
import FormSupport from "./FormSupport"
import { BookingMain } from "../Booking"

const cx = classNames.bind(styles)

function SupportMain() {
  const [showDG, setShowDG] = useState(false)
  const [showBooking, setShowBooking] = useState(false)

  // Điều kiện hiện modal.
  const phone = localStorage.getItem("sodienthoai")
  // Tắt bật modal đóng góp ý kiến.
  const handleOpenDG = () =>
    phone
      ? setShowDG(true)
      : alert("Cảm ơn bạn, hãy giúp tôi đăng nhập trước nhé.")
  const handleCloseDG = () => setShowDG(false)

  // bật tắt modal booking.
  const handleOpenBooking = () => setShowBooking(true)
  const handleCloseBK = () => setShowBooking(false)
  return (
    <>
      <div className={cx("d-flex")}>
        <div
          className={cx("d-flex align-items-center me-5", "cursor-pointer")}
          onClick={handleOpenDG}
        >
          <HiOutlineChatBubbleBottomCenterText
            className={cx("me-3", "fs-30")}
          />
          <div className={cx("")}>
            <div className={cx("fw-6")}>Hỗ trợ giải đáp</div>
            <div>hotro.view360@gmail.com</div>
          </div>
        </div>
        <div className={cx("d-flex align-items-center me-5", "cursor-pointer")}>
          <FaRegClock className={cx("me-3")} />
          <div className={cx("")}>
            <div className={cx("fw-6")}>Trợ giúp</div>
            <div>Các ngày trong tuần</div>
          </div>
        </div>
        <div
          className={cx("d-flex align-items-center", "cursor-pointer")}
          onClick={handleOpenBooking}
        >
          <IoCalendarOutline className={cx("me-3")} />
          <div className={cx("")}>
            <div className={cx("fw-6")}>Đặt lịch</div>
            <div>Đặt lịch xem nhà trước</div>
          </div>
        </div>
      </div>
      {/* Đóng góp ý kiến */}
      <ModalReact
        showModal={showDG}
        handleClose={handleCloseDG}
        modalTitle="Hỗ trợ giải đáp?"
        theme={<FormSupport />}
      />
      {/* Modal booking */}
      <ModalReact
        showModal={showBooking}
        handleClose={handleCloseBK}
        modalTitle="Đặt lịch hẹn"
        theme={<BookingMain />}
      />
    </>
  )
}

export default SupportMain
