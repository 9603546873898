import { useState, useLayoutEffect } from "react";

function CheckPCMobile({children}) {
    const [isMobile, setIsMobile] = useState(false);

    useLayoutEffect(() => {
         const userAgent = navigator.userAgent
        const isMobileDevice = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone/i.test(userAgent)
        setIsMobile(isMobileDevice)
    }, [])

    return children(isMobile)
}

export default CheckPCMobile