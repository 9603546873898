
import classNames from "classnames/bind"
import {
  IoPieChartOutline,
  IoCalendarOutline,
  IoHomeOutline,
} from "react-icons/io5"
import {
  HiOutlineHomeModern,
  HiOutlineUsers,
} from "react-icons/hi2"
import styles from "./Dashboard.module.scss"
import HeaderDashboard from "../../../components/Header/HeaderDashboard"

const cx = classNames.bind(styles)

const dataCate = [
  { path: "/", icon: <IoHomeOutline />, name: "Home" },
  { path: "/dashboard", icon: <IoPieChartOutline />, name: "Thống kê" },
  { path: "/san-pham", icon: <HiOutlineHomeModern />, name: "Sản phẩm" },
  { path: "/lich-hen", icon: <IoCalendarOutline />, name: "Lịch hẹn" },
  { path: "/khach-hang", icon: <HiOutlineUsers />, name: "Khách hàng" },
  // { path: "/hop-dong", icon: <PiHandshakeLight />, name: "Hợp đồng" },
  // { path: "/support", icon: <HiOutlineEnvelope />, name: "Yêu cầu" },
]

function HeaderDashboardPage() {
  return <HeaderDashboard data={dataCate} />
}

export default HeaderDashboardPage
