import { useContext, useState, useRef, useEffect } from "react"
import io from "socket.io-client"
import Tippy from "@tippyjs/react/headless"
import classNames from "classnames/bind"
import { IoNotificationsOutline } from "react-icons/io5"

import styles from "./Notification.module.scss"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import { handleMethodCreateData } from "../../handle-reuses/reuses/method"
import { images } from "../../assets/images"

const cx = classNames.bind(styles)

function NotificationHeaderHome({ classIcon, className }) {
  const { dataUser } = useContext(AppContext)
  const [dataNotifi, setDataNotifi] = useState([])
  const [notifiCount, setNotifiCount] = useState(0)

  const socketRef = useRef()
  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_SERVER)
  }, [])
  useEffect(() => {
    socketRef.current.on("server-notification", function (data) {
      const sortData = data.sort((a, b) => {
        let min_ = new Date(a.start_time).getTime()
        let max_ = new Date(b.start_time).getTime()
        return max_ - min_
      })
      setDataNotifi(sortData)
      setNotifiCount(data.length)
    })
    return () => {
      socketRef.current.off("server-notication")
    }
  }, [])

  // tắt thông báo tạm thời
  const handleNotifiCountView = () => {
    setNotifiCount(0)
  }

  return (
    dataUser.length > 0 && (
      <button
        className={cx(
          className,
          "border-0 bg-transparent",
          "position-relative",
        )}
        onClick={handleNotifiCountView}
      >
        <Tippy
          interactive
          delay={[0, 0]}
          placement="bottom-end"
          trigger="click"
          render={() => <ListNotification data={dataNotifi} />}
        >
          <span className="">
            <IoNotificationsOutline className={cx(classIcon)} />
            {notifiCount === 0 ? (
              ""
            ) : (
              <span className="countNotifi">{notifiCount}</span>
            )}
          </span>
        </Tippy>
      </button>
    )
  )
}
export default NotificationHeaderHome

// List notification.
function ListNotification({ data }) {
  const { dataUser } = useContext(AppContext)
  const handleEventButton = (path) => {
    window.open(path)
  }

  // count view notifi user.
  const handleAddNotifiCountViewUser = (notifiId) => {
    if (dataUser.length > 0) {
      const socket = io(process.env.REACT_APP_SERVER)
      const params = { userid: dataUser[0].id, notifi_id: notifiId }
      socket.emit("client-count-view", params)
      // console.log(parmas)
      socket.on("server-count-view", (dataCheck) => {
        if (dataCheck.length === 0) {
          const object = {
            notifi_id: notifiId,
            userid: dataUser[0].id,
          }
          handleMethodCreateData(
            object,
            () => {},
            process.env.REACT_APP_APICreateNotifiCountView,
          )
        }
      })
    }
  }

  return (
    <div className="bg-tippy">
      <ul className="fs-14">
        {data.length === 0 && <li>Không có thông báo</li>}
        {data.length > 0 &&
          data.map((item) => {
            return (
              <li
                key={item.id}
                className="mb-4"
                onClick={() => handleAddNotifiCountViewUser(item.id)}
              >
                <a href="#" className="d-flex align-items-center">
                  <img
                    src={images.notifi}
                    alt=""
                    width="50"
                    height="50"
                    className="me-3"
                  />
                  <div>
                    <span className="fs-15">{item.title}</span>
                    <p className="text-muted mb-2">{item.description}</p>
                    {item.button !== null && (
                      <button
                        className="rounded-3 border-1 btn btn-danger"
                        onClick={() => handleEventButton(item.path)}
                      >
                        {item.button}
                      </button>
                    )}
                  </div>
                  {item.image !== null && (
                    <img src={item.image} alt="" className="imageNotifi ms-3" />
                  )}
                </a>
              </li>
            )
          })}
      </ul>
    </div>
  )
}
