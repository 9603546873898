import { Link } from "react-router-dom"

import InfoContactHorizontal from "../Info/Info-contact-horizontal"
import { isAuthen } from "../../routers"
import InfoUser from "../Info/InfoUser"
import ButtonPost from "../Button/index"

function HeaderA({ id }) {
  return (
    <div className="landing-page-wrapper-1" style={{ height: "84px" }}>
      <header className="app-header">
        <div className="main-header-container container-fluid">
          <div className="header-content-left">
            <div className="header-element">
              <div className="horizontal-logo">
                <Link to="/" className="header-logo">
                  <img
                    src="../assets/images/brand-logos/toggle-logo.png"
                    alt="logo"
                    className="toggle-logo"
                  />
                  <img
                    src="../assets/images/brand-logos/toggle-dark.png"
                    alt="logo"
                    className="toggle-dark"
                  />
                </Link>
              </div>
            </div>
            <div className="header-element">
              <a
                href="#"
                className="sidemenu-toggle header-link"
                data-bs-toggle="sidebar"
              >
                <span className="open-toggle">
                  <i className="ri-menu-3-line fs-20"></i>
                </span>
              </a>
            </div>
          </div>

          <div className="header-content-right">
            <div className="header-element align-items-center">
              <div className="btn-list d-lg-none d-block">
                <a href="#" className="btn btn-primary-light">
                  Sign Up
                </a>
                <button
                  className="btn btn-outline-light btn-wave waves-effect waves-light avatar avatar-rounded"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#switcher-canvas"
                >
                  <i
                    className="ffi fi-rr-settings-sliders me-1 fs-17 position-relative"
                    style={{ top: "3px" }}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <aside className="app-sidebar sticky" id="sidebar">
        <div className="container p-0">
          <div className="">
            <nav className="main-menu-container nav nav-pills sub-open">
              <div className="landing-logo-container">
                <div className="horizontal-logo">
                  <Link to="/" className="header-logo">
                    <img
                      src="https://muanhahaiphong.com/uploads/logo_2_mau.png"
                      alt="logo"
                      className="desktop-logo"
                    />
                    <img
                      src="https://muanhahaiphong.com/uploads/logo_2_mau.png"
                      alt="logo"
                      className="desktop-white"
                    />
                  </Link>
                </div>
              </div>
              <div className="slide-left" id="slide-left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#7b8191"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  {" "}
                  <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>{" "}
                </svg>
              </div>
              <div className="d-flex justify-content-center col-7">
                <InfoContactHorizontal />
              </div>

              <div className="slide-right" id="slide-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#7b8191"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  {" "}
                  <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>{" "}
                </svg>
              </div>
              <div className="d-lg-flex d-none align-items-center">
                <div className="btn-list mt-lg-2 mt-xl-0 mt-0">
                  {isAuthen ? (
                    <ButtonPost />
                  ) : (
                    <Link
                      to="/login"
                      className="btn fw-6 me-3 btn-outline-dark"
                    >
                      Đăng nhập
                    </Link>
                  )}
                </div>
                {/* <!-- Start::header-element --> */}
                <InfoUser />
                {/* <!-- End::header-element --> */}
              </div>
            </nav>
          </div>
        </div>
      </aside>

      <div className=""></div>
    </div>
  )
}

export default HeaderA
