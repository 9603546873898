import { useLayoutEffect, useRef, useState, useEffect, useContext } from "react"
import io from "socket.io-client"
import classNames from "classnames/bind"
import moment from "moment"
import { BsDot } from "react-icons/bs"
import { FaRegEyeSlash } from "react-icons/fa"
import { MdOutlineElectricalServices } from "react-icons/md"
import { BsWater } from "react-icons/bs"
import { IoWarningOutline } from "react-icons/io5"
import { PiHandshakeLight, PiTimerBold } from "react-icons/pi"
import { BsCreditCard2Back } from "react-icons/bs"
import { TbAirConditioning } from "react-icons/tb"
import { MdHotTub } from "react-icons/md"

import styles from "./detail.module.scss"
import { SliderImageList } from "../../../components/Slider"
import { handleLimitString, handleFormatPrice } from "../../../handle-reuses/reuses"
import { images } from "../../../assets/images"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import SideBarHome from "../Home/SidebarHome"
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"

const cx = classNames.bind(styles)

function DetailEstate({ data }) {
  // const [dataEstate, setDataEstate] = useState(data)
  const socket = useRef()
  const estate = data.find((item) => {
    return item
  })
  let listImages = data[0].images
  if (listImages) {
    listImages = listImages.split(",")
  } else {
    listImages = [images.noImagePro]
  }

  // lựa chọn loại hình xem.
  const [optionView, setOptionView] = useState("listImages")
  const handleOptionImagesView360 = (key) => {
    setOptionView(key)
  }
  // Quản lý get author cho product.
  useLayoutEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    socket.current.emit("client-userid", estate.userid)
    return () => {
      socket.current.off("client-userid")
    }
  }, [])
  // author tương ứng
  const [author, setAuthor] = useState([])
  useLayoutEffect(() => {
    // Lấy author ban đầu
    socket.current.on("server-userid", (dataAuthor) => {
      setAuthor(dataAuthor)
      
    })

    // Xác định online-offline
    socket.current.on("server-online-offline", (dataUserUpdate) => {
      
      if (dataUserUpdate.length > 0) {
        if (dataUserUpdate[0].id === estate.userid) {
          setAuthor(dataUserUpdate)
        } else {
          setAuthor([])
        }
      } else {
        setAuthor([])
      }
    })
    return () => {
      socket.current.off("server-userid")
      socket.current.off("server-online-offline")
    }
  }, [])

  // Litmit phone.
  const phone = handleLimitString(estate.phone, 3)
  const [limitPhone, setLimitPhone] = useState(phone)
  const hidenButtonPhone = useRef("")
  const handleMorePhone = () => {
    setLimitPhone(estate.phone)
    hidenButtonPhone.current = "hide"
    //   Count click phone.
    const params = {
      countclick: Number(data[0].countclick) + 1,
      id: data[0].id,
    }
    socket.current.emit("client-click-phone", params)
  }

  //   Quản lý view 360
  useEffect(() => {
    if (optionView === "view360") {
      // Hiển thị view360.
      if (estate.view360.length > 0) {
        document.getElementById("iframeView360").innerHTML = `
        <iframe id="vt_iframe_${data[0].id}" allow="accelerometer; camera; display-capture; fullscreen; geolocation; gyroscope; magnetometer; microphone; midi; xr-spatial-tracking;" width="100%" height="450px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="${data[0].view360}"></iframe>
      `
      } else {
        document.getElementById(
          "iframeView360",
        ).innerHTML = `Sản phẩm không có view 360.`
      }
    }
  }, [optionView])
  // Quản lý description.
  useEffect(() => {
    // Hiển thị nội dung.
    document.getElementById("discriptionDetail").innerHTML = data[0].discription
  }, [])

  return (
    <div className={cx("container_wrapper", "mt-3")}>
      <div className={cx("content_wrapper")}>
        <div className={cx("row justify-content-between")}>
          {/* Left */}
          <div className={cx("col-xl-2 col-md-12")}>
            {/* <img src={images.bannerHeight} alt="" style={{ width: "100%" }} className={cx("rounded-4")} /> */}
            <div
              className={cx("d-flex justify-content-center")}
              style={{ position: "sticky", top: "121px" }}
            >
              <CheckPCMobile>
                {(isMobile) => (isMobile ? "" : <SideBarHome />)}
              </CheckPCMobile>
            </div>
          </div>
          {/* Main */}
          <div className={cx("col-xl-7 col-md-12")}>
            {/* Title */}
            <h1 className={cx("fs-29 text-center")}>{estate.title}</h1>
            <div className={cx("text-center")}>
              <span className={cx("fs-15")}>
                {estate.cate}
                <BsDot />
                {estate.address}
                <BsDot />
                {estate.districts}
                <BsDot />
                {estate.city}
              </span>
              <div className={cx("fs-18")}>
                <span className={cx("me-2")}>Giá</span>
                {handleFormatPrice(estate.price)}
                {estate.donvi === "Thỏa thuận" || estate.donvi === ""
                  ? estate.donvi
                  : " / " + estate.donvi.toLowerCase()}
                <BsDot />
                <span className={cx("fs-15 text-muted")}>
                  Cập nhật {moment(estate.update_time).locale("vi").fromNow()}
                </span>
              </div>
            </div>
            <div className={cx("col-12 d-flex justify-content-center")}>
              <div
                className={cx("mb-4 mt-3")}
                style={{
                  width: "50%",
                  borderTop: "1px solid #495056",
                  fontSize: "21px",
                }}
              ></div>
            </div>

            {/* list images */}
            <div>
              <div className={cx("d-flex mb-2 fs-15 justify-content-center")}>
                <button
                  className={cx("border-0 rounded-2 btn-outline-dark me-2")}
                  onClick={() => handleOptionImagesView360("listImages")}
                >
                  Hình ảnh
                </button>
                <button
                  className={cx("border-0 rounded-2 btn-outline-dark me-2")}
                  onClick={() => handleOptionImagesView360("view360")}
                >
                  View 360
                </button>
              </div>
              <div
                style={{
                  display: optionView === "listImages" ? "block" : "none",
                }}
              >
                <SliderImageList data={listImages} height="450" />
              </div>

              <div
                style={{
                  display: optionView === "view360" ? "block" : "none",
                  height: "450px",
                }}
                id="iframeView360"
              ></div>
            </div>
            {/* Info rent */}
            {estate.cate.indexOf("Cho thuê") !== -1 ? (
              <div className={cx("d-flex justify-content-center mt-3")}>
                {/* <div
                  className={cx(
                    "mb-3 mt-4 border-top border-block-start-dashed",
                  )}
                ></div> */}
                <InfoRentEstate data={data} />
              </div>
            ) : (
              ""
            )}
            {/* description */}
            <div
              className={cx("mb-3 mt-3 border-top border-block-start-dashed")}
            ></div>
            <div className={cx("mt-3 fs-17")}>
              <div className="fw-6 text-center">
                Mô tả
                <div className={cx("col-12 d-flex justify-content-center")}>
                  <div
                    className={cx("mb-3 mt-3")}
                    style={{
                      width: "35%",
                      borderTop: "1px solid #495056",
                      fontSize: "21px",
                    }}
                  ></div>
                </div>
              </div>

              <div id="discriptionDetail"></div>
            </div>
          </div>
          {/* Right */}
          <div className={cx("col-xl-3 col-md-12")}>
            <div className={cx("formBooking")}>
              {/* Info author */}
              <div className={cx("d-flex")}>
                <span
                  className={cx(
                    "avatar avatar-lg me-2 avatar-rounded",
                    author.length > 0
                      ? author[0].online_offline === 1
                        ? "online"
                        : "offline"
                      : "",
                  )}
                >
                  <img
                    src={
                      author.length > 0
                        ? author[0].avatar
                          ? author[0].avatar
                          : images.noImage
                        : images.noImage
                    }
                    alt=""
                  />
                </span>

                <div className={cx("fs-15 ms-2")}>
                  <span>{estate.hoten}</span>
                  <BsDot />
                  <span>{limitPhone}</span>
                  <button
                    className={cx(
                      "border-0",
                      "bg-none",
                      hidenButtonPhone.current,
                    )}
                    onClick={handleMorePhone}
                  >
                    <FaRegEyeSlash />
                  </button>
                  <p className={cx("text-muted mb-2")}>
                    {author.length > 0 &&
                      (author[0].online_offline === 1
                        ? "Đang online"
                        : "Cập nhật " +
                          moment(author[0].connect_time)
                            .locale("vi")
                            .fromNow())}
                  </p>
                </div>
              </div>
              <CountViewEstate data={estate} />
              <div
                className={cx("mb-3 border-top border-block-start-dashed")}
              ></div>
              <BookingEstate dataEstate={estate} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailEstate

// info cho thuê.
const InfoRentEstate = ({ data }) => {
  // tiến độ thanh toán.
  let paymentMethods = data[0].payment_methods
  if (paymentMethods === 1) {
    paymentMethods = "Đêm"
  } else if (paymentMethods === 2) {
    paymentMethods = "Ngày"
  } else if (paymentMethods === 3) {
    paymentMethods = "Tháng"
  } else if (paymentMethods === 4) {
    paymentMethods = "Năm"
  }
  return (
    <div className="fs-17">
      <div className="fw-6 text-center">Thông tin cho thuê</div>
      <div className={cx("col-12 d-flex justify-content-center")}>
        <div
          className={cx("mb-3 mt-3")}
          style={{
            width: "68%",
            borderTop: "1px solid #495056",
            fontSize: "21px",
          }}
        ></div>
      </div>
      <div className="mt-2">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-5">
            <TbAirConditioning className="me-3 fs-21" />
            <div>
              <div>Điều hòa</div>
              <div className="text-muted fs-14">
                {data[0].dieuhoa === 1 ? "Có" : "Không"}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <MdHotTub className="fs-21 me-2 ms-3" />
            <div>
              <div>Nóng lạnh</div>
              <div className="text-muted fs-14">
                {data[0].binhnonglanh === 1 ? "Có" : "Không"}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mt-3">
          <MdOutlineElectricalServices className="me-3 fs-21" />
          <div>
            <div className="">Giá điện sử dụng</div>
            <div className="text-muted mt-0">
              <span className="me-1">Giá tiêu thụ điện năng</span>
              {Number(data[0].electricity_money)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ / số điện
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <BsWater className="me-3 fs-21" />
          <div>
            <div className="">Giá nước sử dụng</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Giá tiêu thụ nước sinh hoạt</span>
              {Number(data[0].water_money)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ / khối nước
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <IoWarningOutline className="me-3 fs-21" />
          <div>
            <div className="">Dịch vụ khác</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Chi phí cho các dịch vụ khác</span>
              {Number(data[0].service_price)
                .toLocaleString()
                .replace(/[.]/g, ",")}
              đ / tháng
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <PiHandshakeLight className="me-3 fs-21" />
          <div>
            <div className="">Thời gian ký hợp đồng</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Thời gian khi ký hợp đồng là</span>
              {Number(data[0].contract_time)} tháng trở lên
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <BsCreditCard2Back className="me-3 fs-21" />
          <div>
            <div className="">Số tiền đặt cọc khi thuê</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Số tiền cần đặt cọc khi thuê</span>
              {Number(data[0].deposit).toLocaleString().replace(/[.]/g, ",")}đ
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <PiTimerBold className="me-3 fs-21" />
          <div>
            <div className="">Kỳ hạn thanh toán</div>
            <div className="text-muted mt-0">
              <span className="me-1"> Tiến độ thanh toán được tính theo</span>(
              {paymentMethods})
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Xác định lượt xem sản phẩm.
function CountViewEstate({ data }) {
  const [updateView, setUpdateView] = useState(data.countview)

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER)
    socket.on("server-click-views", (dataUpdate) => {
      if (data.id === dataUpdate[0].id) {
        setUpdateView(dataUpdate[0].countview)
      }
    })
    return () => {
      socket.off("server-click-views")
    }
  }, [])

  return (
    <div className={cx("mb-2 text-muted")}>
      {Number(updateView).toLocaleString().replace(/[.]/g, ",")} lượt xem
      <BsDot />
      {moment(data.update_time).locale("vi").fromNow()}
    </div>
  )
}

// Booking
function BookingEstate({ dataEstate }) {
  const { dataUser } = useContext(AppContext)
  const socket = useRef()

  const [object, setObject] = useState({
    fullname: dataUser.length > 0 ? dataUser[0].hoten : "",
    fullphone: dataUser.length > 0 ? dataUser[0].sodienthoai : "",
    discription: "",
  })
  const [checkName, setCheckName] = useState(true)
  const [checkPhone, setCheckPhone] = useState(true)
  const [checkDis, setCheckDis] = useState(true)

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    socket.current.on("server-add-booking", (data) => {
      console.log(data)
    })
  }, [])

  const arrOption = [
    { id: 1, title: "Tôi cần tư vấn sản phẩm này !" },
    { id: 2, title: "Sản phẩm này còn không ?" },
    { id: 3, title: "Dòng sản phẩm tương tự như này ?" },
    { id: 4, title: "Các vấn sản phẩm " + dataEstate.address },
  ]
  const handleFullName = (e) => {
    setCheckName(true)
    setObject({
      fullname: e.target.value,
      fullphone: object.fullphone,
      discription: object.discription,
    })
  }
  const handleFullPhone = (e) => {
    setCheckPhone(true)
    setObject({
      fullname: object.fullname,
      fullphone: e.target.value,
      discription: object.discription,
    })
  }
  const handleDiscription = (e) => {
    setCheckDis(true)
    setObject({
      fullname: object.fullname,
      fullphone: object.fullphone,
      discription: e.target.value,
    })
  }

  const handleOptionContent = (key) => {
    const findOption = arrOption.find((item) => {
      return item.id === key
    })
    setCheckDis(true)
    setObject({
      fullname: object.fullname,
      fullphone: object.fullphone,
      discription: findOption.title,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Kiểm tra trường bắt buộc.
    if (object.fullname === "") {
      setCheckName(false)
    }
    if (object.fullphone === "") {
      setCheckPhone(false)
    }
    if (object.discription === "") {
      setCheckDis(false)
    }
    if (
      object.fullname !== "" &&
      object.fullphone !== "" &&
      object.discription !== ""
    ) {
      // Gửi yêu cầu đến server.
      object.simpleid = dataEstate.id
      object.author_simple = dataEstate.userid
      if (dataUser.length > 0) {
        object.email = dataUser[0].email
      } else {
        object.email = ""
      }
      socket.current.emit("client-add-booking", object)
      setTimeout(() => {
        setObject({
          fullname: object.fullname,
          fullphone: object.fullphone,
          discription: "",
        })
      }, 100)
    }
  }

  return (
    // <div className={cx("fs-17")}>Gửi yêu cầu liên hệ</div>
    <form onSubmit={handleSubmit}>
      <div className="col-xl-12 col-md-12">
        <div className="form-floating mb-1 mt-2">
          <input
            type="text"
            value={object.fullname}
            className="form-control rounded-3"
            placeholder="Họ tên"
            onChange={handleFullName}
          />
          <label>
            Họ tên
            <sup className="text-danger">*</sup>
          </label>
          {!checkName && (
            <span className={cx("text-danger", "fs-12")}>
              *Hãy cho tôi biết tên của bạn
            </span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12 mt-3">
        <div className="form-floating mb-1">
          <input
            type="text"
            value={object.fullphone}
            className="form-control rounded-3"
            placeholder="Số điện thoại"
            onChange={handleFullPhone}
          />
          <label>
            Số điện thoai <sup className="text-danger">*</sup>
          </label>
          {!checkPhone && (
            <span className={cx("text-danger", "fs-12")}>
              *Hãy để lại số điện thoại của bạn
            </span>
          )}
        </div>
      </div>
      <div className="col-xl-12 col-md-12 mt-3">
        <div className={cx("mb-3")}>
          <button
            className={cx("border-0 rounded-5 px-3 py-1 mb-2 btn-outline-dark")}
            onClick={() => handleOptionContent(1)}
            type="button"
          >
            Tôi cần tư vấn sản phẩm này !
          </button>
          <button
            type="button"
            className={cx("border-0 rounded-5 px-3 py-1 mb-2 btn-outline-dark")}
            onClick={() => handleOptionContent(2)}
          >
            Sản phẩm này còn không ?
          </button>
          <button
            type="button"
            className={cx("border-0 rounded-5 px-3 py-1 mb-2 btn-outline-dark")}
            onClick={() => handleOptionContent(3)}
          >
            Dòng sản phẩm tương tự như này ?
          </button>
          <button
            type="button"
            className={cx("border-0 rounded-5 px-3 py-1 btn-outline-dark")}
            onClick={() => handleOptionContent(5)}
          >
            Các sản phẩm {dataEstate.address}
          </button>
        </div>
        <div className="mb-1">
          <textarea
            type="text"
            value={object.discription}
            className="form-control rounded-3"
            placeholder="Nội dung *"
            rows={3}
            onChange={handleDiscription}
          />
          {!checkDis && (
            <span className={cx("text-danger", "fs-12")}>
              *Hãy để lại lời nhắn cho chủ sở hữu
            </span>
          )}
        </div>
      </div>
      <div className="px-4 mt-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
        <button
          className="btn btn-danger-light mt-3 op-9"
          type="submit"
          id="submitProduct"
        >
          Gửi yêu cầu<i className="bi bi-plus-lg ms-2"></i>
        </button>
      </div>
    </form>
  )
}
