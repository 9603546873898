import { useState, useEffect, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./ManagerPath.module.scss";
import FormAddPath from "./FormAddPath";
import ListPath from "./ListPath";
import { LoadingSpinner } from "../../../components/Loading";

const cx = classNames.bind(styles)

function ManagerPath() {
  const [data, setData] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  // truyền dữ liệu cho form edit.
  const [dataEdit, setDataEdit] = useState()
  // Cập nhật dữ liệu khi có sự thay đổi về sắp xếp.
  const [updateData, setUpdateData] = useState(false)

  const [loading, setLoading] = useState(true)
  // Get data.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_APIGetUserCateMenu)
        const dataRes = await response.json()
        if (dataRes) {
          const sortData = dataRes.sort((a, b) => {
            let min_ = a.sort
            let max_ = b.sort
            return min_ - max_
          })
          setData(sortData)
          setLoading(false)
        } else {
          setLoadingData(true)
        }
        
      } catch (error) {
        
      }
    }
    setTimeout(() => {
      fetchData()
    }, 500);
  }, [updateData])


  // Xử lý trả về data cho form cần edit
  const setDataEditForm = (result) => {
    setDataEdit(result)
  }

  // Xử lý update data.
  const handleUpdateData = (checkUpdate) => {
    setUpdateData(checkUpdate)
  }

  if (loading) {
    return <LoadingSpinner />
  }

    return (
      <div className={cx("containerMenu", "rounded-b")}>
        <div className={cx("menuLeft", "bg-set", "rounded-a")}>
          <div className={cx("contentLeft", "rounded-a")}>
            <FormAddPath dataEdit={dataEdit} data={data} />
            <div className={cx("scroll-container")}></div>
          </div>
        </div>
        <div className={cx("menuRight")}>
          <div className={cx("contentRight")}>
            {loadingData ? (
              <div className="p-3">Đang tải...</div>
            ) : (
                <ListPath data={data} resultData={setDataEditForm} handleUpdate={handleUpdateData} />
            )}
          </div>
        </div>
      </div>
    )
}

export default ManagerPath;