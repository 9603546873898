import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import {
  handleMethodCreateData,
  handleMethodEditData,
} from "../../../handle-reuses/reuses/method"

function FormAddPath({ dataEdit, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()
  const [titleForm, setTitleFrom] = useState()

  // Tái sử dụng nếu có nhiều trường cần set lại
  const reuseSetValue = (fields, data) => {
    fields.forEach((field) => {
      setValue(field, data ? data[field] : "")
    })
  }
  useEffect(() => {
    // Cách 1 : nếu nhiều trường thì dùng
    const fields = ["title", "discription", "path"]
    reuseSetValue(fields, dataEdit)
    dataEdit
      ? setTitleFrom("Sửa đường dẫn")
      : setTitleFrom("Thêm dữ đường dẫn mới")
    // Cách 2: nếu ít trường thì dùng
    // setValue("title", dataEdit ? dataEdit.title : "")
    // setValue("discription", dataEdit ? dataEdit.discription : "")
    // setValue("path", dataEdit ? dataEdit.path : "")
  }, [dataEdit])

  // Nút hủy edit.
  const handleCancelEdit = () => {
    reset()
    setTitleFrom("Thêm đường dẫn mới")
  }

  const onSubmit = (object) => {
    // Kiểm tra trước khi lựa chọn thêm mới OR edit.
    if (!dataEdit) {
      // Create
      const sortDataCre = data.sort((a, b) => b.sort - a.sort)
      object.sort = Number(sortDataCre[0].sort) + 1
      handleMethodCreateData(
        object,
        () => {
          alert("Đã thêm đường dẫn thành công.")
          reset()
        },
        process.env.REACT_APP_APICreateUserCateMenu,
      )
    } else {
      // Edit
      object.id = dataEdit.id
      handleMethodEditData(
        object,
        () => {
          alert("Đường dẫn đã được làm mới.")
          reset()
        },
        process.env.REACT_APP_APIEditUserCateMenu,
      )
    }

    setTitleFrom("Thêm đường dẫn mới")
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-12 p-3 text-center fw-6">{titleForm}</div>
      <div className="row gy-3 p-3">
        {/* Title */}
        <div className="col-12">
          <label className="form-label fw-4 mb-1">Tên đường dẫn</label>
          <input
            {...register("title", { required: true })}
            className="form-control"
            placeholder="Tên đường dẫn..."
          />
          {errors.title && (
            <span className="text-danger fs-9">
              *Bạn chưa đặt tên đường dẫn !
            </span>
          )}
        </div>
        {/* discription */}
        <div className="col-12">
          <label className="form-label fw-4 mb-1">Mô tả</label>
          <textarea
            {...register("discription", { required: true })}
            className="form-control"
            placeholder="Mô tả đường dẫn có ý nghĩa gì..."
          ></textarea>

          {errors.discription && (
            <span className="text-danger fs-9">
              *Bạn chưa viết mô tả cho đường dẫn !
            </span>
          )}
        </div>
        {/* path */}
        <div className="col-12">
          <label className="form-label fw-4 mb-1">Đường dẫn</label>
          <textarea
            {...register("path", { required: true })}
            className="form-control"
            placeholder="/duong-dan"
          ></textarea>

          {errors.path && (
            <span className="text-danger fs-9">
              *Bạn chưa đặt cho đường dẫn !
            </span>
          )}
        </div>
      </div>
      <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-end">
        <button
          type="button"
          className="btn btn-primary-light m-1"
          onClick={handleCancelEdit}
        >
          Hủy<i className="bi bi-plus-lg ms-2"></i>
        </button>
        <button className="btn btn-primary-light m-1" type="submit">
          Lưu lại<i className="bi bi-plus-lg ms-2"></i>
        </button>
      </div>
    </Form>
  )
}

export default FormAddPath
