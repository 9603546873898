import { useState } from "react"

import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import vi from "date-fns/locale/vi"
registerLocale("vi", vi)

function DateRange() {
    const [startDate, setStartDate] = useState(new Date("2014/02/08"))
    const [endDate, setEndDate] = useState(new Date("2014/02/10"))
    return (
        <>
            <DatePicker
                locale="vi"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
            />
            <DatePicker
                locale="vi"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
            />
        </>
    );
}

export default DateRange;
