import { LoadingSpinner } from "../../../components/Loading"
import classNames from "classnames/bind"
import styles from "../Mobile.module.scss"
import ListProducts from "./ListProducts";
const cx = classNames.bind(styles);

function PageProduct({ loading, dataEstates, dataBooking }) {
    
    return (
      <div className={cx("wrapper_dash")}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <ListProducts data={dataEstates} dataBooking={dataBooking} />
        )}
      </div>
    )
}

export default PageProduct