function handleLogin(data, navigate) {
    if (data) {
        
        // Lưu vào localStorage và sessionStorage
        if (typeof Storage !== "undefined") {
          // Khởi tạo localStorage, sessionStorage
          /**
           * localStorage : lưu trữ dữ liệu không có thời hạn
           * sessionStorage : Lưu trữ dữ liệu khi phiên làm việc kết thúc (tức khi tắt trình duyệt)
           * */
          const token = data.accessToken
          const phone = data.datauser.sodienthoai
          // localStorage.setItem("sodienthoai", ""+data.datauser.sodienthoai+"")
          // localStorage.setItem("token", "" + token + "")
          const objectUser = { p: phone, t: token }
          localStorage.setItem("object", JSON.stringify(objectUser))
          // localStorage.setItem("password", "" + data[0].matkhau + "")
          // Chuyển trang sau khi đăng nhập thành công.
              window.location.href = "/dashboard" // Cách 1
            // navigate("/profile?users=1") // cách 2
        } else {
          alert("Trình duyệt của bạn không hỗ trợ Storage")
        }
    } else {
        alert("Tài khoản không tồn tại")
    }
}

export {handleLogin}