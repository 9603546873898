import { useEffect, useState } from "react"
import SearchSimple from "../../../components/Search/SearchSimple"

function SearchUserLists({ result }) {
  const [valueInput, setValueInput] = useState("")

  const handleGetValue = (inputText) => {
    setValueInput(inputText)
  }
  // handle Search data.
  useEffect(() => {
    // Xử lý tìm kiếm
    if (valueInput) {
      const dataSearch = async () => {
        const response = await fetch(
          process.env.REACT_APP_APIGetDataSearchUser + `?keyword=${valueInput}`,
        )
        const dataRes = await response.json()
        result(dataRes)
      }
      dataSearch()
    }
  }, [valueInput])

  return <SearchSimple result={handleGetValue} />
}

export default SearchUserLists
