import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"
import moment from "moment";
import {
  HiOutlineChartBarSquare,
  HiOutlineEye,
  HiOutlineCursorArrowRays,
} from "react-icons/hi2"


function ListStatistical({data}) {

    return (
      <div className="card-body" id="listStatistical" style={{height: "calc(100vh - 12rem)"}}>
        <div className="table-responsive border rounded-4">
          <table className="table text-nowrap">
            <thead>
              <tr>
                <th scope="col" className="fw-4">
                  Sản phẩm
                </th>
                <th scope="col" className="fw-4">
                  Thời gian
                </th>
                <th scope="col" className="fw-4">
                  Hoàn thành
                </th>
                <th scope="col" className="fw-4">
                  Lượt xem
                </th>
                <th scope="col" className="fw-4">
                  Chuyển đổi
                </th>
                <th scope="col" className="fw-4">
                  Chi phí
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => {
                  // thời gian quảng cáo.
                  const startTime = moment(item.starttime)
                  const endTime = moment(item.endtime)
                  const currentTime = moment();
                  //   Set month.
                  const months_ = endTime.diff(startTime, "months")
                    // set ratio.
                  const diffDayCurrent = endTime.diff(currentTime, "days")
                  const diffDayTotal = endTime.diff(startTime, "days")
                  const ratioDays = ((diffDayCurrent / diffDayTotal) * 100).toFixed(1)
                  return (
                    <tr key={item.id}>
                      <Tippy content={item.title}>
                        <th scope="row">
                          <div className="fw-4 fs-14 titleStatistical">
                            {item.title}
                          </div>
                          <div className="fs-12 text-muted fw-4">
                            02/01/2024 17:11 - 02/03/2024 17:12
                          </div>
                        </th>
                      </Tippy>

                      <td>{months_} tháng</td>
                      <td>
                              <HiOutlineChartBarSquare /> {ratioDays}%
                      </td>
                      <td>
                        <HiOutlineEye /> {item.countview}
                      </td>
                      <td>
                        <HiOutlineCursorArrowRays className="fs-16" /> {item.countclick}
                      </td>
                      <td>
                        <span className="badge bg-outline-primary fs-12">
                          {item.admoney.toLocaleString().replace(/[.]/g, ",")}
                        </span>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
}

export default (ListStatistical)