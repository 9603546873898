import { useState } from "react"
import Statistical from "./Component/Statistical"
import classNames from "classnames/bind"
import styles from "./Statistical.module.scss"
import SearchSimple from "../../../components/Search/SearchSimple"
import StatisticalMain from "./Component/StatisticalMain"
import StatisticalSearch from "./Component/StatisticalSearch"

const cx = classNames.bind(styles)
function StatisticalPage({ className }) {
  const [value, setValue] = useState("")
  const [active, setActive] = useState(true)

  const handleValueSearch = (value) => {
    if (value) {
      setValue(value)
      setActive(false)
    } else {
      setActive(true)
    }
  }

  return (
    <div className={className}>
      <div className={cx("col-12")}>
        <Statistical />
      </div>
      <div className={cx("col-12")}>
        <div className={cx("col-12 d-flex justify-content-end")}>
          <div className={cx("col-xl-6 col-md-12 mb-2")}>
            <SearchSimple result={handleValueSearch} />
          </div>
        </div>
        {active ? <StatisticalMain /> : ""}
        {!active ? <StatisticalSearch keyword={value} /> : ""}
      </div>
    </div>
  )
}

export default StatisticalPage
