import React, { useEffect, useState } from "react"
import moment from "moment"
import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import vi from "date-fns/locale/vi"
import classNames from "classnames/bind"
import styles from "./Date.module.scss"
import "./Date.scss"

registerLocale("vi", vi)
const cx = classNames.bind(styles)


function DateWeek({resultWeek}) {
    const [startDate, setStartDate] = useState(new Date())
    
    const handleDateWeek = (date) => {
        setStartDate(date)
        // Mảng các ngày trong tuần đã chọn
        const arrWeeks = getWeekDays(date)
        // Trả về 1 mảng components khác sử dụng.
        resultWeek(arrWeeks)
    }

    // Tìm mảng 7 ngày trong tuần.
    const getWeekDays = (startDate) => {
      const weekDays = []
      const currentDay = new Date(startDate)

      for (let i = 0; i < 7; i++) {
        weekDays.push(moment(currentDay).format("YYYY-MM-DD"))
        currentDay.setDate(currentDay.getDate() + 1)
      }
      resultWeek(weekDays)

      return weekDays
    }
    
    // Lần đẩu trả về giá trị mặc định
    useEffect(() => {
        getWeekDays(startDate)
    }, [])

    return (
        <DatePicker
            locale="vi"
            selected={startDate}
            onChange={handleDateWeek}
            dateFormat="I/R"
            showWeekNumbers
            isClearable // nut clear
            showWeekPicker
            placeholderText="Chọn tuần"
            className={cx("dateInput", "fontType")}
        />
    );
}

export default DateWeek
