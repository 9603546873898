import { useEffect, useState, useRef } from "react"
import classNames from "classnames/bind"
import { HiOutlineMagnifyingGlass } from "react-icons/hi2"

import styles from "./SearchHeader.module.scss"
const cx = classNames.bind(styles)

function SearchSimpleNoborder({ result, style }) {
  const [value, setValue] = useState("")
  const valueText = useRef("")
  const valueRef = useRef("")
  const valueClick = useRef("")
  const handleResult = (e) => {
    setValue(e.target.value)
    valueText.current = e.target.value
  }

  const handleGetvalue = (e) => {
    if (e.key === "Enter") {
      result(valueText.current)
    }
  }

  const handleGetClick = () => {
    result(valueText.current)
  }

  useEffect(() => {
    valueRef.current.addEventListener("keydown", handleGetvalue)
    valueClick.current.addEventListener("click", handleGetClick)

    return () => {
      if (valueRef.current !== null) {
        valueRef.current.removeEventListener("keydown", handleGetvalue)
      }
      if (valueClick.current !== null) {
        valueClick.current.removeEventListener("click", handleGetClick)
      }
    }
  }, [value])

  return (
    <div className={cx("d-flex align-items-center", "searchData")}>
      <input
        type="text"
        value={value}
        placeholder="Tìm kiếm..."
        className={cx("searchSimple-2")}
        onChange={handleResult}
        ref={valueRef}
      />
      <button className={cx("searchIcon-2")} ref={valueClick}>
        <HiOutlineMagnifyingGlass />
      </button>
    </div>
  )
}

export default SearchSimpleNoborder
