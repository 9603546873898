import { useForm } from "react-hook-form"
import { handleEditPassword } from "../../../handle-reuses/user/Method"

function FormEditPassword({ idUser }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm()

  const onSubmit = (data) => {
    data.id = idUser
    handleEditPassword(data, reset)
  }
  return (
    <div className="p-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gy-3 pb-3">
          <div className="col-12">
            <label className="form-label mb-1">Mật khẩu mới</label>

            <input
              {...register("matkhau", { required: true })}
              className="form-control"
              placeholder="Nhập mật khẩu mới..."
            />
            {errors.matkhau && (
              <span className="text-danger fs-9">
                *Bạn chưa đặt mật khẩu mới !
              </span>
            )}
          </div>
        </div>
        <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-end">
          <button className="btn btn-primary-light m-1" type="submit">
            Lưu lại<i className="bi bi-plus-lg ms-2"></i>
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormEditPassword
