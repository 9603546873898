import Tippy from "@tippyjs/react"
import { Helmet } from "react-helmet"
import { Link, useParams } from "react-router-dom"
import io from "socket.io-client"
import { useState, useEffect, useRef, useLayoutEffect, useContext } from "react"
import classNames from "classnames/bind"
import styles from "./PageSearch.module.scss"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify"

import { BsDot } from "react-icons/bs"
import { FaHeart } from "react-icons/fa6"
import { SlHeart } from "react-icons/sl"

import { HeaderHome2 } from "../../../components/Header"
import {
  deleteAccentedText,
  handleFormatPrice,
} from "../../../handle-reuses/reuses"
import Footer from "../../../components/Footer"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import {
  handleMethodCreateData,
  handleMethodEditData,
} from "../../../handle-reuses/reuses/method"

const cx = classNames.bind(styles)

function PageSearchPC() {
  const { dataUser } = useContext(AppContext)
  const { slug } = useParams()
  const socket = useRef()

  // Khởi tạo socket
  useLayoutEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
  }, [])

  // Get data
  const [dataCate, setDataCate] = useState([])
  const [dataEstate, setDataEstate] = useState([])
  const [loading, setLoading] = useState(true)
  useLayoutEffect(() => {
    socket.current.emit("client-get-seo-cate", slug)
  }, [])
  // quản lý data
  useLayoutEffect(() => {
    socket.current.on("server-get-seo-cate", (data) => {
      setDataCate(data)
      setLoading(false)
      // Nếu không tồn tại trang, chuyển về trang chủ
      if (data.length === 0) {
        window.location.href = "/"
      }
    })
    socket.current.on("server-data-estate", (data) => {
      setDataEstate(data)
    })
  }, [])

  // Xử  lý add view.
  const handleAddView = (id_, countview) => {
    const params = {
      countview: Number(countview) + 1,
      id: id_,
    }
    socket.current.emit("client-click-views", params)
  }

  // Hiển thị simple saved.
  const [dataSaved, setDataSaved] = useState([])
  // Xác định user đã lưu sản phẩm hay chưa.
  const handleUpdateDataSaved = () => {
    socket.current.emit("client-saved", dataUser[0].id)
    socket.current.on("server-saved", (dataSimpleSaved) => {
      setDataSaved(dataSimpleSaved)
    })
  }
  useEffect(() => {
    if (dataUser.length > 0) {
      handleUpdateDataSaved()
    }
  }, [])
  // Xử lý simple saved.
  const simpleSavedId = useRef(0)
  const handleSimpleSaved = (simpleId) => {
    if (dataUser.length > 0) {
      const params = {
        simpleid: simpleId,
        userid: dataUser[0].id,
      }
      //   get simple saved.
      socket.current.emit("client-simple-saved", params)
      simpleSavedId.current = simpleId
    } else {
      alert("Bạn cần đăng nhập để lưu tin.")
    }
  }
  useEffect(() => {
    // Xử lý thêm mới hoặc sửa simple saved.
    socket.current.on("server-simple-saved", (dataSaved_) => {
      handleCreateEditSaved(
        dataSaved_,
        simpleSavedId.current,
        dataUser,
        handleUpdateDataSaved,
      )
    })
    return () => {
      socket.current.off("server-simple-saved")
    }
  }, [])
  // count view khi bấm chuột phải.
  const handleCountview = (simpleId, countViewOld) => {
    // Tăng view khi click.
    const params = {
      id: simpleId,
      countview: Number(countViewOld) + 1,
    }
    socket.current.emit("client-click-views", params)
  }

  if (dataCate.length === 0 && loading) {
    return //<PageError />
  }

  return (
    <>
      <Helmet>
        <title>{dataCate[0].title}</title>
        <meta name="description" content={dataCate[0].description} />
        <meta name="keywords" content={dataCate[0].keywords} />
      </Helmet>

      {/* Header */}
      <HeaderHome2 />
      <div className={cx("container", "row")}>
        <div className={cx("wrapper_left", "col-xl-2 col-md-12")}></div>
        <div className={cx("wrapper_main", "col-xl-8 col-md-12")}>
          <h1 className={cx("fs-23 text-center")}>{dataCate[0].title}</h1>
          <p className="text-center">
            Hiện có <span className="fs-15 fw-6">{dataEstate.length}</span> bất
            động sản tại trang này
          </p>
          <div className="mb-3 border-top border-block-start-dashed"></div>
          <div>
            {dataEstate.map((item) => {
              // Url
              const formatCateUrl = deleteAccentedText(item.cate).toLowerCase()
              const cate = formatCateUrl.replace(/[ ]/g, "-")
              const formatAddress = deleteAccentedText(
                item.address,
              ).toLowerCase()
              const address = formatAddress.replace(/[ ]/g, "-")

              // List images
              let listImages = []
              if (item.images !== "" || item.images !== null) {
                listImages = item.images.split(",")
              }
              // simple saved.
              let filterSaved = dataSaved
              if (filterSaved.length > 0) {
                filterSaved = filterSaved.filter((item_) => {
                  return item_.simpleid === item.id
                })
              }
              return (
                <div
                  className={cx("mt-3", "d-flex", "wrapper_item")}
                  key={item.id}
                  onMouseEnter={() => handleCountview(item.id, item.countview)}
                >
                  <div className={cx("me-3", "col-3", "position-relative")}>
                    <Tippy content="Lưu lại" className="fs-12">
                      <button
                        className={cx(
                          "border-0 bg-transparent position-absolute",
                        )}
                        style={{ top: "3px", right: "9px" }}
                        onClick={() => handleSimpleSaved(item.id)}
                      >
                        {filterSaved.length > 0 ? (
                          filterSaved[0].status === 1 ? (
                            <span style={{ color: "#ff6174" }}>
                              <FaHeart className="fs-21" />
                            </span>
                          ) : (
                            <SlHeart className="fs-21" />
                          )
                        ) : (
                          <SlHeart className="fs-21" />
                        )}
                      </button>
                    </Tippy>
                    <img src={listImages[0]} alt="" width={200} height={150} />
                  </div>
                  <div className={cx("col-8")}>
                    <Link
                      to={
                        "/" +
                        cate +
                        "-" +
                        address +
                        "/" +
                        item.alias +
                        "-pr" +
                        item.id
                      }
                      onClick={() => handleAddView(item.id, item.countview)}
                    >
                      <h3 className={cx("fs-18", "mt-2")}>{item.title}</h3>
                      <p className={cx("mb-1", "fs-15")}>
                        {item.cate}
                        <BsDot />
                        {item.address}
                        <BsDot />
                        {item.districts}
                        <BsDot />
                        {item.city}
                      </p>
                      <p className={cx("fs-15 fw-6 mb-1")}>
                        {handleFormatPrice(item.price)}
                      </p>
                      <p className="text-muted">
                        Được cập nhật{" "}
                        {moment(item.update_time).locale("vi").fromNow()}
                      </p>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={cx("wrapper_right", "col-xl-2 col-md-12")}></div>
      </div>
      <ToastContainer />
      <Footer />
    </>
  )
}

export default PageSearchPC

// Xử lý create edit saved
function handleCreateEditSaved(
  dataSaved_,
  simpleId,
  dataUser,
  handleUpdateDataSaved,
) {
  if (dataSaved_.length === 0) {
    // thêm mới
    const object = {
      simpleid: simpleId,
      status: 1,
      author: dataUser[0].id,
    }
    handleMethodCreateData(
      object,
      () => {
        alert("Bạn đã lưu thành công. Vào bảng điều khiển để xem")
        handleUpdateDataSaved()
      },
      process.env.REACT_APP_APICreateMarketSimpleSaved,
    )
  } else {
    // Hủy lưu
    let object, alertSeved
    if (dataSaved_[0].status === 0) {
      object = {
        id: dataSaved_[0].id,
        status: 1,
      }
      alertSeved = "Bạn đã LƯU thành công."
      handleMethodEditData(
        object,
        () => {
          toast.success("Lưu thành công", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            progress: undefined,
            theme: "light",
          })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    } else {
      object = {
        id: dataSaved_[0].id,
        status: 0,
      }
      alertSeved = "Bạn đã HỦY thành công."
      handleMethodEditData(
        object,
        () => {
          toast.error("Đã hủy", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            progress: undefined,
            theme: "light",
          })
          handleUpdateDataSaved()
        },
        process.env.REACT_APP_APIEditStatusMarketSimpleSaved,
      )
    }
  }
}
