import { useState } from "react"
import classNames from "classnames/bind"
import { FiSearch } from "react-icons/fi"
import { MdCancel } from "react-icons/md"

import styles from "./SearchHeader.module.scss"
const cx = classNames.bind(styles)

function SearchHorizontal({placeholder, className, result}) {
  const [valueText, setValueText] = useState("")

  const handleInputText = (e) => {
      setValueText(e.target.value)
      result(e.target.value)
  }
  // Xóa tìm kiếm.
  const handleClearInputText = () => {
    setValueText("")
  }

  return (
    <div
      className={cx(
        "Search_Wrapper_horizontal",
        "d-flex justify-content-center align-items-center",
        className
      )}
    >
      <div className={cx("SearchIcon")}>
        <FiSearch className="fs-15"/>
      </div>
      <input
        type="text"
        placeholder={placeholder ? placeholder : "Tìm kiếm..."}
        className={cx("col-12", "Search_Input")}
        value={valueText}
        onChange={handleInputText}
      />
      <div className={cx("Search_ClearText")}>
        {valueText !== "" ? <MdCancel onClick={handleClearInputText} className="fs-21"/> : ""}
      </div>
    </div>
  )
}

export default SearchHorizontal
