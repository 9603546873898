import { useForm, Controller, useWatch } from "react-hook-form"
import classNames from "classnames/bind"
import ReactQuill from "react-quill"
import { useEffect, useRef, useState, useContext } from "react"
import Sortable from "sortablejs"
import io from "socket.io-client"

import { IoImages } from "react-icons/io5"
import { MdOutlineCancel } from "react-icons/md"
import { IoChatboxEllipsesOutline } from "react-icons/io5"

import { handleFormatPriceInput } from "../../handle-reuses/reuses"
import styles from "./FormRealEstate.module.scss"
import {
  handleAddDataMarketSimple,
  handleSuggestStreetEstate,
} from "../../handle-reuses/Market-simple/Method/Market-simple"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import { handleNLP } from "../../handle-reuses/reuses/suptech/nlp"

const cx = classNames.bind(styles)
const infoOther = {
  info: `
  <p>Thông tin chính</p>
  <ul>
  <li>Đường ngõ: </li>
  <li>Diện tích MB: </li>
  <li>Mặt tiền: </li>
  <li>Số tầng: </li>
  <li>Hướng nhà/Ban công: </li>
  </ul>`,
}

const optiondonvi = [
  { id: 1, donvi: "Đêm" },
  { id: 2, donvi: "Ngày" },
  { id: 3, donvi: "Tháng" },
  { id: 4, donvi: "Năm" },
  { id: 5, donvi: "Thỏa thuận" },
]

function FormRealEstateFast() {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm()
  const [files, setFiles] = useState([])
  const [objectFile, setObjectFile] = useState()
  const [formatPrice, setFormatPrice] = useState(0)
  const [electricity, setElectricity] = useState(0)
  const [water, setWater] = useState(0)
  const [serviceOther, setServiceOther] = useState(0)
  const [deposit, setDeposit] = useState(0)
  const { dataUser, dataBalance } = useContext(AppContext)
  const socket = useRef()

  // khởi tạo socket.
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
  }, [])
  // suggest cate.
  const [optionCate, setOptionCate] = useState([])
  const [optionType, setOptionType] = useState([])
  useEffect(() => {
    // Suggest cate
    socket.current.on("server-data-cate", (data) => {
      setOptionCate(data)
    })
    // Suggest type.
    socket.current.on("server-data-type", (data) => {
      setOptionType(data)
    })
  }, [])

  // format price.
  const handleFormatPrice = (e) => {
    const formatPrice = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setFormatPrice(FP)
    }
  }
  // format giá điện
  const handleFormatElectricity = (e) => {
    const formatPrice = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setElectricity(FP)
    }
  }
  // Formta giá nước
  const handleFormatWater = (e) => {
    const formatPrice = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setWater(FP)
    }
  }
  // format giá dịch vụ
  const handleFormatServiceOther = (e) => {
    const formatPrice = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setServiceOther(FP)
    }
  }
  // format tiền đặt cọc
  const handleFormatDeposit = (e) => {
    const formatPrice = e.target.value.replace(/[,. ]/g, "")
    if (isNaN(formatPrice)) {
      alert("Bạn cần nhập dạng số.")
    } else {
      const FP = handleFormatPriceInput(e.target.value)
      setDeposit(FP)
    }
  }

  // LInk đên thẻ input khi click button.
  const linkRef = useRef()
  const handleAddImages = () => {
    linkRef.current.click()
  }
  // Xử lý hiển thị xem trước hình ảnh
  const handleFileImages = (e) => {
    const file = e.target.files
    if (file.length > 0) {
      const newFile = Array.from(file).map((file_) => {
        return URL.createObjectURL(file_)
      })
      setFiles((fileOld) => [...fileOld, ...newFile])
      setObjectFile(file)

      new Sortable(document.querySelector(".imageContainer"), {
        animation: 250, // Thời gian chuyển động khi sắp xếp (milliseconds)
        // Các tùy chọn khác...
      })
    }
  }

  // Delete images.
  const handleDeleteImages = (index) => {
    const updateImage = [...files]
    URL.revokeObjectURL(updateImage[index])
    updateImage.splice(index, 1)
    setFiles(updateImage)
  }

  // Xử lý mô tả bằng AI.
  const requestValue = useWatch({
    control,
    name: "title",
  })
  const [textInput, setTextInput] = useState("")
  const [textOut, setTextOut] = useState("")
  const indexRef = useRef(0)
  // get discription
  const handleContentAI = () => {
    // Đầu vào phải khác rỗng
    if (requestValue) {
      // Ý định người dùng
      const intent = handleNLP(requestValue)

      const fetchData = async () => {
        const response = await fetch(
          process.env.REACT_APP_APIGetMarketContentAi +
            `?keyword=${await intent}`,
        )
        const dataRes = await response.json()
        if (dataRes.length > 0) {
          const indexRandom = Math.floor(Math.random() * dataRes.length)
          const result = dataRes[indexRandom]
          const text = `<span>${result.content_location}</span><p><br></p> <p>${result.content_advantage}</p></br> ${infoOther.info} <br> <p>${result.content_contact}</p>`
          setTextInput(text)
        } else {
          const text = `Tôi rất xin lỗi vì chưa thể đáp yêu cầu của bạn vào lúc này, nhưng tôi hứa sẽ bổ sung dữ liệu trong tương lai.`
          // setTextInput(text)
          alert(text)
        }
      }
      // Không có người dùng click khi content AI đang chạy.
      if (indexRef.current === 0) {
        fetchData()
      }
    } else {
      alert("Bạn chưa có tiêu đề cho sản phẩm của mình.")
    }
  }
  // Tạo hiệu ứng
  const typeWriter = () => {
    const textOut = textInput.slice(0, indexRef.current)
    setTextOut(textOut)
    indexRef.current = indexRef.current + 1

    if (indexRef.current <= textInput.length) {
      document
        .getElementById("submitProduct")
        .setAttribute("style", "display:none")
      setTimeout(typeWriter, 10)
    } else {
      // Reset về ban đầu.
      indexRef.current = 0
      document
        .getElementById("submitProduct")
        .setAttribute("style", "display:block")
    }
  }
  // Quản lý hiệu ứng
  useEffect(() => {
    if (textInput.length > 0) {
      typeWriter()
    }
  }, [textInput])

  // Thông tin dịch vụ.
  const [serviceInfo, setServiceInfo] = useState("none")
  const handleEventCate = (e) => {
    const checkCate = e.target.value
    if (
      checkCate === "Cho thuê nhà" ||
      checkCate === "Cho thuê căn hộ" ||
      checkCate === "Cho thuê chung cư" ||
      checkCate === "Cho thuê mặt bằng"
    ) {
      setServiceInfo("block")
    } else {
      setServiceInfo("none")
    }
  }

  // Quản lý đơn vị.
  const [disPrice, setDisPrice] = useState(false)
  const handleDonviPrice = (e) => {
    const dvPr = e.target.value
    if (dvPr === "Thỏa thuận") {
      setFormatPrice(0)
      setDisPrice(true)
    } else {
      setDisPrice(false)
    }
  }

  // Gợi ý tuyến đường, quận huyện, thành phố.
  const [dataStreet, setDataStreet] = useState([])
  const [street, setStreet] = useState("")
  const hideRef = useRef()
  // Street
  const handleGetValueStreet = (e) => {
    const getData = async () => {
      setStreet(e.target.value)
      const arrStr = await handleSuggestStreetEstate(e.target.value)
      if (arrStr) {
        // Loại bỏ giá trị không tồn tại trong mảng.
        const filterArrStr = arrStr.filter((item) => {
          return item.address !== "0"
        })
        // Tạo một đối tượng dùng để theo dõi các giá trị duy nhất của thuộc tính
        const uniqueValues = []
        // Lọc mảng để chỉ giữ lại các phần tử có giá trị duy nhất của thuộc tính
        const uniqueArr = filterArrStr.filter((item) => {
          if (!uniqueValues[item.address]) {
            uniqueValues[item.address] = true
            return true
          }
        })
        setDataStreet(uniqueArr)
      }
    }
    getData()
  }
  const handleOptionStreet = (option) => {
    setStreet(option)
    setDataStreet([])
  }
  useEffect(() => {
    const handleHideSuggest = (e) => {
      if (hideRef.current && !hideRef.current.contains(e.target)) {
        setDataStreet([])
      }
    }
    window.addEventListener("click", handleHideSuggest)
    return () => {
      window.removeEventListener("click", handleHideSuggest)
    }
  }, [])

  // Districts.
  const [datadistricts, setDataDistricts] = useState([])
  const [districts, setDistricts] = useState("")
  const disRef = useRef()
  const handleGetValueDistricts = (e) => {
    const getData = async () => {
      setDistricts(e.target.value)
      const arrStr = await handleSuggestStreetEstate(e.target.value)
      if (arrStr) {
        // Loại bỏ giá trị không tồn tại trong mảng.
        const filterArrStr = arrStr.filter((item) => {
          return item.districts !== "0"
        })
        // Tạo một đối tượng dùng để theo dõi các giá trị duy nhất của thuộc tính
        const uniqueValues = []
        // Lọc mảng để chỉ giữ lại các phần tử có giá trị duy nhất của thuộc tính
        const uniqueArr = filterArrStr.filter((item) => {
          if (!uniqueValues[item.districts]) {
            uniqueValues[item.districts] = true
            return true
          }
        })
        setDataDistricts(uniqueArr)
      }
    }
    getData()
  }
  const handleOptionDistricts = (option) => {
    setDistricts(option)
    setDataDistricts([])
  }
  useEffect(() => {
    const handleHideSuggest = (e) => {
      if (disRef.current && !disRef.current.contains(e.target)) {
        setDataDistricts([])
      }
    }
    if (datadistricts.length > 0) {
      window.addEventListener("click", handleHideSuggest)
    }
    return () => {
      window.removeEventListener("click", handleHideSuggest)
    }
  }, [])

  // Thành phố.
  const [dataCity, setDataCity] = useState([])
  const [city, setCity] = useState("")
  const cityRef = useRef()
  // Street
  const handleGetValueCity = (e) => {
    const getData = async () => {
      setCity(e.target.value)
      const arrStr = await handleSuggestStreetEstate(e.target.value)
      if (arrStr) {
        // Loại bỏ giá trị không tồn tại trong mảng.
        const filterArrStr = arrStr.filter((item) => {
          return item.city !== "0"
        })
        // Tạo một đối tượng dùng để theo dõi các giá trị duy nhất của thuộc tính
        const uniqueValues = []
        // Lọc mảng để chỉ giữ lại các phần tử có giá trị duy nhất của thuộc tính
        const uniqueArr = filterArrStr.filter((item) => {
          if (!uniqueValues[item.city]) {
            uniqueValues[item.city] = true
            return true
          }
        })
        setDataCity(uniqueArr)
      }
    }
    getData()
  }
  const handleOptionCity = (option) => {
    setCity(option)
    setDataCity([])
  }
  useEffect(() => {
    const handleHideSuggest = (e) => {
      if (cityRef.current && !cityRef.current.contains(e.target)) {
        setDataCity([])
      }
    }
    window.addEventListener("click", handleHideSuggest)
    return () => {
      window.removeEventListener("click", handleHideSuggest)
    }
  }, [])

  // Nút submit
  const onSubmit = (data) => {
    delete data.listImages
    data.short_keywords = data.cate + " " + data.address
    handleAddDataMarketSimple(data, objectFile, reset, dataUser, dataBalance)

    // Xóa file khỏi bộ nhớ nếu submit xong.
    URL.revokeObjectURL(files)
    // Xóa list images
    setFiles([])
    // xóa trường format price.
    setFormatPrice("")
    // Xóa nội dung.
    setTextOut("")
  }

  return (
    <div className="p-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gy-3 pb-3">
          <div className="col-xl-12 col-md-12 position-relative">
            <label className="form-label mb-1 text-muted">
              Thành phố <sup className="text-danger">*</sup>
            </label>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <>
                  <input
                    value={city}
                    className="form-control"
                    placeholder="Tên đường hoặc tên dự án"
                    onChange={(e) => {
                      onChange(e)
                      handleGetValueCity(e)
                    }}
                  />
                  {errors.city && (
                    <span className={cx("text-danger", "fs-12")}>
                      *Bạn cần chọn thành phố.
                    </span>
                  )}
                  <div
                    className={cx(
                      "suggestList",
                      dataCity.length > 0 ? "active" : "",
                    )}
                    ref={cityRef}
                  >
                    <ul>
                      {dataCity.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            onChange(item.city)
                            handleOptionCity(item.city)
                          }}
                        >
                          {item.city}
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            />
            {/* <select
              {...register("city", { required: true })}
              className="form-control"
            >
              <option value="">Chọn thành phố</option>
              {optioncity.map((option) => {
                return (
                  <option key={option.id} value={option.city}>
                    {option.city}
                  </option>
                )
              })}
            </select>
            {errors.cate && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa chọn thành phố
              </span>
            )} */}
          </div>
          <div className="col-xl-6 col-md-12 position-relative">
            <label className="form-label mb-1 text-muted">
              Tên đường/Dự án <sup className="text-danger">*</sup>
            </label>

            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <>
                  <input
                    value={street}
                    className="form-control"
                    placeholder="Tên đường hoặc tên dự án"
                    onChange={(e) => {
                      onChange(e)
                      handleGetValueStreet(e)
                    }}
                  />
                  {errors.address && (
                    <span className={cx("text-danger", "fs-12")}>
                      *Bạn cần điền tên đường hoặc tên dự án
                    </span>
                  )}
                  <div
                    className={cx(
                      "suggestList",
                      dataStreet.length > 0 ? "active" : "",
                    )}
                    ref={hideRef}
                  >
                    <ul>
                      {dataStreet.length > 0 &&
                        dataStreet.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              onChange(item.address)
                              handleOptionStreet(item.address)
                            }}
                          >
                            {item.address}
                          </li>
                        ))}
                    </ul>
                  </div>
                </>
              )}
            />
          </div>
          <div className="col-xl-6 col-md-12 position-relative">
            <label className="form-label mb-1 text-muted">
              Quận/Huyện <sup className="text-danger">*</sup>
            </label>
            <Controller
              name="districts"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <>
                  <input
                    value={districts}
                    className="form-control"
                    placeholder="Tên quận hoặc huyện"
                    onChange={(e) => {
                      onChange(e)
                      handleGetValueDistricts(e)
                    }}
                  />
                  {errors.districts && (
                    <span className={cx("text-danger", "fs-12")}>
                      *Bạn cần điền tên quận, huyện
                    </span>
                  )}
                  <div
                    className={cx(
                      "suggestList",
                      datadistricts.length > 0 ? "active" : "",
                    )}
                    ref={disRef}
                  >
                    <ul>
                      {datadistricts.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              onChange(item.districts)
                              handleOptionDistricts(item.districts)
                            }}
                          >
                            {item.districts}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </>
              )}
            />
          </div>
          <div className="col-xl-6 col-md-12">
            <label className="form-label mb-1 text-muted">
              Danh mục <sup className="text-danger">*</sup>
            </label>
            <select
              {...register("cate", { required: true })}
              className="form-control"
              onChange={handleEventCate}
            >
              <option value="">Chọn danh mục</option>
              {optionCate.map((option) => {
                return (
                  <option key={option.id} value={option.title}>
                    {option.title}
                  </option>
                )
              })}
            </select>
            {errors.cate && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa chọn danh mục
              </span>
            )}
            <div className={cx("suggestCity")} id="suggestCate"></div>
          </div>
          <div className="col-xl-6 col-md-12">
            <label className="form-label mb-1 text-muted">
              Hình thức <sup className="text-danger">*</sup>
            </label>
            <select
              {...register("form_cate", { required: true })}
              className="form-control"
            >
              <option value="">Chọn hình thức</option>
              {optionType.map((option) => {
                return (
                  <option key={option.id} value={option.title}>
                    {option.title}
                  </option>
                )
              })}
            </select>
            {errors.form_cate && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa chọn hình thức
              </span>
            )}
            <div className={cx("suggestCity")} id="suggestFormCate"></div>
          </div>
          <div className="col-xl-12">
            <label className="form-label mb-1 text-muted">
              Tiêu đề <sup className="text-danger">*</sup>
            </label>
            <input
              {...register("title", { required: true })}
              className="form-control"
              placeholder="Cần cho thuê nhà đường trần nguyên hãn, lê chân, hải phòng"
            />
            {errors.title && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn cần tạo tiêu đề
              </span>
            )}
            <div className={cx("suggestCity")} id="suggestCity"></div>
          </div>
          {/* Mô tả */}
          <div className="col-xl-12">
            <label
              htmlFor="discription"
              className="form-label mb-1 d-flex justify-content-between"
            >
              <span className="text-muted">
                Mô tả<sup className="text-danger">*</sup>
              </span>
              <button
                className="border-0 rounded-2 p-1 px-3"
                type="button"
                onClick={handleContentAI}
              >
                Mô tả bằng AI <IoChatboxEllipsesOutline className="ms-1" />
              </button>
            </label>
            <Controller
              name="discription"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <ReactQuill
                    theme="snow"
                    value={textOut}
                    onChange={(value) => {
                      setTextOut(value)
                      field.onChange(value)
                    }}
                    // style={{maxHeight: "400px", overflowY: "auto"}}
                  />
                  {errors.discription && (
                    <span className={cx("text-danger", "fs-12")}>
                      Bạn chưa có mô tả cho sản phẩm.
                    </span>
                  )}
                </>
              )}
            />
          </div>
          {/* các trường nhập thông tin sản phẩm */}
          <div className="col-xl-4 col-md-12">
            <label className="form-label mb-1 text-muted">
              Diện tích mặt bằng<sup className="text-danger">*</sup>
            </label>
            <input
              {...register("dtmb", { required: true })}
              className="form-control"
              placeholder="Ví dụ: 68"
            />
            {errors.dtmb && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa diện tích
              </span>
            )}
          </div>
          <div className="col-xl-4 col-md-12">
            <label className="form-label mb-1 text-muted">
              Mặt tiền<sup className="text-danger">*</sup>
            </label>
            <input
              {...register("mattien", { required: true })}
              className="form-control"
              placeholder="Ví dụ: 8"
            />
            {errors.mattien && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa mặt tiền
              </span>
            )}
          </div>
          <div className="col-xl-4 col-md-12">
            <label className="form-label mb-1 text-muted">
              Hướng<sup className="text-danger">*</sup>
            </label>
            <select
              {...register("direction", { required: true })}
              className="form-control"
            >
              <option value="">Chọn hướng</option>
              <option value="Đông">Đông</option>
              <option value="Tây">Tây</option>
              <option value="Nam">Nam</option>
              <option value="Bắc">Bắc</option>
              <option value="Đông nam">Đông nam</option>
              <option value="Đông bắc">Đông bắc</option>
              <option value="Tây nam">Tây nam</option>
              <option value="Tây bắc">Tây bắc</option>
              <option value="Liên hệ">Liên hệ</option>
            </select>
            {errors.direction && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa hướng sản phẩm
              </span>
            )}
          </div>

          {/* các trường khác */}
          <div className="col-xl-7 col-md-12">
            <label className="form-label mb-1 text-muted">
              Giá sản phẩm<sup className="text-danger">*</sup>
            </label>
            <input
              {...register("price", { required: true })}
              className="form-control"
              placeholder="Ví dụ: 200,000,000"
              value={formatPrice}
              onChange={handleFormatPrice}
              disabled={disPrice}
            />
            {errors.price && (
              <span className={cx("text-danger", "fs-12")}>
                *Bạn chưa nhập giá
              </span>
            )}
          </div>
          <div className="col-xl-5 col-md-12">
            <label className="form-label mb-1 text-muted">Đơn vị</label>
            <select
              {...register("donvi")}
              className="form-control"
              onChange={handleDonviPrice}
            >
              <option value="">Chọn đơn vị</option>
              {optiondonvi.map((option) => {
                return (
                  <option key={option.id} value={option.donvi}>
                    {option.donvi}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="col-xl-12">
            <div className={cx("border-1", "p-3 rounded-3")}>
              <div
                className={cx(
                  "d-flex justify-content-between align-items-center",
                )}
              >
                <span>Thêm ảnh vào bài viết</span>
                <div className="position-relative">
                  <input
                    type="file"
                    {...register("listImages")}
                    multiple
                    className={cx("file-input")}
                    onChange={handleFileImages}
                    style={{ display: "none" }}
                    ref={linkRef}
                  />
                  <label
                    className={cx("option-button")}
                    onClick={handleAddImages}
                  >
                    <IoImages />
                  </label>
                </div>
              </div>
              <ul className="row mt-3 imageContainer">
                {files.map((value, index) => {
                  return (
                    <li key={index} className="col-6 listImage p-1">
                      <img
                        src={value}
                        height="200"
                        width="100%"
                        className={cx("rounded-3")}
                        alt=""
                      />
                      <a
                        href="#"
                        className={cx("deleteButtonImage")}
                        onClick={() => {
                          handleDeleteImages(index)
                        }}
                      >
                        <MdOutlineCancel className="fs-21" />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="col-xl-12">
            <label className="form-label mb-1 text-muted">Link view360</label>
            <textarea
              {...register("view360")}
              placeholder="Lấy link tại view360.vn"
              className="form-control"
              rows={6}
            />
          </div>
        </div>
        <div
          className="px-0 py-3 border-top border-block-start-dashed"
          style={{ display: serviceInfo }}
        >
          <div className="col-xl-12 col-md-12">
            <label className="form-label mb-1 fs-15">Thông tin dịch vụ</label>
          </div>

          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">Điều hòa</label>
            <div className="col-sm-6">
              <div className="input-group">
                <Controller
                  name="dieuhoa"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      {/* Checkbox 1 */}
                      <label className="form-check-label me-3">
                        <input
                          type="checkbox"
                          value="1"
                          checked={field.value === "1"}
                          onChange={() => field.onChange("1")}
                          className="me-1 rounded-5 form-checked-dark"
                        />
                        Có
                      </label>

                      {/* Checkbox 2 */}
                      <label>
                        <input
                          type="checkbox"
                          value="0"
                          checked={field.value === "0"}
                          onChange={() => field.onChange("0")}
                          className="me-1"
                        />
                        Không
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">Bình nóng lạnh</label>
            <div className="col-sm-6">
              <div className="input-group">
                <Controller
                  name="binhnonglanh"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      {/* Checkbox 1 */}
                      <label className="form-check-label me-3">
                        <input
                          type="checkbox"
                          value="1"
                          checked={field.value === "1"}
                          onChange={() => field.onChange("1")}
                          className="me-1 rounded-5 form-checked-dark"
                        />
                        Có
                      </label>

                      {/* Checkbox 2 */}
                      <label>
                        <input
                          type="checkbox"
                          value="0"
                          checked={field.value === "0"}
                          onChange={() => field.onChange("0")}
                          className="me-1"
                        />
                        Không
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">Đơn giá tiền điện</label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("electricity_money")}
                  value={electricity}
                  onChange={handleFormatElectricity}
                  className="form-control"
                  placeholder="Nhập số tiền"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Số điện
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-6 col-form-label">Đơn giá tiền nước</label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("water_money")}
                  value={water}
                  onChange={handleFormatWater}
                  className="form-control"
                  placeholder="Nhập số tiền"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Khối
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-6 col-form-label">
              Đơn giá dịch vụ khác
            </label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("service_price")}
                  value={serviceOther}
                  onChange={handleFormatServiceOther}
                  className="form-control"
                  placeholder="Nhập số tiền"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Tháng
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">
              Thời gian ký hợp đồng
            </label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("contract_time")}
                  className="form-control"
                  placeholder="Nhập số tháng"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Tháng
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-6 col-form-label">Tiền đặt cọc</label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("deposit")}
                  value={deposit}
                  onChange={handleFormatDeposit}
                  className="form-control"
                  placeholder="Nhập số tiền"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  vnđ
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <label className="col-sm-6 col-form-label">
              Phương thức thanh toán
            </label>
            <div className="col-sm-6">
              <div className="input-group">
                <Controller
                  name="payment_methods"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      {/* Checkbox 1 */}
                      <label className="form-check-label me-3">
                        <input
                          type="checkbox"
                          value="1"
                          checked={field.value === "1"}
                          onChange={() => field.onChange("1")}
                          className="me-1 rounded-5 form-checked-dark"
                        />
                        Đêm
                      </label>

                      {/* Checkbox 1 */}
                      <label className="form-check-label me-3">
                        <input
                          type="checkbox"
                          value="2"
                          checked={field.value === "2"}
                          onChange={() => field.onChange("2")}
                          className="me-1 rounded-5 form-checked-dark"
                        />
                        Ngày
                      </label>

                      {/* Checkbox 2 */}
                      <label className="me-3">
                        <input
                          type="checkbox"
                          value="3"
                          checked={field.value === "3"}
                          onChange={() => field.onChange("3")}
                          className="me-1"
                        />
                        Quý
                      </label>

                      {/* Checkbox 3 */}
                      <label>
                        <input
                          type="checkbox"
                          value="4"
                          checked={field.value === "4"}
                          onChange={() => field.onChange("4")}
                          className="me-1"
                        />
                        Năm
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-sm-6 col-form-label">
              Thanh toán định kỳ
            </label>
            <div className="col-sm-6">
              <div className="input-group">
                <input
                  type="text"
                  {...register("periodic_payments")}
                  className="form-control"
                  placeholder="Nhập số tháng"
                />
                <div
                  className="input-group-text fs-11"
                  style={{ width: "35%" }}
                >
                  / Tháng
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button
            className="btn btn-primary-light m-1"
            type="submit"
            id="submitProduct"
          >
            Đăng tin<i className="bi bi-plus-lg ms-2"></i>
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormRealEstateFast

// các trường nhập thông tin sản phẩm.
function EstateInfo({ result }) {
  const {
    register,
    formState: { errors },
  } = useForm()

  return (
    <>
      <div className="col-xl-4 col-md-12">
        <label className="form-label mb-1">
          Diện tích mặt bằng<sup className="text-danger">*</sup>
        </label>
        <input
          type="number"
          {...register("dtmb", { required: true })}
          className="form-control"
          placeholder="Ví dụ: 68"
        />
        {errors.dtmb && (
          <span className={cx("text-danger", "fs-12")}>
            *Bạn chưa diện tích
          </span>
        )}
      </div>
      <div className="col-xl-4 col-md-12">
        <label className="form-label mb-1">
          Mặt tiền<sup className="text-danger">*</sup>
        </label>
        <input
          type="number"
          {...register("mattien", { required: true })}
          className="form-control"
          placeholder="Ví dụ: 8"
        />
        {errors.mattien && (
          <span className={cx("text-danger", "fs-12")}>*Bạn chưa mặt tiền</span>
        )}
      </div>
      <div className="col-xl-4 col-md-12">
        <label className="form-label mb-1">
          Hướng<sup className="text-danger">*</sup>
        </label>
        <select
          {...register("direction", { required: true })}
          className="form-control"
        >
          <option value="">Chọn hướng</option>
          <option value="Đông">Đông</option>
          <option value="Tây">Tây</option>
          <option value="Nam">Nam</option>
          <option value="Bắc">Bắc</option>
          <option value="Đông nam">Đông nam</option>
          <option value="Đông bắc">Đông bắc</option>
          <option value="Tây nam">Tây nam</option>
          <option value="Tây bắc">Tây bắc</option>
          <option value="Liên hệ">Liên hệ</option>
        </select>
        {errors.direction && (
          <span className={cx("text-danger", "fs-12")}>
            *Bạn chưa hướng sản phẩm
          </span>
        )}
      </div>
    </>
  )
}
