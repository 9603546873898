
import { useState, useEffect, useRef, memo } from "react"
import ListPay from "./ListPay"
import { LoadingSpinner } from "../../../components/Loading"

function ListPayment({status}) {
  const [data, setData] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const limit = 20
  const pageRef = useRef(1)
  const [loading, setLoading] = useState(true)

  // Get data
  const fetchData = async (page_) => {
    let apiPay =
      process.env.REACT_APP_APIGetUserPayHis +
      `?quantity=${limit}&offset=${(page_ - 1) * limit}`
    
    const response = await fetch(apiPay)
    const dataRes = await response.json()
    setData((dataOld) => [...dataOld, ...dataRes])
    setLoading(false)
    
    if (dataRes.length < limit) {
      setHasMore(false) // đã hết data
    }
  }
  // Hiển thị lần đầu.
  useEffect(() => {
    setTimeout(() => {
      fetchData(pageRef.current)
    }, 500);
  }, [])


  // Xử lý load more.
  const handleScroll = () => {
    const container = document.getElementById("contentPayMain")
    const totalHeight = container.scrollHeight
    const setHeight = container.offsetHeight
    const scrollY = container.scrollTop

    if (setHeight + scrollY + 1 >= totalHeight && hasMore) {
      setTimeout(() => {
        pageRef.current = pageRef.current + 1
        fetchData(pageRef.current)
      }, 500)
    }
  }

  useEffect(() => {
    const container = document.getElementById("contentPayMain")
    if (container) {
      container.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll)
      }
    }
  }, [pageRef.current])
  

  if (loading) {
    return <LoadingSpinner />
  }

    return (
      <ListPay data={data} hasMore={hasMore} id="contentPayMain" status={status}/>
    )
}

export default memo(ListPayment);