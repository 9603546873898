
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill"

/** Component này tái sử dụng được nó trả về 1 đối tượng của form*/
function FormSample({result, buttonText}) {
    const {register, control, handleSubmit, formState: {errors}, reset} = useForm()
    const onSubmit = (data) => {
        result(data, reset)
    }
    return (
      <div className="p-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row gy-3 pb-3">
            <div className="col-xl-12">
              <label className="form-label mb-1">Tiêu đề</label>
              <input
                {...register("title", { required: true })}
                className="form-control"
                placeholder="Ví dụ: Giải quyết giúp tôi vấn đề sau..."
              />
              {errors.title && (
                <span className={("text-danger", "fs-9")}>
                  *Bạn cần tạo tiêu đề
                </span>
              )}
            </div>
            <div className="col-xl-12">
              <label htmlFor="discription" className="form-label mb-1">
                Mô tả
              </label>
              <Controller
                name="discription"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <ReactQuill
                    theme="snow"
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-end">
            <button className="btn btn-primary-light m-1" type="submit">
              {buttonText}<i className="bi bi-plus-lg ms-2"></i>
            </button>
          </div>
        </form>
      </div>
    )
}

export default FormSample;