import { useContext } from "react"
import { HiOutlinePencilAlt } from "react-icons/hi"
import { Link } from "react-router-dom"

import AppContext from "../../handle-reuses/Contexts/CreateContexts"

function ButtonPost() {
  const { dataUser } = useContext(AppContext)

  // Nếu chưa đăng nhập hiển thị nút đăng nhập
  if (dataUser.length === 0) {
    return (
      <div>
        <Link to="/login" className="btn btn-outline-dark me-3">
          Đăng nhập
        </Link>
      </div>
    )
  }
  // Sau khi đăng nhập hiển thị chọn đăng tin
  return (
    <button
      className="d-flex mb-0 rounded-0 border-0 fs-15"
      style={{ backgroundColor: "inherit" }}
      data-bs-toggle="modal"
      data-bs-target="#postProductFast"
    >
      <HiOutlinePencilAlt className="me-2 fs-21" />
      Đăng tin
    </button>
    // <Dropdown>
    //   <Dropdown.Toggle
    //     variant="outline-dark rounded-5 border-0"
    //     id="buttonPostProduct"
    //     bsPrefix="custom-dropdown-toggle"
    //   >
    //     <div
    //       className="d-flex mb-0"
    //       data-bs-toggle="modal"
    //       data-bs-target="#postProductFast"
    //     >
    //       {/* <HiOutlineUserCircle className="fs-18 me-2" /> */}
    //       <HiOutlinePencilAlt className="me-2 fs-18" />
    //       Đăng tin
    //     </div>
    //   </Dropdown.Toggle>
    // {/* <Dropdown.Menu>
    //   <Dropdown.Item
    //     data-bs-toggle="modal"
    //     data-bs-target="#postProductFast"
    //     className="d-flex align-items-center"
    //   >
    //     <HiOutlinePencilAlt className="me-2 fs-18" />
    //     <span className="fs-14">Đơn giản</span>
    //   </Dropdown.Item>
    //   <Dropdown.Item
    //     data-bs-toggle="modal"
    //     data-bs-target="#postProductPro"
    //     className="d-flex align-items-center"
    //   >
    //     <HiOutlineClipboardDocumentList className="me-2 fs-18" />
    //     <span className="fs-14">Chuyên nghiệp</span>
    //   </Dropdown.Item>
    // </Dropdown.Menu> */}
    // </Dropdown>
  )
}

export default ButtonPost
