import moment from "moment"
import { useState } from "react"

import { LoadingText } from "../../../components/Loading"
import { handleMethodEditData } from "../../../handle-reuses/reuses/method"

function ListPay({ data, hasMore, id, status }) {
  const [dataPay, setDataPay] = useState(data)
  // Xử lý pending;
  const handleEventPending = (id_) => {
    const checkPending = window.confirm(
      "Bạn muốn xác nhận khoản thanh toán này?",
    )
    let valueInput
    if (checkPending) {
      valueInput = {
        id: id_,
        status: 1,
      }
      handleMethodEditData(
        valueInput,
        () => {
          alert("Khoản thanh toán được duyệt thành công.")
          const index = dataPay.findIndex((item) => item.id_his === Number(id_))
          const dataPayNew = [...dataPay]
          dataPayNew[index] = { ...dataPayNew[index], status: 1 }
          setDataPay(dataPayNew)
          status(dataPay) // trả về true cho pay menu biết được đã có thay đổi
          // Xử lý Cộng tiền tài khoản cho user.
          handleAddMoneyUser(dataPayNew[index].id, dataPayNew[index].deposit)
        },
        process.env.REACT_APP_APIEditUserPayHis,
      )
    }
  }

  return (
    <div
      className="table-responsive"
      id={id}
      style={{
        height: "calc(100vh - 12rem)",
        overflow: "auto",
      }}
    >
      <table className="table card-table table-vcenter text-nowrap mb-0">
        <tbody className="active-tab">
          {dataPay.map((value) => {
            let status_ = (
              <button
                className="border-0 rounded-2 fs-9 badge bg-warning"
                onClick={() => handleEventPending(value.id_his)}
              >
                Chờ duyệt
              </button>
            )
            if (value.status === 1) {
              status_ = (
                <button className="border-0 rounded-2 fs-9 badge bg-success">
                  Đã duyệt
                </button>
              )
            }
            return (
              <tr key={value.id_his + id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="lh-1">
                      <span className="avatar avatar-md online avatar-rounded me-2">
                        <img src={"../" + value.avatar} alt="" />
                      </span>
                    </div>
                    <div className="align-items-center">
                      <span className="fs-12 text-muted">Họ tên</span>
                      <p className="mb-0">{value.hoten}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-12 text-muted">Ngày tháng</span>
                    <p className="mb-0 fw-semibold">
                      {moment(value.addtime).format("DD/MM/YYYY HH:mm")}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-12 text-muted">Số tiền</span>
                    <p className="mb-0">
                      {Number(value.deposit)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-12 text-muted">Tổng tiền nạp</span>
                    <p className="mb-0">
                      {Number(value.total_deposit)
                        .toLocaleString()
                        .replace(/[.]/g, ",")}
                    </p>
                  </div>
                </td>
                <td>
                  <div className="align-items-center">
                    <span className="fs-12 text-muted">Tình trạng</span>
                    <p className="mb-0" id={"statusPay" + value.id_his}>
                      {status_}
                    </p>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {hasMore ? (
        data.length >= 20 ? (
          <LoadingText text="Loading..." />
        ) : (
          ""
        )
      ) : (
        <LoadingText text="Bạn đã xem hết rồi" />
      )}
    </div>
  )
}

export default ListPay

// Xử lý add money user.
function handleAddMoneyUser(id_, addMoney) {
  console.log(addMoney)
  const fetchData = async () => {
    const response = await fetch(process.env.REACT_APP_APIGetUserPay)
    const dataRes = await response.json()
    const filter = dataRes.find((item) => item.id === Number(id_))
    if (dataRes.length > 0) {
      const valueInputEdit = {
        id: Number(id_),
        total_deposit: Number(filter.total_deposit) + Number(addMoney),
        total_balance: Number(filter.total_balance) + Number(addMoney),
      }
      // Xử lý edit.
      handleMethodEditData(
        valueInputEdit,
        () => {},
        process.env.REACT_APP_APIEditUserPay,
      )
    }
  }
  fetchData()
}
