import LayoutMainMobile from "./LayoutMainMobile";

function PageHomeMobile() {
    return (
      <>
        <LayoutMainMobile />
      </>
    )
}

export default PageHomeMobile