import classNames from "classnames/bind"
import { useState, useEffect, useContext } from "react"
import moment from "moment"
import styles from "./Notification.module.scss"
import { IoWalletOutline } from "react-icons/io5"

import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import { LoadingSpinner } from "../Loading"

const cx = classNames.bind(styles)

export default function NotificationList({ status, result }) {
  const { dataUser, dataBalance } = useContext(AppContext)
  const [dataHis, setDataHis] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    const response = await fetch(
      process.env.REACT_APP_APIGetUserPayHis + `?only=${dataUser[0].id}`,
    )
    const dataRes = await response.json()
    const filterData = dataRes.filter((item) => item.status === 1)
    setDataHis(filterData)
    setLoading(false)
    result() // gán lại giá trị ban đầu để mỗi lần click noti sẽ gọi lại data
  }
  useEffect(() => {
    if (status) {
      setTimeout(() => {
        fetchData()
      }, 300)
    }
  }, [status])

  return (
    <div className={cx("dropdown")}>
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 fs-17 fw-semibold">Thông báo</p>
        </div>
      </div>
      <div className="dropdown-divider"></div>
      {loading ? <LoadingSpinner /> : ""}
      <ul className="list-unstyled mb-0" id="header-notification-scroll">
        {!loading && dataHis.length > 0
          ? dataHis.map((item) => (
              <li className="dropdown-item mb-3 rounded-3" key={item.id_his}>
                <div className="d-flex align-items-start">
                  <div className="pe-2">
                    <span className="avatar avatar-md bg-primary-transparent avatar-rounded">
                      <IoWalletOutline />
                    </span>
                  </div>
                  <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                    <div className="fs-15">
                      <p className="mb-0 fw-semibold">
                        <a href="#">
                          Bạn vừa được{" "}
                          <span className="text-success">
                            +
                            {Number(item.deposit)
                              .toLocaleString()
                              .replace(/[.]/g, ",")}
                            đ
                          </span>
                        </a>
                      </p>
                      <span className="text-muted fw-normal fs-13 header-notification-text">
                        Số dư tài khoản hiện tại{" "}
                        {Number(dataBalance[0].total_balance)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ
                      </span>
                      <p className="fs-13 text-muted mb-0">
                        {moment(item.addtime).locale("vi").startOf().fromNow()}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))
          : ""}
      </ul>
    </div>
  )
}
