import HeaderDashboardPage from "./Header"
import classNames from "classnames/bind"
import styles from "./Dashboard.module.scss"
import Hopdong from "./Component/Hopdong"

const cx = classNames.bind(styles)

function HopdongPage() {
  return (
    <div className={cx("page justify-content-start", "bg-page")}>
      <div className="mt-3">
        <HeaderDashboardPage />
        <div className={cx("mt-3")}>
          <Hopdong />
        </div>
      </div>
    </div>
  )
}

export default HopdongPage
