import classNames from "classnames/bind";
import styles from "./Loading.module.scss"

const cx = classNames.bind(styles);

function LoadingText({text}) {
    return (
      <div className="fs-15 text-center mt-3 position-relative">
        <div className={cx("loadingText")}>{text}</div>
      </div>
    )
}

export default LoadingText;