import { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import classNames from "classnames/bind"
import io from "socket.io-client"
import { IoIosArrowBack } from "react-icons/io"


import styles from "../Mobile.module.scss"
import { images } from "../../../assets/images"

const cx = classNames.bind(styles)

function NotificationMobile() {
  // Sử dụng điều hướng với navigate
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }

  // Get dataa notifi.
  const [dataNotifi, setDataNotifi] = useState([])
  const [notifiCount, setNotifiCount] = useState(0)
  const socketRef = useRef()
  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_SERVER)
  }, [])
  useEffect(() => {
    socketRef.current.on("server-notification", function (data) {
      const sortData = data.sort((a, b) => {
        let min_ = new Date(a.start_time).getTime()
        let max_ = new Date(b.start_time).getTime()
        return max_ - min_
      })
      setDataNotifi(sortData)
      setNotifiCount(data.length)
    })
    return () => {
      socketRef.current.off("server-notication")
    }
  }, [])

  return (
    <div>
      {/* App Header */}
      <div className={cx("appHeader")}>
        <div className={cx("left", "d-flex align-items-center")}>
          <Link
            to="#"
            className={cx("headerButton", "goBack")}
            onClick={goBack}
          >
            <IoIosArrowBack className="me-1 fs-15" /> <span>QUAY LẠI</span>
          </Link>
        </div>
        <div className={cx("pageTitle")}>Thông báo</div>
        {/* <div className={cx("right")}>
          <Link to="#" className="headerButton">
            <IoNotificationsOutline className="fs-25" />{" "}
            <span>{notifiCount}</span>
          </Link>
        </div> */}
      </div>
      {/* *App Header */}

      {/* List notification */}
      {dataNotifi.length > 0 ? (
        <ListNotification dataNotifi={dataNotifi} />
      ) : (
        <div className="text-center fs-15" style={{marginTop: "68px"}}>Bạn chưa có thông báo.</div>
      )}
    </div>
  )
}

export default NotificationMobile

// List notification.
function ListNotification({ dataNotifi }) {
  console.log(dataNotifi)
  return (
    <div className={cx("appCapsule")}>
      <ul className={cx("mt-3")}>
        {dataNotifi.map((item) => {
          return (
            <li key={item.id} className={cx("mb-3")}>
              <Link to="#" className={cx("d-flex")}>
                <img src={images.notifi} alt="" width={35} height={35} />
                <div
                  className={cx(
                    "ms-3",
                    "d-flex justify-content-between col-10",
                  )}
                >
                  <div className={cx("fs-15")}>
                    <strong className={cx("fw-5")}>{item.title}</strong>
                    <p className={cx("text-muted fs-14")}>{item.description}</p>
                  </div>
                  <div>
                    {item.image ? (
                      <img
                        src={item.image}
                        alt=""
                        width={100}
                        height={68}
                        className={cx("imageNoti")}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
