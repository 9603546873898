
import LayoutLeft from "./LayoutLeft";
import LayoutRight from "./LayoutRight";

function LayoutCustomer({dataCountCate}) {
    return (
        <div className="d-flex col-xl-12 col-md-12">
            {/* Left */}
            <div className="col-xl-5 col-md-12">
                <LayoutLeft dataCountCate={dataCountCate} />
            </div>
            {/* Right */}
            <div className="col-xl-7 col-md-12">
                <LayoutRight />
            </div>
        </div>
    );
}

export default LayoutCustomer;