import ModalA from "../../../components/Modal/ModalA"
import RegisterCover from "./Register-cover"

function Register() {
  return (
    <ModalA
      id="registerCover"
      theme={<RegisterCover />}
      modalTitle="Đăng ký tài khoản"
    />
  )
}

export default Register
