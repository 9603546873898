import { useForm } from "react-hook-form"
import { useState, useEffect, useRef } from "react"
import { TiUpload } from "react-icons/ti"
import classNames from "classnames/bind"
import styles from "./Register-conver.module.scss"
import { handleRegister } from "../../../handle-reuses/reuses/Reuses-register"
const cx = classNames.bind(styles)

function RegisterCover() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const linkRef = useRef()

  const [listImage, setListImages] = useState([])
  const [nameFileAvatar, setNameFileAvatar] = useState("")
  const [namePreview, setNamePreview] = useState("")
  const [phonePreview, setPhonePreview] = useState("")
  const [checkpasswords, setCheckPasswords] = useState(true)
  const [file, setFile] = useState()
  const [resetComponent, setResetComponent] = useState(false)

  // Xử lý link ảnh
  const handleEventClickImage = () => {
    linkRef.current.click()
  }
  // Get image
  const handleAddAvatar = (e) => {
    const files = e.target.files
    if (files.length > 0) {
      const preAvatar = Array.from(files).map((file) =>
        URL.createObjectURL(file),
      )
      setListImages(preAvatar)
      setNameFileAvatar(e.target.files[0].name)
      setFile(files)
    }
  }
  // Xóa khỏi bộ nhớ nếu thay đổi image.
  useEffect(() => {
    // Clearnup (xóa dữ liệu hình ảnh cũ khỏi bộ nhớ)
    return () => {
      if (listImage.length > 0) {
        URL.revokeObjectURL(listImage)
      }
    }
  }, [listImage])

  // Preview name, phone.
  const handleNamePreview = (e) => {
    setNamePreview(e.target.value)
  }
  const handlePhonePreview = (e) => {
    setPhonePreview(e.target.value)
  }
  // check passwords.
  const handleCheckPasswords = (e) => {
    if (e.target.value.length >= 6) {
      setCheckPasswords(true)
    } else {
      setCheckPasswords(false)
    }
  }

  // Xử lý submit.
  const onSubmit = (data) => {
    delete data.avatar
    delete data.dieukhoan
    // format times
    let dateTime = new Date()
    let days_ = dateTime.getDate()
    let months_ = dateTime.getMonth() + 1
    let year_ = dateTime.getFullYear()
    let hours_ = dateTime.getHours()
    let fullName = days_ + "-" + months_ + "-" + year_ + "-" + hours_
    if (nameFileAvatar) {
      data.avatar = `users/${year_}/month${months_}/days${days_}/${fullName}-${nameFileAvatar}`
    } else {
      data.avatar = ""
    }
    // Điều kiện password đạt điều kiện.
    if (checkpasswords) {
      // Xử lý thêm dữ liệu
      handleRegister(data, file, reset)
    }
  }

  return (
    <div className="card custom-card">
      <div className="card-body p-3">
        <p className="h5 fw-semibold mb-2 text-center">Thông tin đăng ký</p>
        <p className="mb-4 text-muted op-7 fw-normal text-center">
          Chào mừng bạn đến với Bất Động Sản View 360 !
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row gy-3">
            <div className="col-xl-12 col-xl-12 d-flex justify-content-center align-items-center">
              <div className="avatar avatar-xxl avatar-rounded me-3 position-relative overflow-hidden cursor border-1">
                <img src={listImage[0]} className={cx("border-1")} />
                <input
                  type="file"
                  {...register("avatar")}
                  onChange={handleAddAvatar}
                  style={{ display: "none" }}
                  ref={linkRef}
                />
                <label
                  htmlFor="fileInput"
                  onClick={handleEventClickImage}
                  className={cx("position-absolute", "buttonAddImage")}
                >
                  <TiUpload className={cx("op-3")} />
                </label>
              </div>
              <div>
                <div className="fs-21">{namePreview}</div>
                <div className="fs-18">{phonePreview}</div>
              </div>
            </div>
            <div className="col-xl-12">
              <label
                htmlFor="signup-firstname"
                className="form-label text-default"
              >
                Họ tên
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Họ tên"
                {...register("hoten", { required: true })}
                onChange={handleNamePreview}
              />
              {errors.hoten && (
                <span className="fs-12 text-danger">*Bạn cần nhập họ tên</span>
              )}
            </div>
            <div className="col-xl-12">
              <label
                htmlFor="signup-lastname"
                className="form-label text-default"
              >
                Số điện thoại
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Số điện thoại"
                {...register("sodienthoai", { required: true })}
                onChange={handlePhonePreview}
              />
              {errors.sodienthoai && (
                <span className="fs-12 text-danger">
                  *Bạn cần nhập số điện thoại
                </span>
              )}
            </div>
            <div className="col-xl-12">
              <label
                htmlFor="signup-lastname"
                className="form-label text-default"
              >
                Email
              </label>
              <input
                type="email"
                className="form-control form-control-lg"
                placeholder="Email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="fs-12 text-danger">*Bạn cần nhập Email</span>
              )}
            </div>
            <div className="col-xl-12">
              <label
                htmlFor="signup-firstname"
                className="form-label text-default"
              >
                Giới tính
              </label>
              <select
                {...register("gioitinh")}
                className="form-control form-control-lg"
              >
                <option value="">Chọn giới tính</option>
                <option value="Nam">Nam</option>
                <option value="Nữ">Nữ</option>
                <option value="Khác">Khác</option>
              </select>
            </div>
            <div className="col-xl-12">
              <label
                htmlFor="signup-password"
                className="form-label text-default"
              >
                Mật khẩu
              </label>
              <input
                type="password"
                className="form-control form-control-lg"
                placeholder="Mật khẩu cần lớn hơn 6 ký tự"
                {...register("matkhau", { required: true })}
                onChange={handleCheckPasswords}
              />
              {checkpasswords === false && (
                <span className="fs-12 text-danger">
                  *Mật khẩu cần có độ dài ít nhất 6 ký tự
                </span>
              )}
            </div>
            <div className="col-xl-12 mb-2">
              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  {...register("dieukhoan", { required: true })}
                />
                <label
                  className="form-check-label text-muted fw-normal"
                  htmlFor="defaultCheck1"
                >
                  Với việc đăng ký, bạn đã đọc và đồng ý với điều khoản{" "}
                  <a href="terms_conditions.html" className="text-success">
                    <u>Điều khoản</u>
                  </a>{" "}
                  và{" "}
                  <a className="text-success">
                    <u>Chính sách bảo mật</u>
                  </a>
                </label>
                {errors.dieukhoan && (
                  <span className={cx("fs-11 text-danger")}>
                    *Hãy đồng ý các điều khoản cũng như chính sách bảo mật cho
                    việc truyền thông của chúng tôi.
                  </span>
                )}
              </div>
            </div>
            <div className="col-xl-12 d-grid mt-2">
              <button type="submit" className="btn btn-lg btn-primary">
                Đăng ký
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RegisterCover
