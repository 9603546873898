import { useState, useEffect } from "react"
import { PiCompass } from "react-icons/pi"
import classNames from "classnames/bind"

import styles from "./Phongthuy.module.scss"
import ModalReact from "../Modal/ModalReact"
import { hadoLacthu, quetBatTrach, ynghiaPhuhop } from "./BangPhongThuy"
import { LoadingSpinner } from "../Loading"

const cx = classNames.bind(styles)
function Phongthuy({ className, sizeIcon }) {
  const [showHide, setShowHide] = useState(false)

  const handleShowModal = () => {
    setShowHide(true)
  }
  const handleClose = () => setShowHide(false)
  return (
    <>
      <button
        type="button"
        className={cx("me-2", "border-0 op-7", "bg-transparent", className)}
        onClick={handleShowModal}
      >
        <PiCompass className={sizeIcon} />
      </button>
      {/* Modal */}
      <ModalReact
        modalTitle="Xem hướng nhà phù hợp theo năm sinh(Âm lịch)"
        showModal={showHide}
        handleClose={handleClose}
        theme={<HandleResult />}
      />
    </>
  )
}

export default Phongthuy

// Giao diện kết quả
export function HandleResult() {
  const [result, setResult] = useState([])
  const [object, setObject] = useState({
    year: "",
    sex: "Nam",
  })
  const handleInputYear = (e) => {
    let year__ = e.target.value;
    setObject({
      year: e.target.value,
      sex: object.sex,
    })
    if (year__.length >= 4) {
      const result_ = handleLogic({
        year: e.target.value,
        sex: object.sex,
      })
      setResult(result_)
    }
  }
  const handleInputSex = (e) => {
    setObject({
      year: object.year,
      sex: e.target.value,
    })
    const result_ = handleLogic({
      year: object.year,
      sex: e.target.value,
    })
    setResult(result_)
  }
  // Submit
  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   const result_ = handleLogic(object)
  //   setResult(result_)
  // }
  return (
    <div>
      <form>
        <div className="d-flex col-12">
          <div className="form-floating mb-3 me-3 col-7">
            <input
              type="number"
              value={object.year}
              className="form-control rounded-3 fs-18"
              id="yearInput"
              placeholder="Năm sinh của bạn"
              onChange={handleInputYear}
            />
            <label className="fs-15">Nhập năm sinh của bạn</label>
          </div>
          <div className="form-floating mb-3 col-4">
            <select
              value={object.sex}
              className="form-select fs-18"
              id="floatingSelect"
              aria-label="Chọn giới tính"
              onChange={handleInputSex}
            >
              <option value="Nam">Nam</option>
              <option value="Nữ">Nữ</option>
            </select>
            <label className="fs-15">Giới tính</label>
          </div>
        </div>
        {/* <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button className="btn btn-outline-dark m-1" type="submit">
            Xem kết quả
          </button>
        </div> */}
      </form>
      {/* Hiển thị kết quả */}
      {result.length > 0 && <ShowResults data={result} />}
    </div>
  )
}

// Hiển thị kết quả.
function ShowResults({ data}) {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500);
  }, [data])
  if (loading) {
    return <LoadingSpinner />
  }
  // console.log(data)
  return (
    <div className="panel-body">
      <h3 className="text-center fs-23">Hướng tốt - {data[0].nhomhuong}</h3>
      {data.map((item, index) => {
        return (
          <p className="fs-15" key={index}>
            <span className="fw-6 me-1">Hướng {item.huongnha}:</span>
            <span className="text-success me-1 fw-6">{item.title}</span>
            <span className="me-1 text-warning">{item.description}</span><br />
            <span className="text-muted">{item.detail}</span>
          </p>
        )
      })}
    </div>
  )
}

// Xử lý logic.
function handleLogic(object) {
  
  const year = object.year.split("")
  let totalYear = year.reduce((a, b) => {
    return Number(a) + Number(b)
  })

  // Nếu totalYear lớn hơn hoặc bằng 10 nó lại chạy lại
  while (totalYear >= 10) {
    totalYear = tinhTongChuSo(totalYear)
  }

  let checkHuong = 0
  if (Number(object.year) >= 2000) {
    // Tính từ năm 2000 trở về đây.
    if (object.sex === "Nam") {
      checkHuong = 11 - totalYear
      if (checkHuong >= 10) {
        checkHuong = tinhTongChuSo(checkHuong)
      }
    } else if (object.sex === "Nữ") {
      checkHuong = 4 + totalYear
      if (checkHuong >= 10) {
        checkHuong = tinhTongChuSo(checkHuong)
      }
    }
  } else {
    // Tính từ năm 1999 trở về trước
    if (object.sex === "Nam") {
      checkHuong = 11 - totalYear
      if (checkHuong >= 10) {
        checkHuong = tinhTongChuSo(checkHuong)
      }
    } else if (object.sex === "Nữ") {
      checkHuong = 4 + totalYear
      if (checkHuong >= 10) {
        checkHuong = tinhTongChuSo(checkHuong)
      }
    }
  }
  // console.log(checkHuong)
  let resHuongnha
  // Kiểm tra thuộc năm sinh thuộc hướng nào
  if (
    checkHuong === 1 ||
    checkHuong === 3 ||
    checkHuong === 4 ||
    checkHuong === 9
  ) {
    resHuongnha = handleFilterAllArr(checkHuong, "Đông tứ trạch", object.sex)
  } else if (
    checkHuong === 2 ||
    checkHuong === 5 ||
    checkHuong === 6 ||
    checkHuong === 7 ||
    checkHuong === 8
  ) {
    resHuongnha = handleFilterAllArr(checkHuong, "Tây tứ trạch", object.sex)
  }

  return resHuongnha
}

// Xử lý tổng.
function tinhTongChuSo(n) {
  let tong = 0
  while (n > 0) {
    tong += n % 10
    n = Math.floor(n / 10)
  }
  return tong
}

// Lọc các dữ liệu phù hợp với nhau.
function handleFilterAllArr(checkHuong, nhomHuong, gioitinh) {
  let resHuongnha = []
  // lọc các hướng phù hợp.
  let cachuong = hadoLacthu.filter((item) => {
    return item.nhom === nhomHuong
  })
  // console.log(cachuong)
  let quaiSo_ = cachuong.find((item) => {
    // theo dân gian nếu bằng 5 thì set
    if (checkHuong === 5) {
      if (gioitinh === "Nam") {
        checkHuong = 2
      } else if (gioitinh === "Nữ") {
        checkHuong = 8
      }
    }
    return item.number === checkHuong
  })

  // Quẻ bát trạch.
  let resQueBatTrach = []
  for (let i = 0; i < cachuong.length; i++) {
    let batTrach = quetBatTrach.filter((item) => {
      return (
        item.cungmenh === quaiSo_.cung && item.que === cachuong[i].huong
      )
    })
    // console.log(batTrach)
    if (batTrach.length > 0) {
      resQueBatTrach.push(batTrach[0])
    }
  }
  // console.log(resQueBatTrach)
  // Kết quả cuối cùng để hiển thị
  for (let i = 0; i < resQueBatTrach.length; i++) {
    let res = ynghiaPhuhop.filter((item) => {
      return item.title.includes(resQueBatTrach[i].description)
    })
    // console.log(res)
    let object = {}
    if (res.length > 0) {
      object.huongnha = resQueBatTrach[i].que
      object.title = res[0].title
      object.description = res[0].description
      object.detail = res[0].detail
      object.nhomhuong = nhomHuong
      resHuongnha.push(object)
    }
  }
  // console.log(resHuongnha)
  return resHuongnha
}
