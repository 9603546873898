import ModalA from "../Modal/ModalA";
import FormRealEstateFast from "../Form/FromRealEstateFast";
import moment from "moment";

function FooterSimple() {
  return (
    <div>
      <div
        style={{
          position: "fixed",
          bottom:"0px",
          left: "80px",
          width:"20%",
          fontSize: "12px",
        }}
      >
        <div className="text-center landing-main-footer py-3">
          <span className="text-muted">
            {" "}
            Copyright © <span id="year">{moment().format("YYYY")}</span>{" "}
            <a href="#" className="fw-semibold">
              <u>System</u>
            </a>
            . Designed with <span className="fa fa-heart text-danger"></span>{" "}
            by{" "}
            <a href="#" className="fw-semibold">
              <u>Hoàng hiếu</u>
            </a>{" "}
            All rights reserved
          </span>
        </div>
      </div>
      {/* modal post fast */}
      <ModalA
        id="postProductFast"
        size="modal-fade"
        theme={<FormRealEstateFast />}
        modalTitle="Thông tin đưa ra thị trường"
      />
      {/* modal post product pro*/}
      {/* <ModalA
        id="postProductPro"
        size="modal-xl"
        theme={<FormRealEstate />}
        modalTitle="Đăng tin chuyên nghiệp"
      /> */}
    </div>
  );
}

export default FooterSimple;