import classNames from "classnames/bind";
import styles from "./Alert.module.scss"
import { FaCheckCircle } from "react-icons/fa"


const cx = classNames.bind(styles);
function AlertSuccess() {
    return (
      <div
        className={cx(
          "bg-alert-w",
          "d-flex justify-content-center flex-direction-column align-items-center",
        )}
      >
        <div>
          <FaCheckCircle className={cx("fs-29 text-success")} />
        </div>
        <div className="fs-21 text-success">Cảm ơn bạn</div>
        <div className="text-center">
          <p className="mb-0">Thông tin thanh toán của bạn đang được kiểm duyệt.</p>
          <p className="mb-0">Số dư sẽ tự động cập nhật khi duyệt thành công.</p>
        </div>
      </div>
    )
}

export default AlertSuccess;