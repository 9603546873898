import { useState} from "react"
import { Nav } from "react-bootstrap"
import classNames from "classnames/bind"
import styles from "../../Dashboard.module.scss"
import SearchSimple from "../../../../../components/Search/SearchSimple"

const cx = classNames.bind(styles)

function Khaithac() {
  const [activeTab, setActiveTab] = useState("home")

  const handleTabSelect = (key) => {
    setActiveTab(key)
  }

  return (
    <div className="tab-style-2">
      <div className="d-flex align-items-center">
        <Nav variant="tabs" defaultActiveKey="home" className="col-8">
          <Nav.Item>
            <Nav.Link
              eventKey="home"
              onClick={() => handleTabSelect("home")}
              active={activeTab === "home"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              {/* <IoCalendarOutline className="mb-1 fs-21" /> */}
              Tất cả (30)
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="canho"
              onClick={() => handleTabSelect("canho")}
              active={activeTab === "canho"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              Căn hộ (2)
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="chungcu"
              onClick={() => handleTabSelect("chungcu")}
              active={activeTab === "chungcu"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              Chung cư (12)
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="nhatang"
              onClick={() => handleTabSelect("nhatang")}
              active={activeTab === "nhatang"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              {/* <BsCalendar2Week className="mb-1 fs-19" /> */}
              Nhà tầng (10)
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="nhanho"
              onClick={() => handleTabSelect("nhanho")}
              active={activeTab === "nhanho"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              Nhà nhỏ (12)
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="phongtro"
              onClick={() => handleTabSelect("phongtro")}
              active={activeTab === "phongtro"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              Phòng trọ (12)
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="col-4 d-flex justify-content-end">
          <SearchSimple />
        </div>
      </div>

      {/* Nội dung của tab được hiển thị dựa trên activeTab */}
      <div className={cx("container", "mt-3")}>
        {activeTab === "home" && <div className=""></div>}
        {activeTab === "canho" && (
          <div className="">Content for căn hộ tab</div>
        )}
        {activeTab === "chungcu" && (
          <div className="">Content for chung cư</div>
        )}
        {activeTab === "nhatang" && <div className="">Danh sách nhà tầng</div>}

        {activeTab === "nhanho" && (
          <div className="">Content for Contact tab nhà nhỏ</div>
        )}
        {activeTab === "phongtro" && (
          <div className="">Content for Contact phòng trọ</div>
        )}
      </div>
    </div>
  )
}
export default Khaithac
