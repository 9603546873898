import { useState, useContext } from "react"
import { Controller, useForm} from "react-hook-form"
import classNames from "classnames/bind"

import styles from "../../Dashboard.module.scss"
import { handleFormatPriceInput } from "../../../../../handle-reuses/reuses"
import { handleAddData } from "./Handle/Method"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"

const cx = classNames.bind(styles)

const optionCate = [
  { id: 1, cate: "Mua nhà" },
  { id: 2, cate: "Mua đất" },
  { id: 3, cate: "Mua đầu tư" },
  { id: 4, cate: "Thuê nhà riêng" },
  { id: 5, cate: "Thuê phòng trọ" },
  { id: 6, cate: "Thuê căn hộ" },
  { id: 7, cate: "Thuê chung cư" },
  { id: 8, cate: "Thuê mặt bằng" },
]
const optionHuong = [
  { id: 1, title: "Đông" },
  { id: 2, title: "Tây" },
  { id: 3, title: "Nam" },
  { id: 4, title: "Băc" },
  { id: 5, title: "Đông Nam" },
  { id: 6, title: "Tây Bắc" },
  { id: 7, title: "Đông Bắc" },
  { id: 8, title: "Tây Nam" },
]

function FormAddCustomer({ result }) {
  const { dataUser } = useContext(AppContext)
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [suggestStreet, setSuggestStreet] = useState([])
  const [suggestDistricts, setSuggestDistricts] = useState([])
  const [suggestCitys, setSuggestCitys] = useState([])
  const [street, setStreet] = useState("")
  const [districts, setDistricts] = useState("")
  const [citys, setCitys] = useState("")
  const [formatPrice, setFormatPrice] = useState("")

  const fetchData = async (streetsText, districtsText, citysText) => {
    if (streetsText) {
      const response = await fetch(
        process.env.REACT_APP_APIGetDataStreetProject +
          `?streets=${streetsText}`,
      )
      const dataResStreet = await response.json()
      // Loại bỏ giá trị giống nhau trong mảng.
      const arr = []
      const resultArr = dataResStreet.filter((item) => {
        if (!arr[item.street_project]) {
          arr[item.street_project] = true
          return true
        }
      })
      setSuggestStreet(resultArr)
    }
    if (districtsText) {
      const response = await fetch(
        process.env.REACT_APP_APIGetDataStreetProject +
          `?districts=${districtsText}`,
      )
      const dataResDistrict = await response.json()
      // Loại bỏ giá trị giống nhau.
      const arr = []
      const resultArr = dataResDistrict.filter((item) => {
        if (!arr[item.districts]) {
          arr[item.districts] = true
          return true
        }
      })
      setSuggestDistricts(resultArr)
    }
    if (citysText) {
      const response = await fetch(
        process.env.REACT_APP_APIGetDataStreetProject + `?citys=${citysText}`,
      )
      const dataResCity = await response.json()
      // Loại bỏ giá trị giống nhau.
      const arr = []
      const resultArr = dataResCity.filter((item) => {
        if (!arr[item.citys]) {
          arr[item.citys] = true
          return true
        }
      })
      setSuggestCitys(resultArr)
    }
  }
  // Xử lý gợi ý street
  const handleSuggestStreet = (e) => {
    setStreet(e.target.value)
    fetchData(e.target.value)
    if (e.target.value === "") {
      setSuggestStreet([])
    }
  }
  const handleOptionStreet = (option) => {
    setStreet(option)
    setSuggestStreet([])
  }
  // Xử lý gợi ý districs.
  const handleSuggestDistricts = (e) => {
    setDistricts(e.target.value)
    fetchData("", e.target.value, "")
    if (e.target.value === "") {
      setSuggestDistricts([])
    }
  }
  const handleOptionDistricts = (option) => {
    setDistricts(option)
    setSuggestDistricts([])
  }
  // Xử lý gợi ý citys
  const handleSuggestCitys = (e) => {
    setCitys(e.target.value)
    fetchData("", "", e.target.value)
    if (e.target.value === "") {
      setSuggestCitys([])
    }
  }
  const handleOptionCitys = (option) => {
    setCitys(option)
    setSuggestCitys([])
  }

  // Format price.
  const handlePrice = (e) => {
    setFormatPrice(e.target.value)
    if (e.target.value !== "") {
      const formatPrice = handleFormatPriceInput(e.target.value)
      setFormatPrice(formatPrice)
    }
  }

  // Submit
  const onSubmit = (data) => {
    data.userid = dataUser[0].id
    handleAddData(data, reset)
    result(true)
  }
  return (
    <div className="p-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gy-3 pb-3">
          <div className="col-xl-6 col-md-12">
            <div className="form-floating">
              <select
                {...register("loaisanpham", { required: true })}
                className="form-control"
              >
                <option value="">Chọn danh mục</option>
                {optionCate.map((option) => {
                  return (
                    <option key={option.id} value={option.cate}>
                      {option.cate}
                    </option>
                  )
                })}
              </select>
              <label className="form-label mb-1">
                Danh mục <sup className="text-danger">*</sup>
              </label>
              {errors.loaisanpham && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa chọn danh mục
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <Controller
                name="tuyenduong"
                control={control}
                defaultValue=""
                render={({ field: { onChange } }) => (
                  <>
                    <input
                      value={street}
                      className="form-control"
                      placeholder="Trần nguyên hãn"
                      onChange={(e) => {
                        onChange(e)
                        handleSuggestStreet(e)
                      }}
                    />
                    <label>Tên đường hoặc dự án</label>
                    <div
                      className={cx(
                        "suggestList",
                        suggestStreet.length > 0 ? "active" : "",
                      )}
                    >
                      <ul>
                        {suggestStreet.map((item) => {
                          return (
                            <li
                              key={item.id}
                              onClick={() => {
                                onChange(item.street_project)
                                handleOptionStreet(item.street_project)
                              }}
                            >
                              {item.street_project}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <Controller
                name="quanhuyen"
                control={control}
                defaultValue=""
                render={({ field: { onChange } }) => (
                  <>
                    <input
                      value={districts}
                      className="form-control"
                      placeholder="Lê Chân"
                      onChange={(e) => {
                        onChange(e)
                        handleSuggestDistricts(e)
                      }}
                    />
                    <label>Chọn quận/huyện</label>
                    <div
                      className={cx(
                        "suggestList",
                        suggestDistricts.length > 0 ? "active" : "",
                      )}
                    >
                      <ul>
                        {suggestDistricts.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => {
                                onChange(item.districts)
                                handleOptionDistricts(item.districts)
                              }}
                            >
                              {item.districts}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <Controller
                name="thanhpho"
                control={control}
                defaultValue=""
                render={({ field: { onChange } }) => (
                  <>
                    <input
                      value={citys}
                      className="form-control"
                      placeholder="Hải phòng"
                      onChange={(e) => {
                        onChange(e)
                        handleSuggestCitys(e)
                      }}
                    />
                    <label>Chọn thành phố</label>
                    <div
                      className={cx(
                        "suggestList",
                        suggestCitys.length > 0 ? "active" : "",
                      )}
                    >
                      <ul>
                        {suggestCitys.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => {
                                onChange(item.citys)
                                handleOptionCitys(item.citys)
                              }}
                            >
                              {item.citys}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                type="number"
                {...register("duongngo", { required: true })}
                className="form-control"
                placeholder="Đường ngõ"
              />
              <label>
                Đường ngõ <sup className="text-danger">*</sup>
              </label>
              {errors.duongngo && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa đường ngõ
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                type="number"
                {...register("dtmb", { required: true })}
                className="form-control"
                placeholder="Diện tích mặt bằng"
              />
              <label>
                Diện tích mặt bằng <sup className="text-danger">*</sup>
              </label>
              {errors.dtmb && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa Diện tích mặt bằng
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                type="number"
                {...register("mattien", { required: true })}
                className="form-control"
                placeholder="Mặt tiền"
              />
              <label>
                Mặt tiền <sup className="text-danger">*</sup>
              </label>
              {errors.mattien && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa Mặt tiền
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <select
                {...register("huongnha", { required: true })}
                className="form-control"
              >
                <option value="">Chọn hướng</option>
                {optionHuong.map((option) => {
                  return (
                    <option key={option.id} value={option.title}>
                      {option.title}
                    </option>
                  )
                })}
              </select>
              <label>
                Hướng nhà <sup className="text-danger">*</sup>
              </label>
              {errors.huongnha && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa hướng nhà
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-12 col-md-12">
            <div className="form-floating mb-1">
              <input
                type="text"
                value={formatPrice}
                {...register("giasanpham", { required: true })}
                className="form-control"
                placeholder="Giá"
                onChange={handlePrice}
              />
              <label>
                Giá <sup className="text-danger">*</sup>
              </label>
              {errors.giasanpham && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa nhập giá
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                type="text"
                {...register("tenlienhe", { required: true })}
                className="form-control"
                placeholder="Tên liên hệ"
              />
              <label>
                Tên liên hệ <sup className="text-danger">*</sup>
              </label>
              {errors.tenlienhe && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa tên liên hệ
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="form-floating mb-1">
              <input
                type="text"
                {...register("sodienthoai", { required: true })}
                className="form-control"
                placeholder="Số điện thoại"
              />
              <label>
                Số điện thoại <sup className="text-danger">*</sup>
              </label>
              {errors.sodienthoai && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa Số điện thoại
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="px-4 d-flex py-3 border-top border-block-start-dashed d-sm-flex justify-content-center">
          <button
            className="btn btn-outline-dark m-1 px-4"
            type="submit"
            id="submitProduct"
          >
            Thêm mới
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormAddCustomer
