import { HiOutlineBell, HiOutlineChatBubbleLeftRight } from "react-icons/hi2"
import {
  IoWalletOutline,
  IoAddCircleOutline,
} from "react-icons/io5"
import { PiWarningCircle } from "react-icons/pi"
import Tippy from "@tippyjs/react/headless"
import { useState, useContext, useEffect} from "react"

import InfoUser from "../../Info/InfoUser"
import FormRealEstateFast from "../../Form/FromRealEstateFast"
import ModalA from "../../Modal/ModalA"
import ModalReact from "../../Modal/ModalReact"
import { WalletMain } from "../../../pages/pc/Component/Wallet"
import FormSupport from "../../../pages/pc/Component/Support/FormSupport"
import { NotificationList } from "../../Notification"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { handleMethodEditData } from "../../../handle-reuses/reuses/method"

function HeaderRight() {
  const { dataUser, socket } = useContext(AppContext)

  const [showHideWallet, setShowHideWallet] = useState(false)
  const handleShowModal = () => {
    setShowHideWallet(true)
  }
  const handleCloseModal = () => setShowHideWallet(false)
  // Modal support.
  const [showHideSupport, setShowHideSupport] = useState(false)
  const handleShowSupport = () => {
    setShowHideSupport(true)
  }
  const handleCloseSupport = () => setShowHideSupport(false)
  // Modal Chatbot.
  const [showHideChat, setShowHideChat] = useState(false)
  const handleShowChat = () => {
    setShowHideChat(true)
  }
  const handleCloseChat = () => setShowHideChat(false)

  // count notifi.
  const [dataHisSuc, setDataHisSuc] = useState([])
  const [checkHideNoti, setCheckHideNoti] = useState(false)

  const fetchData = async () => {
    if (dataUser.length > 0) {
      const response = await fetch(
        process.env.REACT_APP_APIGetNotifiUserPayHis +
          `?only=${dataUser[0].id}&statusonly=${1}`,
      )
      const dataRes = await response.json()
      const filterData = dataRes.filter((item) => item.watched === 0)

      setDataHisSuc(filterData)
    }
  }
  useEffect(() => {
    fetchData()
  }, [checkHideNoti])

  // Check hide notification.
  const handleCheckHideNotifi = () => {
    setCheckHideNoti(true)
  }
  // handle notification.
  const [checkNotification, setCheckNotification] = useState(false)
  const [onOff, setOnOff] = useState(false)
  const handleEventNotification = (check) => {
    setCheckNotification(check)
    setOnOff(true)
    // Tắt thông báo khi click vào chuông thông báo.
    handleHideNotifiEventClick(dataHisSuc, handleCheckHideNotifi)
  }
  const handleCheckOnOffNoti = () => {
    setOnOff(false)
  }

  return (
    <>
      <div className="d-flex">
        <Tippy
          interactive
          delay={[0, 0]}
          placement="left"
          render={() => (
            <div
              style={{
                backgroundColor: "black",
                padding: "3px 6px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              Đăng tin
            </div>
          )}
        >
          <button
            type="button"
            className="border-0 me-3"
            style={{ background: "none" }}
            data-bs-toggle="modal"
            data-bs-target="#postProductFast"
          >
            <IoAddCircleOutline className="fs-21" />
          </button>
        </Tippy>
        <Tippy
          interactive
          delay={[0, 0]}
          placement="left"
          render={() => (
            <div
              style={{
                backgroundColor: "black",
                padding: "3px 6px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              Chatbot
            </div>
          )}
        >
          <button
            type="button"
            className="border-0 me-3"
            style={{ background: "none" }}
            onClick={handleShowChat}
          >
            <HiOutlineChatBubbleLeftRight className="fs-21" />
          </button>
        </Tippy>
        <Tippy
          interactive
          delay={[0, 0]}
          placement="bottom-end"
          trigger="click"
          render={() =>
            checkNotification ? (
              <NotificationList status={onOff} result={handleCheckOnOffNoti} />
            ) : (
              ""
            )
          }
        >
          <button
            type="button"
            className="border-0 me-3 position-relative"
            style={{ background: "none" }}
            onClick={() => handleEventNotification(true)}
          >
            <HiOutlineBell className="fs-21" />
            {dataHisSuc.length > 0 ? (
              <div
                className="position-absolute fs-12"
                style={{
                  top: "5px",
                  right: "-1px",
                  backgroundColor: "#ff6a6a",
                  color: "white",
                  borderRadius: "21px",
                  width: "19px",
                  height: "19px",
                  lineHeight: "19px",
                }}
              >
                {dataHisSuc.length <= 9 ? Number(dataHisSuc.length) : "9+"}
              </div>
            ) : (
              ""
            )}
          </button>
        </Tippy>
        <Tippy
          interactive
          delay={[0, 0]}
          placement="left"
          render={() => (
            <div
              style={{
                backgroundColor: "black",
                padding: "3px 6px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              Ví của bạn
            </div>
          )}
        >
          <button
            type="button"
            className="border-0 me-3"
            style={{ background: "none" }}
            onClick={handleShowModal}
          >
            <IoWalletOutline className="fs-21" />
          </button>
        </Tippy>
        <Tippy
          interactive
          delay={[0, 0]}
          placement="left"
          render={() => (
            <span
              style={{
                backgroundColor: "black",
                padding: "3px 6px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              Hỗ trợ
            </span>
          )}
        >
          <button
            type="button"
            className="border-0 me-3"
            style={{ background: "none" }}
            onClick={handleShowSupport}
          >
            <PiWarningCircle className="fs-21" />
          </button>
        </Tippy>
        <InfoUser />
      </div>
      {/* Modal Add Product */}
      <ModalA
        id="postProductFast"
        // size="modal-lg"
        theme={<FormRealEstateFast />}
        modalTitle="Thông tin đưa ra thị trường"
      />
      {/* Modal wallet */}
      <ModalReact
        modalTitle="Ví của bạn"
        showModal={showHideWallet}
        handleClose={handleCloseModal}
        theme={<WalletMain />}
      />
      {/* Modal support */}
      <ModalReact
        modalTitle="Hỗ trợ giải đáp"
        showModal={showHideSupport}
        handleClose={handleCloseSupport}
        theme={<FormSupport />}
      />
      {/* Modal Chat */}
      <ModalReact
        modalTitle="Hãy đặt câu hỏi cho tôi"
        showModal={showHideChat}
        handleClose={handleCloseChat}
        theme="Giao diện chat"
      />
    </>
  )
}

export default HeaderRight

// Xửu lý tắt thông báo
function handleHideNotifiEventClick(data, checkHideNoti) {
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const object = {
        id: data[i].id_his,
        watched: 1,
      }
      handleMethodEditData(
        object,
        () => {
          checkHideNoti()
        },
        process.env.REACT_APP_APIEditRowWatchedUserPayHis,
      )
    }
  }
}
