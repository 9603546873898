import classNames from "classnames/bind";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2"
import { useEffect, useState, useRef } from "react";

import styles from "./SearchHeader.module.scss";
const cx = classNames.bind(styles);

function SearchSimple({ result}) {
  const [value, setValue] = useState("");
  const valueRef = useRef("")
  const valueClick = useRef("")
  const handleResult = (e) => {
    setValue(e.target.value)
    
  }

  const handleGetvalue = (e) => {
    if (e.key === "Enter") {
      result(value)
    }
  }

  const handleGetClick = () => {
    result(value)
  }

  useEffect(() => {
    valueRef.current.addEventListener("keydown", handleGetvalue)
    valueClick.current.addEventListener("click", handleGetClick)
    
    return () => {
      if (valueRef.current !== null) {
        valueRef.current.removeEventListener("keydown", handleGetvalue)
      }
      if (valueClick.current !== null) {
        valueClick.current.removeEventListener("click", handleGetClick)
      }
        
    }
  }, [value])


    return (
      <div className={cx("search")}>
        <input
          type="text"
          value={value}
          placeholder="Tìm kiếm..."
          className={cx("searchSimple")}
          onChange={handleResult}
          ref={valueRef}
        />
        <button className={cx("searchIcon")} ref={valueClick}>
            <HiOutlineMagnifyingGlass />
        </button>
      </div>
    )
}

export default SearchSimple;