import { useState} from "react"
import classNames from "classnames/bind"
import styles from "./Payment.module.scss"
import PaymentMenu from "./PaymentMenu"

import CalendarA from "../../../components/Calendar/CalendarA"
import ListPayment from "./ListPayAll"
import ListPayPending from "./ListPayPending"
import ListPaySuccess from "./ListPaySuccess"
import StatisticalPage from "../Statistical"
import ModalA from "../../../components/Modal/ModalA"
import SearchPayment from "./SearchPayment"

const cx = classNames.bind(styles)

function LayoutPayment() {
  const [active, setActive] = useState("all")
  const [statusPay, setStatusPay] = useState([])
  // data được trả về từ category menu
  const handleGetData = (nameCate) => {
    setActive(nameCate)
  }

  // Trả về khi statu thay đổi.
  const handleStatus = (status) => {
    setStatusPay(status)
  }


  return (
    <>
      <div className={cx("row", "justify-content-between")}>
        {/* Layout main */}
        <div className={cx("col-xl-8 col-md-12")}>
          {/* category */}
          <div className={cx("paymentMenu")}>
            <div className={cx("contentPayMenu")}>
              <div className={cx("paymentCate")}>
                <PaymentMenu resultData={handleGetData} status={statusPay} />
              </div>
            </div>
          </div>
          {/* Content */}
          <div className={cx("paymentMain", "mt-3")}>
            {/* All */}
            <div className={cx("contentPayMain")}>
              {active === "all" && <ListPayment status={handleStatus} />}

              {/* Pending */}
              {active === "pending" && <ListPayPending status={handleStatus} />}

              {/* success */}
              {active === "success" && <ListPaySuccess />}

              {/* statistical */}
              {active === "statistical" && <StatisticalPage />}

              {/* Discount */}
            </div>
          </div>
        </div>
        {/* Layoyt right */}
        <div className={cx("paymentRight", "col-xl-4 col-md-12")}>
          <div className={cx("paymentSearch", "mt-2")}>
            <button
              data-bs-toggle="modal"
              data-bs-target="#modalSearchPayment"
              className="btn border-1 rounded-3 btn-outline-dark"
            >
              Tìm kiếm thanh toán
            </button>
          </div>
          <div className={cx("contentPayRight", "mt-3")}>
            <CalendarA />
          </div>
        </div>
      </div>
      <ModalA
        id="modalSearchPayment"
        modalTitle={"Tìm kiếm khoản thanh toán"}
        size="modal-lg"
        theme={<SearchPayment />}
      />
    </>
  )
}

export default LayoutPayment
