import io from "socket.io-client"
import { useContext, useEffect, useState, useRef } from "react"
import classNames from "classnames/bind"

import styles from "./ChatBot.module.scss"
import { images } from "../../assets/images"
import { autoResizeInput } from "../../handle-reuses/reuses"
import AppContext from "../../handle-reuses/Contexts/CreateContexts"
import { handleNLP} from "../../handle-reuses/reuses/suptech/nlp"

const cx = classNames.bind(styles)

const arrSorry = ["Rất xin lỗi tôi chưa thể trả lời câu hỏi này của bạn"]

function ThemeChatBot() {
  const { dataUser } = useContext(AppContext)
  const socket = useRef()
  const [valueText, setValueText] = useState("")
  const [arrChat, setArrChat] = useState([
    {
      image: images.view360,
      name: "View 360",
      content: "Chào bạn tôi có thể giúp gì được bạn &#128512;",
    },
  ])
  const [arrAnswer, setArrAnswer] = useState([])
  // Khởi tạo socket.
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
  }, [])
  // Get value text input.
  const handleGetValueText = (e) => {
    setValueText(e.target.value)
  }
  // Quản lý hiệu ứng.
  useEffect(() => {
    // Tự động dãn kích thước height
    autoResizeInput("messengeChat", 43)
  }, [])
  // Submit.
  const handleSubmit = async (e) => {
    e.preventDefault()
    // Thêm dữ liệu vào bảng arrChat.
    let avatar = images.noImage
    let nameUser = "Bạn"
    if (dataUser.length > 0) {
      nameUser = dataUser[0].hoten
      if (dataUser[0].avatar !== "" || dataUser[0].avatar !== null) {
        avatar = dataUser[0].avatar
      }
    }
    const arrNew = [...arrChat]
    const updateChat = [
      ...arrNew,
      { image: avatar, name: nameUser, content: valueText },
    ]
    setArrChat(updateChat)

    //   console.log(valueText)
    const object = {
      userid: dataUser[0].id,
      question: valueText,
      tokens: valueText.toLowerCase().replace(/[ .,/]/g, ","),
      quantity: 0,
    }
    // socket.current.emit("client-chat-question", object)
    // answer.

    const result = await handleNLP(valueText)

    console.log(result)
    socket.current.emit("client-question-chatbot", result)
  }
  useEffect(() => {
    socket.current.on("server-question-chatbot", (data) => {
      // console.log(data)
      // Xác định mảng chứa câu trả lời
      let arrAnswer = []
      if (data.length > 0) {
        if (!data[0].answer) {
          arrAnswer = [...arrSorry]
        } else {
          arrAnswer = data[0].answer.split(";")
        }
      }
      // console.log(arrAnswer)
      // Xác định câu trả lời
      let answer
      if (arrAnswer.length > 0) {
        let randomIndex = Math.floor(Math.random() * arrAnswer.length)
        answer = {
          image: images.view360,
          name: "View 360",
          content: <TypingEffect text={arrAnswer[randomIndex]} speed={10} />,
        }
      } else {
        answer = {
          image: images.view360,
          name: "View 360",
          content: "Rất xin lỗi tôi chưa thể trả lời bạn lúc này.",
        }
      }
      // Trả về kết quả cho người dùng
      setTimeout(() => {
        setArrChat((arrOld) => [...arrOld, answer])
      }, 100)
      setValueText("")
    })
  }, [])

  return (
    <div>
      <div className={cx("d-flex")}>
        {/* Chat content */}
        <div className={cx("col-12 mb-5 px-5")}>
          <ul>
            {arrChat.map((item, index) => {
              return (
                <li className={cx("d-flex mt-3 mb-4")} key={index}>
                  <div className={cx("chat-list-inner")}>
                    <div className="chat-user-profile">
                      <span className="avatar avatar-sm avatar-rounded chatstatusperson mt-1">
                        <img
                          className="chatimageperson"
                          src={item.image}
                          alt="img"
                        />
                      </span>
                    </div>
                    <div className="ms-3">
                      <span className={cx("chatting-user-info")}>
                        <span className={cx("fw-6 fs-16")}>{item.name}</span>
                      </span>
                      <div className={cx("chat-content")}>
                        <div className={cx("fs-15")}>
                          <p className="mb-0">{item.content}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {/* Messenge chat send */}
      <form onSubmit={handleSubmit}>
        <div className={cx("col-12 px-3 position-relative")}>
          <textarea
            type="text"
            placeholder="Nhập văn bản"
            value={valueText}
            onChange={handleGetValueText}
            className={cx("col-12", "form-control rounded-3")}
            id="messengeChat"
            style={{ height: "43px", fontSize: "15px" }}
          ></textarea>
          <button className={cx("send", "border-0 px-3 rounded-3")}>Gửi</button>
        </div>
      </form>
    </div>
  )
}
export default ThemeChatBot

// Hiệu ứng viết chữ.
function TypingEffect({ text, speed }) {
  const [displayText, setDisplayText] = useState("")
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayText((prevText) => prevText + text[currentIndex])
        setCurrentIndex((prevIndex) => prevIndex + 1)
      } else {
        clearInterval(interval)
      }
    }, speed)

    return () => clearInterval(interval)
  }, [currentIndex, text, speed])

  // Hàm để hiển thị nội dung với các thẻ HTML
  const renderHtml = (htmlString) => {
    return <span dangerouslySetInnerHTML={{ __html: htmlString }} />
  }

  return <span>{renderHtml(displayText)}</span>
}
