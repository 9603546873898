import { useState } from "react"
import moment from "moment"
import "moment/locale/vi"
import { PiDotOutlineThin } from "react-icons/pi"

function CardListEvent({ dataPro, dataBooking }) {
  

  return (
    <div className="card custom-card rounded-4">
      <div className="card-header">
        <div className="card-title text-h">Lịch hẹn</div>
      </div>
      <div className="card-body" style={{ height: "459px", overflow: "auto" }}>
        {dataBooking.length === 0 ? "Chưa có lịch hẹn" : ""}

        <ul className="list-unstyled timeline-widget mb-0 my-3">
          {dataBooking.map((item) => {
            const filterEstate = dataPro.filter((row) => {
              return row.id === item.simpleid
            })
            
            return (
              <li className="timeline-widget-list" key={item.id}>
                <div className="d-flex align-items-top">
                  <div className="me-3 text-center">
                    <span className="d-block fs-20 fw-semibold text-indigo">
                      {moment(item.addtime).format("DD")}
                    </span>
                    <span className="d-block fs-12 text-muted text-capi">
                      {moment(item.addtime).locale("vi").format("dddd")}
                    </span>
                  </div>
                  <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between ms-3">
                    <div>
                      <span className="fw-6 text-h">
                        {item.fullname} <PiDotOutlineThin /> {item.fullphone}
                      </span>
                      <div className="mb-1 mt-1 text-truncate text-wrap">
                        {item.discription}
                        <p className="text-muted mb-1">
                          {filterEstate.length > 0 ? filterEstate[0].title : ""}
                        </p>
                      </div>
                      <p className="mb-0 fs-12 lh-1 text-muted">
                        {moment(item.addtime).format("DD/MM/YYYY HH:mm")}
                        {/* <span className="badge bg-primary-transparent ms-2">
                          Announcement
                        </span> */}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            )
          }) }
        </ul>
      </div>
    </div>
  )
}

export default CardListEvent
