// lưu ý: nếu fle có đuôi svg thêm .default
const images = {
    noImage: require("../../assets/images/default-avatar.jpeg"),
    noImagePro: require("./no-images.gif"),
    view360: require("./ad-view360.jpg"),
    bannerKM: require("./banner-km.png"),
    notifi: require("./loa.png"),
    bannerHeight: require("./test.png"),
    bannerPrView360: require("./banner-view.png") 
}

const imageLogo = {
    logo: require("./logo.png")
}

const imagePay = {
    qr: require("./qr-pay.jpg")
}

// Danh cho danh mục
const imagesCate = {
    product: require("./add-item.png"),
    calendar: require("./calendar.png"),
    customer: require("./human.png"),
    wallet: require("./wallet.png"),
    love: require("./love.png")
}

export {images, imageLogo, imagePay, imagesCate}