import { useState, useContext } from "react"
import { Nav } from "react-bootstrap"
import moment from "moment"
import classNames from "classnames/bind"

import styles from "../../Dashboard.module.scss"
import ListProduct from "./ListProduct"
import SearchSimple from "../../../../../components/Search/SearchSimple"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"

const cx = classNames.bind(styles)

function Sanpham({ data, dataBooking }) {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [activeTab, setActiveTab] = useState("home")
  const [dataEstates, setDataEstates] = useState(data)
  // Get value search.
  const handleGetValue = (valueText) => {
    if (valueText !== "") {
      const fetchData = async () => {
        const response = await fetch(
          process.env.REACT_APP_APIGetDataMarketSimple +
            `?keyword=${valueText}&phone=${dataUser[0].sodienthoai}`,
        )
        const dataRes = await response.json()
        if (dataRes.length > 0) {
          setDataEstates(dataRes)
        } else {
          alert("Không có kết quả phù hợp.")
        }
      }
      fetchData()
    } else {
      // Trả về mảng ban đầu
      setDataEstates(data)
    }
  }

  // Data không quảng cáo
  const dataNoAd = dataEstates.filter((item) => {
    return item.status === 0
  })
  // data hết hạn.
  const dataExpired = dataEstates.filter((item) => {
    let endDate = moment(item.endtime)
    let currentDate = moment()
    let diffTime = endDate.diff(currentDate, "days")
    return item.status === 1 && diffTime <= 0
  })
  // data quảng cáo.
  const dataAd = dataEstates.filter((item) => {
    let endDate = moment(item.endtime)
    let currentDate = moment()
    let diffTime = endDate.diff(currentDate, "days")
    return item.status === 1 && diffTime > 0
  })

  const handleTabSelect = (key) => {
    setActiveTab(key)
  }

  return (
    <div className="tab-style-2">
      <div className="row align-items-center">
        <Nav variant="tabs" defaultActiveKey="home" className="col-xl-8 col-12">
          <Nav.Item>
            <Nav.Link
              eventKey="home"
              onClick={() => handleTabSelect("home")}
              active={activeTab === "home"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              {/* <IoCalendarOutline className="mb-1 fs-21" /> */}
              Tất cả ({Number(data.length).toLocaleString()})
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="no-ad"
              onClick={() => handleTabSelect("no-ad")}
              active={activeTab === "no-ad"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              Không quảng cáo ({Number(dataNoAd.length).toLocaleString()})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="hethan"
              onClick={() => handleTabSelect("hethan")}
              active={activeTab === "hethan"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              Hết hạn ({Number(dataExpired.length).toLocaleString()})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="quangcao"
              onClick={() => handleTabSelect("quangcao")}
              active={activeTab === "quangcao"}
              className="d-flex flex-direction-column align-items-center fw-6 font-13"
            >
              {/* <BsCalendar2Week className="mb-1 fs-19" /> */}
              Quảng cáo ({Number(dataAd.length).toLocaleString()})
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="col-xl-4 col-12 d-flex justify-content-end">
          <SearchSimple result={handleGetValue} />
        </div>
      </div>

      {/* Nội dung của tab được hiển thị dựa trên activeTab */}
      <div className={cx("mt-3")}>
        {activeTab === "home" && (
          <div className="">
            <ListProduct data={dataEstates} dataBooking={dataBooking} />
          </div>
        )}
        {activeTab === "no-ad" && (
          <div className="">
            <ListProduct data={dataNoAd} dataBooking={dataBooking} />
          </div>
        )}
        {activeTab === "hethan" && (
          <div className="">
            <ListProduct data={dataExpired} dataBooking={dataBooking} />
          </div>
        )}
        {activeTab === "quangcao" && (
          <div className="">
            <ListProduct data={dataAd} dataBooking={dataBooking} />
          </div>
        )}
      </div>
    </div>
  )
}
export default Sanpham
