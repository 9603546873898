import React, { useState } from "react"
import classNames from "classnames/bind"
import styles from "./Date.module.scss"

import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import vi from "date-fns/locale/vi"
registerLocale("vi", vi)
const cx = classNames.bind(styles)

function DateMonthForMonth() {
  const [startDate, setStartDate] = useState(new Date("2014/02/08"))
  const [endDate, setEndDate] = useState(null)

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

    return (
        <DatePicker
            locale="vi"
            selected={startDate}
            onChange={handleChange}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            className={cx("fontType")}
        />
    );
}

export default DateMonthForMonth
