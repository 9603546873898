
import { HiOutlineHomeModern } from "react-icons/hi2"
import { PiBuildings } from "react-icons/pi"
import { BsBuildings } from "react-icons/bs"
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { LiaBedSolid } from "react-icons/lia"
import { GiIsland } from "react-icons/gi"

import { CategoryHorizontal } from "../../../components/Category"

const dataCate = [
  { id: 1, icon: <HiOutlineHomeModern />, title: "Mặt đường", path: "/" },
  { id: 2, icon: <PiBuildings />, title: "Trong ngõ", path: "/" },
  { id: 3, icon: <GiIsland />, title: "T.Định cư", path: "/" },
  { id: 4, icon: <BsBuildings />, title: "Chung cư", path: "/" },
  { id: 5, icon: <HiOutlineBuildingOffice2 />, title: "Căn hộ", path: "/" },
  { id: 6, icon: <LiaBedSolid />, title: "Phòng trọ", path: "/" },
]
function ListCategory({className, result}) {
  return <CategoryHorizontal data={dataCate} className={className} result={result} />
}

export default ListCategory