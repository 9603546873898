import io from "socket.io-client"
import { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import classNames from "classnames/bind"

import { HeaderHome2 } from "../../../components/Header"
import styles from "./detail.module.scss"
import DetailEstate from "./DetailEstate"
import Footer from "../../../components/Footer"
import LoadingSample from "../../../components/Loading/LoadingSample"
import ComponentHeader from "../../mobile/Component/Header/ComponentHeader" // dành cho mobile
import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import MenuFooter from "../../mobile/MenuFooter/MenuFooter"

const cx = classNames.bind(styles)

function DetailPage() {
  const { slug } = useParams()
  const socket = useRef()
  const [dataEstate, setDataEstate] = useState([])
  const [loading, setLoading] = useState(true)

  // Get data theo parmas
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    const stringUrl = slug.indexOf("pr")
    if (stringUrl !== -1) {
      const prId = slug.substring(stringUrl).replace(/\D/g, "")
      // Gửi yêu cầu lên server.
      socket.current.emit("client-detail-product", prId)
    }
    return () => {
      socket.current.off("client-detail-product")
    }
  }, [])

  useEffect(() => {
    socket.current.on("server-detail-product", (data) => {
      setDataEstate(data)
      setLoading(false)
    })
    return () => {
      socket.current.off("server-detail-product")
    }
  }, [])

  if (loading) {
    return <LoadingSample />
  }
  return (
    <>
      {/* thêm vào thẻ <head> */}
      <Helmet>
        <title>{dataEstate[0].title}</title>
        <meta
          name="description"
          content={
            dataEstate[0].cate +
            " " +
            dataEstate[0].address +
            ", " +
            dataEstate[0].title
          }
        />
        <meta
          name="keywords"
          content={
            dataEstate[0].cate +
            ", " +
            dataEstate[0].address +
            ", " +
            dataEstate[0].short_keywords
          }
        />
      </Helmet>
      {/* Nội dung chi tiết */}
      <div className="page">
        {/* Header */}
        <CheckPCMobile>
          {(isMobile) => (isMobile ? <ComponentHeader /> : <HeaderHome2 />)}
        </CheckPCMobile>
        {/* Main */}
        <div className={cx("container")}>
          <div className={cx("main-sidebar-1")}>
            <DetailEstate data={dataEstate} />
          </div>
        </div>
        {/* Footer */}
        <Footer />
        {/* footer mobile */}
        <CheckPCMobile>
          {(isMobile) => isMobile && <MenuFooter />}
        </CheckPCMobile>
      </div>
    </>
  )
}

export default DetailPage
