import classNames from "classnames/bind"
import { useState, useEffect, useRef, useContext } from "react"
import { Link } from "react-router-dom"
import io from "socket.io-client"
import { IoNotificationsOutline } from "react-icons/io5"

import styles from "../Mobile.module.scss"
import SearchHomeMobile from "../Search/SearchHome/SearchHomeMobile"
import ListItemRealEstate from "../../pc/Home/ListItemRealEstate"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import ListCategory from "../../pc/Home/ListCategory"
import MenuFooter from "../MenuFooter/MenuFooter"
import InfoUserMobile from "../Component/InfoUser/InfoUserMobile"
import { SlideBanner } from "../../../components/Slider"
import { images } from "../../../assets/images"

const cx = classNames.bind(styles)
const dataBanner = [
  {
    id: 1,
    title: "Không gian 3D",
    info: "View360.vn",
    info1: "Tài trợ",
    class: "bg-card",
    image: images.bannerPrView360,
    width: 250,
    path: "https://view360.com.vn",
  },
  {
    id: 2,
    title: "Chuyển đổi số",
    info: "Team Tech",
    info1: "Tài trợ",
    class: "bg-card-warning",
    image: images.bannerPrView360,
    width: 250,
    path: "#",
  },
  {
    id: 3,
    title: "Bất động sản",
    info: "muanhahaiphong.com",
    info1: "Tài trợ",
    class: "bg-card",
    image: images.bannerPrView360,
    width: 250,
    path: "https://muanhahaiphong.com",
  },
]

function LayoutMainMobile() {
  /** =========== Xử lý logic ================ */
  const { dataUser } = useContext(AppContext)
  const [dataEstate, setDataEstate] = useState([])
  const [loading, setLoading] = useState(true)
  const limit = 50
  const page = useRef(1)
  const dataCate = useRef([])
  const socket = useRef()

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
  }, [])
  // Count notification.
  const [notifiCount, setNotifiCount] = useState(0)
  useEffect(() => {
    socket.current.on("server-notification", function (data) {
      setNotifiCount(data.length)
    })
    return () => {
      socket.current.off("server-notication")
    }
  }, [])
  // Xac nhan duoc su dung tai nguyen
  const identification = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      identification: `${process.env.REACT_APP_IDENTIFICATION}`,
    },
  }

  const fetchData = async (limit_, page_) => {
    try {
      // data estate
      const response = await fetch(
        process.env.REACT_APP_APIGetDataPageHomeMarketSimpleF +
          `?quantity=${limit_}&offset=${(page_ - 1) * limit_}`,
        identification,
      )
      const dataRes = await response.json()
      const sortData = dataRes.sort((a, b) => {
        let min_ = a.admoney
        let max_ = b.admoney
        return max_ - min_
      })
      setDataEstate(sortData)
      dataCate.current = sortData
      setLoading(false)
    } catch (err) {
      console.log("Kết nối thất bại")
    }
  }
  useEffect(() => {
    fetchData(limit, page.current)
  }, [])

  // Hiển thị theo danh mục
  const handleCateShowHome = (key) => {
    let cateInput = key.toLowerCase()
    if (cateInput === "home") {
      setDataEstate(dataCate.current)
    } else {
      socket.current.emit("client-get-data-cate", cateInput)
    }
  }
  useEffect(() => {
    socket.current.on("server-get-data-cate", (data) => {
      setDataEstate(data)
    })
  }, [])

  // Modal filter.
  // const [modalFilter, setModalFilter] = useState(false)
  // const handleShowModalFilter = () => {
  //   setModalFilter(true)
  // }
  //   const handleCloseModalFilter = () => setModalFilter(false)

  // Xử lý reload lại trang khi cuộn quá top
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if (scrollTop < 0) {
      // Xử lý khi người dùng kéo cuộn lên quá top của trang
      window.location.reload() // Làm mới trang
      // console.log("Làm mới")
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  /** =========== * Xử lý logic ================ */

  return (
    <div className={cx("mobile_wrapper")}>
      {/* Header */}
      <div
        className={cx(
          "d-flex align-items-center justify-content-between mb-3",
          "headerMobile",
        )}
      >
        <div>
          <span className={cx("nameLogo")}>View360</span>
          {/* <img src={imageLogo.logo} alt="Logo" className={cx("logoMobile")} /> */}
        </div>
        <div
          className={cx("col-6 d-flex justify-content-end align-items-center")}
        >
          <Link to="/notification" className={cx("me-3 position-relative")}>
            <IoNotificationsOutline className="fs-29" />
            <span className={cx("countNotifiMobi")}>{notifiCount}</span>
          </Link>
          <InfoUserMobile height={35} width={35} />
        </div>
      </div>

      {/* Search */}
      <div className={cx("col-12", "wrapper_searchHome")}>
        <SearchHomeMobile />
      </div>

      {/* List items */}
      <div className={cx("mt-0")} style={{ marginBottom: "86px" }}>
        <div className={cx("listCateHomeMobile", "mb-3")}>
          <ListCategory result={handleCateShowHome} />
          {/* Slider banner */}
          <div className="mt-3">
            <SlideBanner data={dataBanner} />
          </div>
        </div>
        <ListItemRealEstate dataEstate={dataEstate} />
      </div>

      {/* MenuFooter */}
      <MenuFooter />
    </div>
  )
}
export default LayoutMainMobile
