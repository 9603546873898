import classNames from "classnames/bind";
import styles from "./Layout.module.scss"

const cx = classNames.bind(styles)

function LayoutMain({children}) {
    return (
      <div className={cx("container")}>
        <div className={cx("col-xl-12")}>
          {children}
        </div>
      </div>
    )
}

export default LayoutMain;