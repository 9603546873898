import { useContext, useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import io from "socket.io-client"
import moment from "moment"
import classNames from "classnames/bind"
import { MdNavigateNext } from "react-icons/md"
import { Dropdown } from "react-bootstrap"

import styles from "../Mobile.module.scss"
import { images } from "../../../assets/images"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import { LoadingSpinner } from "../../../components/Loading"
import ModalReact from "../../../components/Modal/ModalReact"
import DetailProduct from "../../pc/Dashboard/Component/Sanpham/DetailProduct"

const cx = classNames.bind(styles)

function PageCalendar() {
  const { dataUser } = useContext(AppContext)
  const [dataCate, setDataCate] = useState([])
  const socket = useRef()
  const [loading, setLoading] = useState(true)
  const [activeCate, setActiveCate] = useState("btn-warning")
  const [countBooking, setCountBooking] = useState({
    pending: 0,
    loading: 0,
    success: 0,
  })
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    // Gọi data ban đầu
    socket.current.emit("client-cate-status", {
      author_simple: dataUser[0].id,
      status: 0,
    })
    // Cate status.
    socket.current.emit("client-count-status", dataUser[0].id)
  }, [])

  const handleCateStatus = (key, active) => {
    socket.current.emit("client-cate-status", {
      author_simple: dataUser[0].id,
      status: key,
    })
    setActiveCate(active)
  }
  // Count cate.

  useEffect(() => {
    socket.current.on("server-cate-status", (data) => {
      setDataCate(data)
      setLoading(false)
    })
    // Data cate.
    socket.current.on("server-count-status", (data) => {
      let pending_ = data.filter((item) => item.status === 0)
      let loading_ = data.filter((item) => item.status === 1)
      let success_ = data.filter((item) => item.status === 2)
      setCountBooking({
        pending: pending_.length > 0 ? pending_[0].count : 0,
        loading: loading_.length > 0 ? loading_[0].count : 0,
        success: success_.length > 0 ? success_[0].count : 0,
      })
    })
  }, [])

  // Xử lý update status.
  const handleUpdateStatus = (key) => {
    if (key) {
      // Gọi data ban đầu
      socket.current.emit("client-cate-status", {
        author_simple: dataUser[0].id,
        status: 0,
      })
      // Cate status.
      socket.current.emit("client-count-status", dataUser[0].id)
      setActiveCate("btn-warning")
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <div className={cx("wrapper_dash")}>
      {/* Search */}
      {/* <div className="col-12 d-flex justify-content-end">
          <SearchSimple />
        </div> */}
      {/* Danh mục */}
      <div className={cx("cateHorizontal")}>
        <ul className={cx("d-flex column-gap-9")} style={{ width: "400px" }}>
          <li className={cx("me-3")}>
            <button
              className={cx(
                "btn btn-wave",
                activeCate === "btn-warning" ? activeCate : "btn-light",
              )}
              onClick={() => handleCateStatus(0, "btn-warning")}
            >
              Chờ xử lý ({countBooking.pending})
            </button>
          </li>
          <li className={cx("me-3")}>
            <button
              className={cx(
                "btn btn-wave",
                activeCate === "btn-info" ? activeCate : "btn-light",
              )}
              onClick={() => handleCateStatus(1, "btn-info")}
            >
              Đang xử lý ({countBooking.loading})
            </button>
          </li>
          <li className={cx("me-3")}>
            <button
              className={cx(
                "btn btn-wave",
                activeCate === "btn-success" ? activeCate : "btn-light",
              )}
              onClick={() => handleCateStatus(2, "btn-success")}
            >
              Đã xử lý ({countBooking.success})
            </button>
          </li>
        </ul>
      </div>
      {/* Danh sách yêu cầu lịch hẹn */}
      <BookingList
        dataBooking={dataCate}
        resultUpdateStatus={handleUpdateStatus}
      />
    </div>
  )
}
export default PageCalendar

// Danh sách yêu cầu.
function BookingList({ dataBooking, resultUpdateStatus }) {
  // modal booking.
  const [showModal, setShowModal] = useState(false)
  const [dataDetail, setDataDetail] = useState([])
  const handleShowModal = (id_) => {
    setShowModal(true)
    const dataDetail_ = dataBooking.filter((item) => {
      return item.id === id_
    })
    setDataDetail(dataDetail_)
  }
  const handleCloseModal = () => setShowModal(false)

  // Cập nhật status booking.
  const updateStatus = (key) => {
    resultUpdateStatus(key)
  }

  if (dataBooking.length === 0) {
    return <div className="text-center mt-5">Chưa có dữ liệu</div>
  }

  return (
    <div className={cx("mt-5")} id="wrapper_container_booking">
      <ul>
        {dataBooking.map((item) => {
          return (
            <li
              key={item.id}
              className={cx("mb-3")}
              onClick={() => handleShowModal(item.id)}
            >
              <div className={cx("d-flex", "itemBooking")}>
                <span className={cx("avatar avatar-xl col-3")}>
                  <img
                    src={item.avatar ? item.avatar : images.noImage}
                    alt=""
                    className={cx("rounded-5")}
                  />
                </span>

                <div
                  className={cx(
                    "ms-2 fs-15 d-flex justify-content-between col-9",
                  )}
                >
                  <div>
                    <span className={cx("text-primary")}>{item.fullname}</span>
                    <div className={cx("fs-14")}>{item.discription}</div>
                    <div className={cx("fs-14")}>
                      <div className={cx("fs-12 text-muted")}>
                        Gửi {moment(item.addtime).format("DD/MM/YYYY")} lúc{" "}
                        {moment(item.addtime).format("HH:mm:ss")}
                      </div>
                    </div>
                  </div>
                  <button className={cx("border-0 rounded-3", "detailBooking")}>
                    <MdNavigateNext />
                  </button>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      {/* {isLoading ? (
          <div className={cx("text-center mb-5")}>Bạn đã xem hết rồi.</div>
        ) : (
          <LoadingSpinner />
        )} */}
      {/* Modal detail booking */}
      {showModal && (
        <ModalReact
          modalTitle="Yêu cầu từ khách hàng"
          showModal={showModal}
          handleClose={handleCloseModal}
          theme={
            <DetailInfoBooking data={dataDetail} updateStatus={updateStatus} />
          }
        />
      )}
    </div>
  )
}

// Thông tin chi tiết yêu cầu booking.
function DetailInfoBooking({ data, updateStatus }) {
  const socket = useRef()
  const [dataEstate, setDataEstate] = useState([])
  const [dataImages, setDataImages] = useState([])
  const [update, setUpdate] = useState(false)
  const dataInfo = data.find((item) => {
    return item
  })
  // Khởi tạo.
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    socket.current.emit("client-detail-product", dataInfo.simpleid)
  }, [])

  useEffect(() => {
    socket.current.on("server-detail-product", (data) => {
      setDataEstate(data)
      let listImages = []
      if (data[0].images) {
        listImages = data[0].images.split(",")
      }
      setDataImages(listImages)
    })
  }, [])

  // Chuyển đổi trạng thái.
  const handleStatusBooking = (key, id_) => {
    socket.current.emit("client-edit-status", { status: key, id: id_ })
    dataInfo.status = key
  }
  useEffect(() => {
    socket.current.on("server-edit-status", (data) => {
      if (data === "success") {
        updateStatus(true)
        setUpdate(true)
      }
    })
  }, [])

  return (
    <div>
      <div className={cx("d-flex align-items-center")}>
        <img
          src={dataInfo.avatar ? dataInfo.avatar : images.noImage}
          width={60}
          height={60}
          alt=""
        />
        <div className={cx("ms-2")}>
          <h6>{dataInfo.fullname}</h6>
          <p className="mb-0">{dataInfo.email}</p>
        </div>
      </div>
      {/* Option */}
      <div className="d-flex justify-content-center">
        <a
          href={"tel: " + dataInfo.fullphone}
          className={cx("badge bg-dark-transparent fs-15 fw-4 me-3")}
        >
          {/* <LuPhoneCall className="me-2" /> */}
          {dataInfo.fullphone}
        </a>
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-line border-0 p-0" // class mặc định
            id="dropdown-basic"
            bsPrefix="custom-dropdown-toggle"
          >
            {dataInfo.status === 0 ? (
              <span className={cx("badge bg-warning fs-15 fw-4")}>
                Chờ xử lý
              </span>
            ) : dataInfo.status === 1 ? (
              <span className="badge bg-info fs-15 fw-4">Đang xử lý</span>
            ) : (
              <span className="badge bg-success fs-15 fw-4">Đã xử lý</span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link
              to="#"
              className="dropdown-item"
              onClick={() => handleStatusBooking(0, dataInfo.id)}
            >
              Chờ xử lý
            </Link>
            <Link
              to="#"
              className="dropdown-item"
              onClick={() => handleStatusBooking(1, dataInfo.id)}
            >
              Đang xử lý
            </Link>
            <Link
              to="#"
              className="dropdown-item"
              onClick={() => handleStatusBooking(2, dataInfo.id)}
            >
              Đã xử lý
            </Link>
          </Dropdown.Menu>
        </Dropdown>
        <button className="border-0 bg-transparent"></button>
      </div>
      {/* Nội dung người gửi */}
      <div className="mt-4">
        <h6 className="text-center mb-3">{dataInfo.discription}</h6>
        <div>
          {dataEstate.length === 0 && <LoadingSpinner />}
          {dataEstate.length > 0 && (
            <DetailProduct data={dataEstate} dataImages={dataImages} />
          )}
        </div>
      </div>
    </div>
  )
}
