import CheckPCMobile from "../../../handle-reuses/reuses/check.pc.mobile"
import PageSearchPC from "./PageSearchPC"
import PageSearchMobile from "../../mobile/PageSeach"

function PageSearch() {
  return (
    <CheckPCMobile>
      {(isMobile) => (isMobile ? <PageSearchMobile /> : <PageSearchPC />)}
    </CheckPCMobile>
  )
}
export default PageSearch
