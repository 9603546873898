import { HeaderSample } from "../../../components/Header"
import LayoutMain from "../../../components/Layout"
import { FooterSimple } from "../../../components/Footer"
import LayoutPayment from "../Payment_/LayoutPayment"
import {sidebarSimple, SidebarSimple } from "../../../components/Sidebar";



function PaymentPage() {
  
  return (
    <>
      {/* Header */}
      <HeaderSample />
      {/* Main */}
      <LayoutMain>
        <div className="row">
          <div className="col-3 px-3">
            <SidebarSimple data={sidebarSimple} />
          </div>
          <div className="col-9">
            <LayoutPayment />
          </div>
        </div>
      </LayoutMain>
      {/* Footer */}
      <FooterSimple />
    </>
  )
}

export default PaymentPage
