import { useEffect, useState, memo, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./Payment.module.scss"
import {
  HiOutlineBookmarkSquare,
  HiOutlineEnvelope,
  HiOutlineEnvelopeOpen,
  HiOutlineChartPie,
  HiOutlineTicket,
} from "react-icons/hi2"

const cx = classNames.bind(styles);

function PaymentMenu({resultData, status}) {
  const [active, setActive] = useState("all")
  const [data, setData] = useState([]);
  // get data.
  const fetchData = async () => {
    const response = await fetch(process.env.REACT_APP_APIGetUserPayHis + `?statusonly=${0}`)
    const dataRes = await response.json();
    setData(dataRes)
  }
  useEffect(() => {
    fetchData()
  }, [status])

  const handleActive = (item) => {
    setActive(item)
    // Trả về LayoutPayment
    resultData(item)
  }

  return (
    <div className={cx("d-flex")}>
      <div
        className={cx(
          "d-flex flex-direction-column align-items-center me-5 gap-x",
          "iconCate",
          `${active === "all" ? "active" : ""}`,
        )}
        onClick={() => handleActive("all")}
      >
        <div>
          <HiOutlineBookmarkSquare className={cx("fs-26")} />
        </div>
        <div className={cx("fw-4")}>Tất cả</div>
      </div>
      <div
        className={cx(
          "d-flex flex-direction-column align-items-center me-5 gap-x",
          "iconCate",
          `${active === "pending" ? "active" : ""}`,
        )}
        onClick={() => handleActive("pending")}
      >
        <div className="position-relative">
          <HiOutlineEnvelope className={cx("fs-26")} />
          <div className={cx("position-absolute", "notifi")}>
            {data.length > 0 ? data.length : ""}
          </div>
        </div>
        <div className={cx("fw-4")}>Chờ xử lý</div>
      </div>
      <div
        className={cx(
          "d-flex flex-direction-column align-items-center me-5 gap-x",
          "iconCate",
          `${active === "success" ? "active" : ""}`,
        )}
        onClick={() => handleActive("success")}
      >
        <div>
          <HiOutlineEnvelopeOpen className={cx("fs-26")} />
        </div>
        <div className={cx("fw-4")}>Đã duyệt</div>
      </div>
      <div
        className={cx(
          "d-flex flex-direction-column align-items-center me-5 gap-x",
          "iconCate",
          `${active === "statistical" ? "active" : ""}`,
        )}
        onClick={() => handleActive("statistical")}
      >
        <div>
          <HiOutlineChartPie className={cx("fs-26")} />
        </div>
        <div className={cx("fw-4")}>Thống kê</div>
      </div>
      <div
        className={cx(
          "d-flex flex-direction-column align-items-center me-5 gap-x",
          "iconCate",
          `${active === "discount" ? "active" : ""}`,
        )}
        onClick={() => handleActive("discount")}
      >
        <div>
          <HiOutlineTicket className={cx("fs-26")} />
        </div>
        <div className={cx("fw-4")}>Khuyến mại</div>
      </div>
    </div>
  )
}

export default memo(PaymentMenu);