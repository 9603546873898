import { useState, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import { Modal} from "react-bootstrap"

function ModalReact({ size, modalTitle, theme, showModal, handleClose }) {
    const [sizeModal, setSizeModal] = useState("");
    const [themeModal, setThemeModal] = useState("")

    useEffect(() => {
        if (size) {
            setSizeModal(size)
        } else {
            setSizeModal("")
        }
    }, [sizeModal])
    


  useEffect(() => {
    if (theme) {
      setThemeModal(theme)
    }
  }, [])

  
    return (
      <Modal show={showModal} onHide={handleClose} className={size}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle ? <span className="fw-4 fs-18">{modalTitle}</span> : "Tên modal"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{themeModal}</Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Lưu thay đổi
          </Button>
        </Modal.Footer> */}
      </Modal>
    )
}

export default ModalReact;

// Cách dùng.
/**
 * 1. tạo useState để bật tắt (mặc định là false).
 * 2. tạo hàm xử lý bật => state trả về true.
 * 3. tạo hàm xử lý tắt => state trả về false
 */