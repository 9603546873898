import { useState, useEffect, useRef, memo } from "react";
import ListPay from "./ListPay";
import { LoadingSpinner } from "../../../components/Loading";

function ListPayPending({status}) {
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(true)
    const page = useRef(1);
    const limit =20;
    const status_ = 0
    // Get data.
    const fetchData = async (page_) => {
        try {
            const api =
              process.env.REACT_APP_APIGetUserPayHis +
              `?quantity=${limit}&offset=${
                (page_ - 1) * limit
                }&statusonly=${status_}`
            
            const response = await fetch(api)
            const dataRes = await response.json();
            setData((old) => [...old, ...dataRes])
            setLoading(false)
            if (dataRes.length === 0) {
                setHasMore(false)
            }

        } catch (error) {
            
        }
    }
    // Hiển thị ban đầu.
    useEffect(() => {
      setTimeout(() => {
        fetchData(page.current)
      }, 500);
    }, [])

    // Xử lý load more
    const handleScroll = () => {
        const container = document.getElementById("listPayPending")
        const totalHeight = container.scrollHeight;
        const setHeight = container.offsetHeight;
        const scrollY = container.scrollTop;
        if (setHeight + scrollY + 1 >= totalHeight && hasMore) {
            page.current = page.current + 1;
            setTimeout(() => {
                fetchData(page.current)
            }, 500);
        }
    }

    useEffect(() => {
        const container = document.getElementById("listPayPending");
        if (container) {
            container.addEventListener("scroll", handleScroll)
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll)
            }
        }
    }, [page.current])


    if (loading) {
        return <LoadingSpinner />
    }
    return ( 
        <ListPay data={data} hasMore={hasMore} id="listPayPending" status={ status} />
     );
}

export default memo(ListPayPending)