import { useState, useEffect, useRef } from "react";
import classNames from "classnames/bind"
import { IoNotificationsOutline } from "react-icons/io5"
import { Link } from "react-router-dom";
import io from "socket.io-client"

import styles from "../../Mobile.module.scss"
import SearchHomeMobile from "../../Search/SearchHome/SearchHomeMobile";
import InfoUserMobile from "../InfoUser/InfoUserMobile";

const cx = classNames.bind(styles);

function ComponentHeader() {
  const [notifiCount, setNotifiCount] = useState(0)
  const socket = useRef()
  // Khởi tạo socket.
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
  }, [])
  useEffect(() => {
    socket.current.on("server-notification", function (data) {
      setNotifiCount(data.length)
    })
    return () => {
      socket.current.off("server-notication")
    }
  }, [])

    return (
      <div className={cx("Wrapper_HeaderMobile")}>
        <div
          className={cx(
            "d-flex align-items-center justify-content-between mb-2",
            "headerMobile",
          )}
        >
          <div>
            <span className={cx("nameLogo")}>View360</span>
            {/* <img src={imageLogo.logo} alt="Logo" className={cx("logoMobile")} /> */}
          </div>
          <div
            className={cx(
              "col-6 d-flex justify-content-end align-items-center",
            )}
            style={{ columnGap: "18px" }}
          >
            <Link to="/notification" className={cx("position-relative")}>
              <IoNotificationsOutline className="fs-29" />{" "}
              <span className={cx("countNotifiMobi")}>{notifiCount}</span>
            </Link>
            <InfoUserMobile height={30} width={30} />
          </div>
        </div>
        {/* Search */}
        <SearchHomeMobile />
      </div>
    )
}

export default ComponentHeader
