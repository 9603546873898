import HeaderA from "./HeaderA";
import HeaderDefault from "./HeaderDefault";
import HeaderMain from "./HeaderMain";
import HeaderHome2 from "./HeaderHome2";

function HeaderHome({id}) {
    return ( 
      <HeaderA id={id} />
     );
}



function HeaderSample() {
  return ( <HeaderDefault /> );
}


export {HeaderMain, HeaderSample, HeaderHome2 }
export default HeaderHome