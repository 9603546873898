import {
  HiOutlineHomeModern, // trang chủ
  HiOutlineUserGroup, // tài khoản
  HiOutlineExclamationCircle, // phiên bản
  HiOutlineArrowTopRightOnSquare, // đường dẫn (liên kết)
  HiOutlineCreditCard, // payment
  HiOutlineFolderOpen, // project (folder)
} from "react-icons/hi2"


/** ====== Sidebar simple ======*/
export const sidebarSimple = [
  { icon: <HiOutlineHomeModern />, name: "Trang chủ", path: "/" },
  { icon: <HiOutlineUserGroup />, name: "Tài khoản", path: "/manager-user" },
  {
    icon: <HiOutlineArrowTopRightOnSquare />,
    name: "Quản lý đường dẫn",
    path: "/manager-path",
  },
  {
    icon: <HiOutlineCreditCard />,
    name: "Thanh toán",
    path: "/manager-payment",
  },
  {
    icon: <HiOutlineFolderOpen />,
    name: "Quản lý dự án",
    path: "/manager-project",
  },
  {
    icon: <HiOutlineExclamationCircle />,
    name: "Phiên bản",
    path: "/manager-version",
  },
]


/** ======= data menu real estate ======== */
export const dataMenuRealEstate = [
  { icon: <HiOutlineHomeModern />, name: "Trang chủ", path: "/" },
  { icon: <HiOutlineUserGroup />, name: "Tài khoản", path: "/manager-user" },
  {
    icon: <HiOutlineArrowTopRightOnSquare />,
    name: "Quản lý đường dẫn",
    path: "/manager-path",
  },
  {
    icon: <HiOutlineCreditCard />,
    name: "Thanh toán",
    path: "/manager-payment",
  },
  {
    icon: <HiOutlineFolderOpen />,
    name: "Quản lý dự án",
    path: "/manager-project",
  },
  {
    icon: <HiOutlineExclamationCircle />,
    name: "Phiên bản",
    path: "/manager-version",
  },
]