
import { useState, useEffect, useRef, memo } from "react";
import ListPay from "./ListPay";
import { LoadingSpinner } from "../../../components/Loading";

function ListPaySuccess() {
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(true)
    const page = useRef(1);
    const limit = 20;
    const [loading, setLoading] = useState(true)

    // Get data.
    const fetchData = async (page_) => {
        const api = process.env.REACT_APP_APIGetUserPayHis + `?quantity=${limit}&offset=${(page_ - 1) * limit}&statusonly=${1}`;
        const response = await fetch(api);
        const dataRes = await response.json();
        setData((old) => [...old, ...dataRes])
        setLoading(false)
        if (dataRes.length === 0) {
            setHasMore(false)
        }
    }
    useEffect(() => {
        setTimeout(() => {
            fetchData(page.current)
        }, 500);
    }, [])
    // Xử lý load more.
    const handleScroll = () => {
        const container = document.getElementById("listPaySuccess");
        const totalHeight = container.scrollHeight;
        const setHeight = container.offsetHeight;
        const scrollY = container.scrollTop;
        if (setHeight + scrollY + 1 >= totalHeight && hasMore) {
            setTimeout(() => {
                page.current = page.current + 1;
                fetchData(page.current)
            }, 500);
        }
    }
    useEffect(() => {
        const container = document.getElementById("listPaySuccess");
        if (container) {
            container.addEventListener("scroll", handleScroll)
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll)
            }
        }
    }, [page.current])

    if (loading) {
        return <LoadingSpinner />
    }


    return ( 
        <ListPay data={data} hasMore={hasMore} id="listPaySuccess" />
     );
}

export default memo(ListPaySuccess);