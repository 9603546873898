import { useState, useEffect, useRef} from "react"
import ListStatistical from "./ListStatistical"
import { handleLimitTitle } from "../../../../handle-reuses/reuses"
import { LoadingSpinner } from "../../../../components/Loading"

function StatisticalSearch({ keyword }) {
  const [data, setData] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(true)
  const page = useRef(1)
  const limit = 20
  const phone = localStorage.getItem("sodienthoai")

  const fetchData = async (page_) => {
    const response = await fetch(
      process.env.REACT_APP_APIGetDataMarketSimple +
        `?limit=${limit}&offset=${
          (page_ - 1) * limit
        }&phone=${phone}&keyword=${keyword}`,
    )
    const dataRes = await response.json()
    setLoading(false)
    setData(dataRes)
    if (dataRes.length === 0) {
      setHasMore(false)
    }
  }
  // get data
  useEffect(() => {
    if (keyword !== "") {
      setLoading(true)
      setTimeout(() => {
        page.current = 1 // gán lại về giá trị ban đầu cho mỗi lần tìm kiếm mới
        fetchData(page.current)
      }, 500)
    }
  }, [keyword])

  // Xửu lý load more.
  const handleScroll = () => {
    const container = document.getElementById("listStatistical")
    const totalHeight = container.scrollHeight
    const setHeight = container.offsetHeight
    const scrollY = container.scrollTop
    if (setHeight + scrollY + 1 >= totalHeight && hasMore) {
      setTimeout(() => {
        page.current = page.current + 1
        fetchData(page.current)
      }, 500)
    }
  }

  useEffect(() => {
    const container = document.getElementById("listStatistical")
    if (container) {
      container.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll)
      }
    }
  }, [page.current])

  // Xử lý logic
  useEffect(() => {
    // Giới hạn tiêu đề
    handleLimitTitle("titleStatistical", 36)
  }, [data])

  console.log(data)
  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      {data.length > 0 ? (
        <ListStatistical data={data} />
      ) : (
        <div>Không có kết quả phù hợp.</div>
      )}
    </>
  )
}

export default StatisticalSearch
