import HomePage from "../pages/pc/Home"
import DashboardPage from "../pages/pc/Dashboard/DashboardPage"
import SanphamPage from "../pages/pc/Dashboard/SanphamPage"
import LichhenPage from "../pages/pc/Dashboard/LichhenPage"
import HopdongPage from "../pages/pc/Dashboard/HopdongPage"
import KhachhangPage from "../pages/pc/Dashboard/KhachhangPage"
import DetailPage from "../pages/pc/Detail"
import LoginPage from "../pages/pc/Login"
import SettingPage from "../pages/pc/Setting"
import PageSearch from "../pages/pc/Search"
import NotificationMobile from "../pages/mobile/Notification/NotificationMobile"
import PageProductLike from "../pages/mobile/PageProductLike"

import ListUserPage from "../pages/pc/Setting/PageListUser"
import ListManagerPath from "../pages/pc/Setting/PageListPath"
import PaymentPage from "../pages/pc/Setting/PageListPayment"

// Xác đinh user đăng nhập 1 lần nào chưa (ký tự !! trả về true nếu sodienthoai tồn tại)
const checkLocal = localStorage.getItem("object")
let checkUserLogin
if (checkLocal) {
  checkUserLogin = JSON.parse(localStorage.getItem("object"))
}
const phone = checkUserLogin ? checkUserLogin.p : ""
const isAuthen = !!phone

// public routes (Chứa đường dẫn công khai)
const publicRoutes = [
  { path: "/", component: HomePage },
  { path: "/:slug/:slug", component: DetailPage },
  { path: "/login", component: LoginPage },
  { path: "/:slug", component: PageSearch },
  { path: "/notification", component: NotificationMobile },
]

// Private routes (Chứa đường dẫn riêng tư)
const privateRoutes = [
  { path: "/dashboard", component: DashboardPage },
  { path: "/san-pham", component: SanphamPage },
  { path: "/lich-hen", component: LichhenPage },
  { path: "/khach-hang", component: KhachhangPage },
  { path: "/hop-dong", component: HopdongPage },
  { path: "product-like", component: PageProductLike },
]

// Private router 2 (Chứa đường dẫn chỉ nhân viên được xem)
const privateRoutesPersonnal = [
  { path: "/dashboard-data", component: HomePage },
  { path: "/dashboard-job", component: HomePage },
]

// Private router 3 (Chứa đường dẫn chỉ admin được xem)
const privateRoutesAdmin = [
  { path: "/dashboard-data", component: HomePage },
  { path: "/dashboard-job", component: HomePage },
  { path: "/setting", component: SettingPage },
  { path: "/manager-user", component: ListUserPage },
  { path: "/manager-path", component: ListManagerPath },
  { path: "/manager-payment", component: PaymentPage },
]
export {
  isAuthen,
  publicRoutes,
  privateRoutes,
  privateRoutesPersonnal,
  privateRoutesAdmin,
}
