import { useState} from "react"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"
import styles from "./Header.module.scss"
import ButtonPost from "../Button/ButtonPost"
import InfoUser from "../Info/InfoUser"
import { SupportMain } from "../../pages/pc/Component/Support"

const cx = classNames.bind(styles)

function HeaderMain() {
  const [styleEvent, setStyleEvent] = useState()

  const handleScroll = () => {
    const offset = window.scrollY
    // console.log(offset)
    if (offset >= 10) {
      setStyleEvent("appSidebar")
    }
  }
  window.addEventListener("scroll", handleScroll)

  return (
    <header className={cx("wrapper")}>
      <div
        className={cx(
          "content",
          "d-flex",
          "align-items-center",
          "justify-content-between",
          styleEvent,
        )}
      >
        {/* Logo */}
        <div className={cx("col-xl-2")}>
          <Link to="/">
            <img
              src="https://muanhahaiphong.com/uploads/logo_2_mau.png"
              className={cx("w_168")}
            />
          </Link>
        </div>
        {/* Info */}
        <div className={cx("col-xl-7", "d-flex", "justify-content-center")}>
          <SupportMain />
        </div>
        {/* Post & Login */}
        <div
          className={cx(
            "col-xl-3 d-flex align-items-center justify-content-end",
          )}
        >
          <div>
            <ButtonPost />
          </div>
          <div>
            <InfoUser />
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderMain
