import classNames from "classnames/bind"
import { RiMoneyDollarCircleLine } from "react-icons/ri"
import { GoDotFill } from "react-icons/go"
import { useState, useContext, useEffect, useRef } from "react"
import moment from "moment"
import { useForm } from "react-hook-form"

import styles from "./Wallet.module.scss"
import { imagePay } from "../../../../assets/images"
import { handleFormatPriceInput } from "../../../../handle-reuses/reuses"
import AppContext from "../../../../handle-reuses/Contexts/CreateContexts"
import { LoadingSpinner } from "../../../../components/Loading"
import { handleAddPayment } from "./Method/Method"
import { AlertSuccess } from "../../../../components/Alert"

const cx = classNames.bind(styles)

function WalletMain() {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [activeTab, setActiveTab] = useState("history")
  const [dataPay, setDataPay] = useState([])
  const [loading, setLoading] = useState(true)
  const limit = 5
  const page = useRef(1)

  const fetchData = async (page_) => {
    const response = await fetch(
      process.env.REACT_APP_APIGetUserPayHis +
        `?only=${dataUser[0].id}&quantity=${limit}&offset=${
          (page_ - 1) * limit
        }`,
    )
    const dataRes = await response.json()
    setDataPay(dataRes)
    setLoading(false)
  }
  useEffect(() => {
    setTimeout(() => {
      fetchData(page.current)
    }, 500)
  }, [activeTab])

  const handleActiveTab = (key) => {
    setActiveTab(key)
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className={cx("container", "p-0 fs-15")}>
      {/* account balance */}
      <div className={cx("bg-wallet")}>
        <div className={cx("accountBalance", "d-flex justify-content-between")}>
          <div>
            <div className="d-flex align-items-center">
              <RiMoneyDollarCircleLine className={cx("me-1 fs-18")} />
              Số dư hiện tại
            </div>
            <div className="fs-18 fw-6 mt-1">
              {dataPay.length > 0
                ? Number(dataPay[0].total_balance)
                    .toLocaleString()
                    .replace(/[.]/g, ",")
                : 0}
              đ
            </div>
          </div>
          {/* <div>Tỷ suất 15%</div> */}
        </div>
        <div
          className={cx("mb-3 mt-3 border-block-start-dashed", "border-top-w")}
        ></div>
        <div className="d-flex gap-1 justify-content-between">
          <div>
            <div className="text-muted">Đã nạp</div>
            <div>
              {dataPay.length > 0
                ? Number(dataPay[0].total_deposit)
                    .toLocaleString()
                    .replace(/[.]/g, ",")
                : 0}
              đ
            </div>
          </div>
          <div>
            <div className="text-muted">Đã chi</div>
            <div>
              {dataPay.length > 0
                ? (
                    Number(dataPay[0].total_deposit) -
                    Number(dataPay[0].total_balance)
                  )
                    .toLocaleString()
                    .replace(/[.]/g, ",")
                : 0}
              đ
            </div>
          </div>
          <div>
            <div className="text-muted">Khuyến mãi</div>
            <div>0%</div>
          </div>
        </div>
      </div>
      {/* Danh mục */}
      <div className={cx("d-flex justify-content-start mt-3")}>
        <button
          className={cx(
            "border-0 rounded-3 p-2 px-3 me-2 badge fw-4 fs-14",
            activeTab === "history" ? "bg-success" : "bg-light text-dark",
          )}
          onClick={() => handleActiveTab("history")}
        >
          Lịch sử
        </button>
        <button
          className={cx(
            "border-0 rounded-3 p-2 px-3 me-2 badge fw-4 fs-14",
            activeTab === "payment" ? "bg-success" : "bg-light text-dark",
          )}
          onClick={() => handleActiveTab("payment")}
        >
          Nạp tiền
        </button>
        <button
          className={cx(
            "border-0 rounded-3 p-2 px-3 me-2 badge fw-4 fs-14",
            activeTab === "linkService" ? "bg-success" : "bg-light text-dark",
          )}
          onClick={() => handleActiveTab("linkService")}
        >
          Dịch vụ liên kết
        </button>
      </div>
      {/* Danh sách */}
      <div className={cx("mt-3", "card-body")}>
        {activeTab === "history" && <ComponentHistory data={dataPay} />}
        {activeTab === "payment" && <ComponentPayment dataUser={dataUser} />}
        {activeTab === "linkService" && <ComponentLinkService />}
      </div>
    </div>
  )
}

export default WalletMain

// Component history.
function ComponentHistory({ data }) {
  if (data.length === 0) {
    return <div className="text-center">Chưa có giao dịch</div>
  }

  return (
    <ul
      className="list-unstyled mb-0 crm-recent-activity"
      style={{ maxHeight: "321px", overflow: "auto" }}
    >
      {data.map((item) => {
        return (
          <li className="crm-recent-activity-content" key={item.id_his}>
            <div className="d-flex align-items-top">
              <div className="me-3">
                <span className="avatar avatar-xs bg-warning-transparent avatar-rounded">
                  <GoDotFill className="fs-18 op-8" />
                </span>
              </div>
              <div className="crm-timeline-content">
                <span>
                  {/* <span className="fw-6">{item.hoten}</span>
                  {", "} */}
                  {item.status === 1 ? (
                    <span className="text-success">
                      Khoản thanh toán thành công.
                    </span>
                  ) : (
                    <span className="text-warning">
                      Khoản thanh toán đang chờ xử lý
                    </span>
                  )}
                </span>
                <div>
                  <span>
                    <span className="me-2">Số tiền:</span>
                    {item.status === 1 ? (
                      <span className="text-success">
                        +
                        {Number(item.deposit)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ
                      </span>
                    ) : (
                      <span className="text-muted">
                        +
                        {Number(item.deposit)
                          .toLocaleString()
                          .replace(/[.]/g, ",")}
                        đ
                      </span>
                    )}
                  </span>
                </div>
                <div className="text-muted">
                  {moment(item.addtime).locale("vi").startOf().fromNow()}
                </div>
              </div>
              <div className="flex-fill text-end">
                <span className="d-block text-muted op-7">
                  {moment(item.addtime).format("DD-MM-YYYY HH:mm:ss")}
                </span>
                <div>
                  <span className="me-2">Số dư:</span>{" "}
                  {Number(item.total_balance)
                    .toLocaleString()
                    .replace(/[.]/g, ",")}
                  đ
                </div>
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

// Component payment.
function ComponentPayment({ dataUser }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [payment, setPayment] = useState("")
  const [checkSuccess, setCheckSuccess] = useState(false)

  const handleGetValueDeposit = (e) => {
    const formatPrice = handleFormatPriceInput(e.target.value)
    setPayment(formatPrice)
  }

  // Check add data success.
  const handleCheckAddDataSuccess = () => {
    setCheckSuccess(true)
  }

  const onSubmit = (data) => {
    data.userid = dataUser[0].id
    handleAddPayment(data, reset, handleCheckAddDataSuccess)
  }

  if (checkSuccess) {
    return <AlertSuccess />
  }

  return (
    <div className={cx("col-12")}>
      <div className="col-xl-12 col-md-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-xl-12 col-md-12">
            <div className="form-floating mb-1 mt-2">
              <input
                type="text"
                value={payment}
                {...register("deposit", { required: true })}
                className="form-control"
                placeholder="Số tiền thanh toán"
                onChange={handleGetValueDeposit}
              />
              <label>
                Số tiền thanh toán <sup className="text-danger">*</sup>
              </label>
              {errors.deposit && (
                <span className={cx("text-danger", "fs-12")}>
                  *Bạn chưa nhập số tiền thanh toán
                </span>
              )}
            </div>
          </div>
          <div className="col-xl-12 col-md-12">
            {payment.replace(/[., ]/g, "") >= 50000 ? (
              <img src={imagePay.qr} alt="" width="100%" />
            ) : (
              <span className="text-danger fs-12">
                Lưu ý: số tiền tối thiểu là 50,000đ
              </span>
            )}
          </div>
          <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-center mt-2">
            {payment.replace(/[., ]/g, "") >= 50000 ? (
              <button
                className="btn btn-outline-dark m-1 px-4"
                type="submit"
                id="submitProduct"
              >
                Gửi yêu cầu thanh toán
              </button>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

// Component link service.
const dataLink = [
  {
    id: 1,
    title: "View360 - view360.com.vn",
    discription:
      "View360 - Là nền tảng thiết kế và mô phỏng lại không gian, tăng trải nghiệm thăm quan khảo sát và rất phù hợp cho ngành bất động sản, du lịch, phòng trưng bày ...",
    path: "https://view360.com.vn",
    image:
      "https://view360.com.vn/dist/assets/images/brand-logos/anhgif360.gif",
  },
  {
    id: 2,
    title: "Mua nhà hải phòng - muanhahaiphong.com",
    discription:
      "Đăng tin bất động sản chính chủ, kết hợp không gian mô phỏng với view360 chân thực khu vực hải phòng.",
    path: "https://muanhahaiphong.com",
    image: "https://muanhahaiphong.com/uploads/logo_moi_23.08.21.png",
  },
]
function ComponentLinkService() {
  // Mở cửa sổ mới khi click vào link.
  const handleOpenNewLink = (path) => {
    window.open(path, "_blank")
  }
  return (
    <div className="">
      {dataLink.map((item) => {
        return (
          <div className="col-12 mt-3" key={item.id}>
            <div className="d-flex gap-2">
              <img
                src={item.image}
                alt="Công nghệ thực tế ảo 360 độ"
                width="45"
                height="45"
                className="rounded-5 bg-warning op-8"
              />
              <div>
                <span className="fw-6">{item.title}</span>
                <div className="text-muted fs-14">{item.discription}</div>
              </div>
            </div>
            <div className="text-end">
              <a href="#" onClick={() => handleOpenNewLink(item.path)}>
                <button className="btn btn-outline-dark">Chi tiết</button>
              </a>
            </div>
          </div>
        )
      })}
    </div>
  )
}
