import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"

function ModalA({ id, size, modalTitle, theme }) {
    const [sizeModal, setSizeModal] = useState("");
    const [themeModal, setThemeModal] = useState("")

    useEffect(() => {
        if (size) {
            setSizeModal(size)
        } else {
            setSizeModal("")
        }
    }, [sizeModal])
    
    useEffect(() => {
        if (theme) {
          setThemeModal(theme)
        }
    }, [])
    return (
      <div
        className="modal fade"
        id={id}
        tabIndex="-1"
        aria-labelledby="modalXlLabel"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className={"modal-dialog " + sizeModal + ""}>
          <div className="modal-content rounded-4">
            <div className="modal-header">
              <h6 className="modal-title">{modalTitle || "Đẩy thông tin"}</h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">{themeModal}</div>
          </div>
        </div>
      </div>
    )
}

export default ModalA;