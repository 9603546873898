import classNames from "classnames/bind";
import styles from "./Calendar.module.scss"
import { DateWeek } from "../Date";
import { useEffect, useState } from "react";
import moment from "moment";

const cx = classNames.bind(styles)

function CalendarA() {
  const [arrWeeks, setArrWeeks] = useState([]);

  // Get data từ DateWeek
  const getArrWeeks = (arr) => {
      setArrWeeks(arr)
  }

  // Get day.
  const handleGetDay = (day) => {
    console.log(day)
  }

    return (
      <div className={cx("card-body", "border-1", "p-3", "rounded-4", "container")}>
        <div className={cx("d-flex justify-content-end")}>
          <DateWeek resultWeek={getArrWeeks} />
        </div>
        <nav
          className="nav nav-style-1 nav-pills mb-4 nav-justified"
          role="tablist"
        >
          <a
            className="nav-link active"
            data-bs-toggle="tab"
            role="tab"
            aria-current="page"
            href="#mon"
            aria-selected="false"
            tabIndex="-1"
            onClick={() => handleGetDay(arrWeeks[0])}
          >
            <span className="d-block mb-1">
              {arrWeeks.length > 0 && moment(arrWeeks[0]).format("DD")}
            </span>
            <span className="d-block mb-0 op-7 fs-12">Th.2</span>
          </a>
          <a
            className="nav-link"
            data-bs-toggle="tab"
            role="tab"
            href="#tue"
            aria-selected="false"
            tabIndex="-1"
            onClick={() => handleGetDay(arrWeeks[1])}
          >
            <span className="d-block mb-1">
              {arrWeeks.length > 0 && moment(arrWeeks[1]).format("DD")}
            </span>
            <span className="d-block mb-0 op-7 fs-12">Th.3</span>
          </a>
          <a
            className="nav-link"
            data-bs-toggle="tab"
            role="tab"
            href="#wed"
            onClick={() => handleGetDay(arrWeeks[2])}
          >
            <span className="d-block mb-1">
              {arrWeeks.length > 0 && moment(arrWeeks[2]).format("DD")}
            </span>
            <span className="d-block mb-0 op-7 fs-12">Th.4</span>
          </a>
          <a
            className="nav-link"
            data-bs-toggle="tab"
            role="tab"
            href="#thu"
            aria-selected="true"
            onClick={() => handleGetDay(arrWeeks[3])}
          >
            <span className="d-block mb-1">
              {arrWeeks.length > 0 && moment(arrWeeks[3]).format("DD")}
            </span>
            <span className="d-block mb-0 op-7 fs-12">Th.5</span>
          </a>
          <a
            className="nav-link"
            data-bs-toggle="tab"
            role="tab"
            href="#fri"
            aria-selected="true"
            onClick={() => handleGetDay(arrWeeks[4])}
          >
            <span className="d-block mb-1">
              {arrWeeks.length > 0 && moment(arrWeeks[4]).format("DD")}
            </span>
            <span className="d-block mb-0 op-7 fs-12">Th.6</span>
          </a>
          <a
            className="nav-link"
            data-bs-toggle="tab"
            role="tab"
            href="#sat"
            aria-selected="true"
            onClick={() => handleGetDay(arrWeeks[5])}
          >
            <span className="d-block mb-1">
              {arrWeeks.length > 0 && moment(arrWeeks[5]).format("DD")}
            </span>
            <span className="d-block mb-0 op-7 fs-12">Thứ bảy</span>
          </a>
          <a
            className="nav-link"
            data-bs-toggle="tab"
            role="tab"
            href="#sun"
            aria-selected="true"
            onClick={() => handleGetDay(arrWeeks[6])}
          >
            <span className="d-block mb-1">
              {arrWeeks.length > 0 && moment(arrWeeks[6]).format("DD")}
            </span>
            <span className="d-block mb-0 op-7 fs-12">Chủ nhật</span>
          </a>
        </nav>
        <div className="tab-content pt-3 my-3" style={{overflowY: "scroll", height:"268px"}}>
          <div
            className="tab-pane show active border-0 p-0"
            id="mon"
            role="tabpanel"
          >
            <ul className="list-unstyled mb-0 upcoming-events-list">
              <li>
                <div className="d-flex align-items-top justify-conent-between">
                  <div className="flex-fill">
                    <p className="mb-0 fs-14">Hoàng văn hiếu</p>
                    <p className="mb-0 fs-14">0934.222.740</p>
                    <p className="mb-0 text-muted">
                      Tôi cần xem căn hộ Hoàng Huy Small
                    </p>
                  </div>
                  <div>
                    <span className="text-muted">
                      <i className="ri-time-line align-middle me-1 d-inline-block "></i>
                      9:00am - 10:00am
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-top justify-conent-between">
                  <div className="flex-fill">
                    <p className="mb-0 fs-14">Lunch with team members</p>
                    <p className="mb-0 text-muted">Dolores Ait Labore Sit</p>
                  </div>
                  <div>
                    <span className="text-muted">
                      <i className="ri-time-line align-middle me-1 d-inline-block"></i>
                      12:00pm - 12:45am
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-top justify-conent-between">
                  <div className="flex-fill">
                    <p className="mb-0 fs-14">General board meeting</p>
                    <p className="mb-0 text-muted">Golden PArk</p>
                  </div>
                  <div>
                    <span className="text-muted">
                      <i className="ri-time-line align-middle me-1 d-inline-block"></i>
                      4:00pm - 5:30pm
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-top justify-conent-between">
                  <div className="flex-fill">
                    <p className="mb-0 fs-14">Create New Registration Page</p>
                    <p className="mb-0 text-muted">2UA Project</p>
                  </div>
                  <div>
                    <span className="text-muted">
                      <i className="ri-time-line align-middle me-1 d-inline-block"></i>
                      5:00pm - 5:45pm
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
}

export default CalendarA;