import ComponentHeader from "../Component/Header/ComponentHeader"
import PageSearchForMobile from "./PageSearchForMobile"
import MenuFooter from "../MenuFooter/MenuFooter"
function PageSearchMobile() {
    return (
        <>
            {/* Header */}
            <ComponentHeader />
            {/* Container */}
            <PageSearchForMobile />
            {/* Menu Footer */}
            <MenuFooter />
        </>
    )
}

export default PageSearchMobile