import { useEffect, useRef, useState } from "react"
import AppContext from "./CreateContexts"
import Loading from "../../components/Loading"
import io from "socket.io-client"

function ContextData({ children }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const [getDataUser, setGetDataUser] = useState([])
  const [accountBalance, setAccountBalance] = useState([])
  const [loading, setLoading] = useState(true)
  const checkLocal = localStorage.getItem("object")
  let user
  if (checkLocal) {
    user = JSON.parse(localStorage.getItem("object"))
  }
  
  const phone = user ? user.p : ""
  const getToken = user ? user.t : ""
  // khi get data từ sever cần gửi kèm token
  const token = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken}`
    }
  };

  // Dùng để gọi dữ liệu
  useEffect(() => {
    // Kiểm tra kết nối internet
    if (isOnline) {
      // Get data user
      const fetchData = async () => {
        try {
          if (phone !== "") {
            const response = await fetch(
              process.env.REACT_APP_APIGetDataSearchUser + `?keyword=${phone}`,
            )
            const dataRes = await response.json()
            if (dataRes.length > 0) {
              const responsePay = await fetch(
                process.env.REACT_APP_APIGetUserPay + `?only=${dataRes[0].id}`,
              )
              const dataResPay = await responsePay.json()
              setAccountBalance(dataResPay)
            }
            setGetDataUser(dataRes)
          }
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }
      fetchData()
    }
    
  }, [])

  // connect socket
  const socket = useRef();
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER);
    
  }, [])

  // Kiểm tra xem loading data thành công chưa
  if (loading || !isOnline) {
    return <Loading />
  }
  return (
    <AppContext.Provider
      value={{ dataUser: getDataUser, dataBalance: accountBalance, connect: isOnline, socket: socket.current, token: token}}
    >
      {children}
    </AppContext.Provider>
  )
}

export default ContextData
