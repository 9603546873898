import { Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";

import styles from "./Sidebar.module.scss";
const cx = classNames.bind(styles);

function SidebarSimple({ data }) {
    const location = useLocation();
    const currentPath = location.pathname;


    return (
      <Nav>
        {data.map((item, index) => (
          <Nav.Item className="col-12 mb-1" key={index}>
            <Link
              to={item.path}
              className={cx("d-flex align-items-baseline fw-4 rounded-2 font-13 nav-link ",`${item.path === currentPath ? "active" : ""}`)}
            >
              <span className="fs-21 me-3">{item.icon}</span>
              <span className="fs-15">{item.name}</span>
            </Link>
          </Nav.Item>
        ))}
      </Nav>
    )
}

export default SidebarSimple;