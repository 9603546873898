import { memo, useEffect, useState, useRef } from "react"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css" // optional
import { HiOutlinePencilSquare } from "react-icons/hi2"
import classNames from "classnames/bind"
import styles from "./ManagerPath.module.scss"
import { handleMethodEditData } from "../../../handle-reuses/reuses/method"
// phân quyền được truy cập đường dẫn (0:public, 1:user, 2:personnal, 3:admin).
const dataActive = [{ act: 0 }, { act: 1 }, { act: 2 }, { act: 3 }]

const cx = classNames.bind(styles)
function ListPath({ data, resultData, handleUpdate }) {
  const [oldSort, setOldSort] = useState()
  const sortIdOld = useRef() // lấy sort id cũ.

  // Xữ lý sắp xếp
  useEffect(() => {
    sortIdOld.current = oldSort // lấy giá trị cũ thông qua setOldSort
  }, [oldSort])

  const handleClickSortData = (e) => {
    setOldSort(e.target.value)
  }
  const handleEditSortData = (e) => {
    // Cập nhật chuyển đổi vị trí
    const sortCurrent = e.target.value // sort hiện tại
    const idCurrent = e.target.id.replace(/\D/g, "")
    const dataUpdate1 = {
      id: Number(idCurrent),
      sort: sortCurrent,
    }

    //tự động cập nhận dũ liệu từ việc thay đổi của object trùng
    const getDataUpdate2 = data.find(
      (value) => value.sort === Number(sortCurrent),
    )
    getDataUpdate2.sort = sortIdOld.current
    const dataUpdate2 = {
      id: getDataUpdate2.id,
      sort: getDataUpdate2.sort,
    }

    // xử lý update sắp xếp.
    handleMethodEditData(
      dataUpdate1,
      () => {
        handleMethodEditData(
          dataUpdate2,
          () => {
            alert("Cập nhật lại vị trí đường dẫn thành công.")
            // Cập mới data.
            handleUpdate(true)
            setTimeout(() => {
              handleUpdate(false)
            }, 10)
          },
          process.env.REACT_APP_APIEditRowSortUserCateMenu,
        )
      },
      process.env.REACT_APP_APIEditRowSortUserCateMenu,
    )
  }

  // Phân quyền hiển thị đường dẫn.
  const handleRoleActivePath = (e) => {
    const valueInput = {
      id: Number(e.target.id.replace(/\D/g, "")),
      active: e.target.value,
    }
    // xử lý phân quyền show.
    handleMethodEditData(
      valueInput,
      () => {
        alert("Cập nhật quyền truy cập đường dẫn thành công.")
        // Cập mới data.
        handleUpdate(true)
        setTimeout(() => {
          handleUpdate(false)
        }, 10)
      },
      process.env.REACT_APP_APIEditRowActiveUserCateMenu,
    )
  }

  // Xử lý edit path.
  const handleEditPath = async (id_) => {
    try {
      const response = await fetch(process.env.REACT_APP_APIGetUserCateMenu)
      const dataRes = await response.json()
      const filterData = dataRes.find((value) => value.id === id_)
      resultData(filterData) // trả về cho form data cần edit
    } catch (error) {}
  }
  if (data.length === 0) {
    return <div className={cx("p-3")}>Chưa có dữ liệu.</div>
  }

  return (
    <div className="table-responsive">
      <table className="table text-nowrap table-bordered">
        <thead>
          <tr>
            <th scope="col">Sắp xếp</th>
            <th scope="col">Tên đường dẫn</th>
            <th scope="col">Đường dẫn</th>
            <th scope="col">Trạng thái</th>
            <th scope="col">Lựa chọn</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return (
              <tr key={item.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <select
                      style={{ width: "80%" }}
                      className={cx("border-1", "rounded-5")}
                      id={"path" + item.id}
                      onChange={handleEditSortData}
                      onClick={handleClickSortData}
                      ref={sortIdOld}
                    >
                      <option value={item.sort}>{item.sort}</option>
                      {data.map(
                        (value, index) =>
                          value.sort !== item.sort && (
                            <option key={index} value={value.sort}>
                              {value.sort}
                            </option>
                          ),
                      )}
                    </select>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">{item.title}</div>
                </td>
                <td>
                  <span className="badge bg-success-transparent fs-12">
                    {item.path}
                  </span>
                </td>
                <td>
                  <Tippy content="(0: public, 1:user, 2:personnal, 3:admin)">
                    <select
                      style={{ width: "80%" }}
                      className={cx("border-1", "rounded-5")}
                      id={"active" + item.id}
                      onChange={handleRoleActivePath}
                    >
                      <option value={item.active}>{item.active}</option>
                      {dataActive.map(
                        (value, index) =>
                          item.active !== value.act && (
                            <option key={index} value={value.act}>
                              {value.act}
                            </option>
                          ),
                      )}
                    </select>
                  </Tippy>
                </td>
                <td>
                  <div className="hstack gap-2 flex-wrap">
                    <Tippy content="Sửa">
                      <a
                        href="#"
                        className="text-info fs-21 lh-1"
                        onClick={() => handleEditPath(item.id)}
                      >
                        <HiOutlinePencilSquare />
                      </a>
                    </Tippy>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default memo(ListPath)
