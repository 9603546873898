
import classNames from "classnames/bind";
import styles from "./Layout.module.scss"

const cx = classNames.bind(styles)

function LeftMain({themeLeft, themeRight}) {


    return (
      <div className={cx("container", "mt-3")}>
        <div className={cx("row")}>
          <div className={cx("left", "col-xl-3")}>
            <div className={cx("p-3", "border-1", "rounded-3")}>
              {themeLeft}
            </div>
          </div>
          <div className={cx("main", "col-xl-9")}>
            {/* Nội dung của tab được hiển thị dựa trên activeTab */}
            <div className={cx("p-3", "border-1", "rounded-3")}>
              {themeRight}
            </div>
          </div>
        </div>
      </div>
    )
}

export default LeftMain;