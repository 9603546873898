import { useState, useEffect, useContext } from "react"
import classNames from "classnames/bind"

import styles from "../../Dashboard.module.scss"
import LayoutCustomer from "./LayoutCustomer"
import AppContext from "../../../../../handle-reuses/Contexts/CreateContexts"
import { LoadingSpinner } from "../../../../../components/Loading"

const cx = classNames.bind(styles)

function CustomerList() {
  const dataG = useContext(AppContext)
  const dataUser = dataG.dataUser
  const [dataCountCate, setDataCountCate] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    // Get data count cate
    const responseCountCate = await fetch(
      process.env.REACT_APP_APIGetDataCountCateMarketBuyer +
        `?only=${dataUser[0].id}`,
    )
    const dataResCountCate = await responseCountCate.json()
    setDataCountCate(dataResCountCate)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }

  return <LayoutCustomer dataCountCate={dataCountCate} />
}
export default CustomerList
