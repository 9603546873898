import { useState } from "react"
import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import vi from "date-fns/locale/vi"
registerLocale("vi", vi)

// Dùng với useForm 
function DateTime({field}) {
  return (
    <DatePicker
      locale="vi"
      showTimeSelect
      selected={field.value}
      onChange={(date) => field.onChange(date)}
      timeInputLabel="Time:"
      dateFormat="dd/MM/yyyy HH:mm"
      isClearable // nút clear
      minDate={new Date()} // Ngăn chọn ngày đã qua
      placeholderText="Chọn thời gian"
      className="form-control"
    />
  )
}

export default DateTime
