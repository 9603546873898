import classNames from "classnames/bind"
import { FaHeart } from "react-icons/fa6"
import { BsDot } from "react-icons/bs"
import io from "socket.io-client"
import { Link } from "react-router-dom"
import { useEffect, useState, useRef, useContext } from "react"

import styles from "../Mobile.module.scss"
import SearchHorizontal from "../../../components/Search/SearchHorizontal"
import { images } from "../../../assets/images"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"
import {
  handleFormatPrice,
  deleteAccentedText,
} from "../../../handle-reuses/reuses"

const cx = classNames.bind(styles)

function ProductLike() {
  const [valueSearch, setValueSearch] = useState()
  const handleResultSearch = (textInput) => {
    setValueSearch(textInput)
  }
  return (
    <div className={cx("wrapper_dash")}>
      <div className={cx("mt-5")}>
        {/* Search */}
        <div className={cx("mb-3")}>
          <SearchHorizontal
            result={handleResultSearch}
            placeholder="Tên, số điện thoại, tên đường phố"
            className="rounded-3"
          />
        </div>
        {/* Content */}
        <div>
          <ListProductLike valueSearch={valueSearch} />
        </div>
      </div>
    </div>
  )
}

export default ProductLike

// List product like.
function ListProductLike({ valueSearch }) {
  const { dataUser } = useContext(AppContext)
  const [dataProLike, setDataProLike] = useState([])
  const [arrSavedId, setArrSavedId] = useState([])
  const dataProRef = useRef([])
  const [loading, setLoading] = useState(true)
  const socket = useRef()
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
    // Get data product saved.
    socket.current.emit("client-saved", dataUser[0].id)
  }, [])

  useEffect(() => {
    socket.current.on("server-saved", function (data) {
      const filterData = data.filter((item) => {
        return item.status === 1
      })
      let arrSimpleid = [],
        arrSaved = []
      for (let i = 0; i < filterData.length; i++) {
        arrSimpleid.push(filterData[i].simpleid)
        socket.current.emit("client-detail-product", filterData[i].simpleid)
        arrSaved.push({
          id: filterData[i].id,
          simpleid: filterData[i].simpleid,
        })
      }
      setArrSavedId(arrSaved)
    })
    socket.current.on("server-detail-product", (data) => {
      setDataProLike((old) => [...old, ...data])
      // Dùng cho update pro.
      dataProRef.current.push(data[0])
    })
  }, [])

  // Kiểm tra hủy thích.
  const handleCheckCancelLike = (result) => {
    let updatePro = []
    for (let i = 0; i < result.length; i++) {
      let filterPro = dataProRef.current.filter(
        (item) => item.id === result[i].simpleid,
      )
      updatePro.push(filterPro[0])
    }
    setDataProLike(updatePro)
    // Gán mảng tổng mới
    dataProRef.current = updatePro
    setArrSavedId(result)
  }

  // Search data pro.
  useEffect(() => {
    if (valueSearch !== "") {
      const dataSearchPro = dataProRef.current.filter((item) => {
        return (
          item.hoten.toLowerCase().includes(valueSearch.toLowerCase()) ||
          item.address.toLowerCase().includes(valueSearch.toLowerCase()) ||
          item.phone
            .replace(/[., ]/g, "")
            .includes(valueSearch.replace(/[., ]/g, ""))
        )
      })
      setDataProLike(dataSearchPro)
    }
  }, [valueSearch])

  return (
    <ListItemProLike
      data={dataProLike}
      arrSaved={arrSavedId}
      result={handleCheckCancelLike}
    />
  )
}

// List item product like.
function ListItemProLike({ data, arrSaved, result }) {
  const { dataUser } = useContext(AppContext)
  const socket = useRef()
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER)
  }, [])
  const noImagePro = (
    <img
      src={images.noImagePro}
      alt=""
      width={65}
      height={65}
      className={cx("me-3")}
    />
  )
  // Xử lý edit saved.
  const handleEditSaved = (id_) => {
    let checkCancel = window.confirm("Bạn muốn hủy trạng thái đã lưu ?")
    if (checkCancel) {
      const params = {
        id: id_,
        status: 0,
        author: dataUser[0].id,
      }
      socket.current.emit("client-edit-status-saved", params)
      // update data pro
      socket.current.on("server-edit-status-saved", (dataUpdate) => {
        let filterData = dataUpdate.filter((item) => item.status === 1)
        let arrSavedUpdate = []
        for (let i = 0; i < filterData.length; i++) {
          arrSavedUpdate.push({
            id: filterData[i].id,
            simpleid: filterData[i].simpleid,
          })
        }
        result(arrSavedUpdate)
      })
    }
  }

  // count view khi chạm vào phần tử.
  const handleCountview = (simpleId, countViewOld) => {
    // Tăng view khi click.
    const params = {
      id: simpleId,
      countview: Number(countViewOld) + 1,
    }
    socket.current.emit("client-click-views", params)
  }
  // Mở detail.
  const handleOpenDetail = (simpleId, countViewOld) => {
    // Tăng view khi click.
    const params = {
      id: simpleId,
      countview: Number(countViewOld) + 1,
    }
    socket.current.emit("client-click-views", params)
  }

  // Hiển thị danh sách
  return data.map((item) => {
    // filter id saved.
    let savedId = arrSaved.find((saved) => {
      return saved.simpleid === item.id
    })
    // console.log(savedId)
    // List images.
    let listImages = []
    if (item.images) {
      listImages = item.images.split(",")
    }
    // format url detail.
    const formatCateUrl = deleteAccentedText(item.cate).toLowerCase()
    const cate = formatCateUrl.replace(/[ ]/g, "-")
    const formatAddress = deleteAccentedText(item.address).toLowerCase()
    const address = formatAddress.replace(/[ ]/g, "-")

    return (
      <div
        className={cx("bg-itemPro", "mb-3")}
        key={item.id}
        onTouchStart={() => handleCountview(item.id, item.countview)}
      >
        <div className={cx("d-flex", "itemProLike")}>
          {listImages.length > 0 ? (
            <img
              src={listImages[0]}
              alt=""
              width={65}
              height={65}
              className={cx("me-3")}
            />
          ) : (
            noImagePro
          )}
          <div className={cx("col-9 d-flex justify-content-between")}>
            <Link
              to={
                "/" + cate + "-" + address + "/" + item.alias + "-pr" + item.id
              }
              onClick={() => handleOpenDetail(item.id, item.countview)}
            >
              <h6 className={cx("mb-1")}>{item.address}</h6>
              <div className={cx("d-flex justify-content-between")}>
                <div style={{ color: "#0289ff" }}>
                  <span>{item.cate}</span>
                  <BsDot />
                  <span className={cx("fw-5")}>
                    {handleFormatPrice(Number(item.price))}
                  </span>
                </div>
              </div>
              <p className="fs-14 mb-0 text-muted">
                <span>{item.districts}</span>
                <BsDot />
                <span>{item.city}</span>
                {/* <TextLimit text={item.title} number="30" /> */}
              </p>
            </Link>
            <button
              className={cx("border-0 rounded-5 bg-transparent")}
              style={{ color: "#ff6174" }}
              onClick={() => handleEditSaved(savedId.id)}
            >
              <FaHeart className="fs-18" />
            </button>
          </div>
        </div>
      </div>
    )
  })
}
