import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import PageProduct from "./PageProduct"
import MenuFooter from "../MenuFooter/MenuFooter"

function PageProductMobile({loading, dataEstates, dataBooking}) {
    return (
        <>
            {/* Header */}
            <HeaderDashboardMobile namePage="Sản phẩm" />
            {/* Main */}
            <PageProduct loading={loading} dataEstates={dataEstates} dataBooking={dataBooking} />
            {/* MenuFooter */}
            <MenuFooter />
        </>
    );
}

export default PageProductMobile