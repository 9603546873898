import SearchSimple from "../../../components/Search/SearchSimple"
import { useState, useEffect } from "react"
import { LoadingSpinner } from "../../../components/Loading"
import ListPay from "./ListPay"

function SearchPayment() {
  const [valueInput, setValueInput] = useState("")
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  // Get value search.
  const handleGetValue = (input) => {
    if (input) {
      setValueInput(input)
    }
  }
  //   Get data.
  const fetchData = async () => {
    const response = await fetch(
      process.env.REACT_APP_APIGetSearchUserPayHis + `?keyword=${valueInput}`,
    )
    const dataRes = await response.json()
    setLoading(false)
    console.log(dataRes)
    setData(dataRes)
  }

  // Quản lý get data.
  useEffect(() => {
    if (valueInput) {
      setLoading(true)
      setTimeout(() => {
        fetchData()
      }, 500)
    } else {
      setLoading(false)
    }
  }, [valueInput])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className="col-12">
      <SearchSimple result={handleGetValue} />
      {data && (
        <div>
          {data.length > 0 ? (
            <ListPay data={data} id="searchPay" />
          ) : (
            <div className="text-center mt-3">
              Tôi không tìm thấy kết quả phù hợp.
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchPayment
