import { useState } from "react"
import { IoAddOutline } from "react-icons/io5"

import HeaderDashboardMobile from "../Component/Header/ComponentHeaderDash"
import MenuFooter from "../MenuFooter/MenuFooter"
import ListCustomer from "./ListCustomer"
import ModalReact from "../../../components/Modal/ModalReact"
import FormAddCustomer from "../../pc/Dashboard/Component/Khachhang/FormAddCustomer"

function PageCustomerMobile() {
    const [addSuccess, setAddSuccess] = useState(false)
    const handleAddCusSuccess = (result) => {
        setAddSuccess(result)
    }
    return (
        <>
            {/* Header */}
            <HeaderDashboardMobile namePage="Khách hàng" layoutRight={<HeaderLayoutRight result={handleAddCusSuccess}/>}/>
            {/* Content */}
            <ListCustomer addSuccess={addSuccess} />
            {/* Footer */}
            <MenuFooter />
            
        </>
    )
}
export default PageCustomerMobile

// Layout right.
function HeaderLayoutRight({result}) {
    const [showModal, setShowModal] = useState(false)
    const handleShowModal = () => {
        setShowModal(true)
    }
    const handleClose = () => setShowModal(false)
    
    // Xử lý khi add success.
    const handleAddSuccess = (result_) => {
        if (result_) {
            setShowModal(false)
            result(result_)
        }
    }
    return (
        <>
            <div className="d-flex">
                <button
                    className="border-0 rounded-5 fs-18"
                    style={{ width: "35px", height: "35px" }}
                    onClick={handleShowModal}
                >
                    <IoAddOutline className="fs-21" />
                </button>
            </div>
            {/* Modal form add customer */}
            <ModalReact
                modalTitle="Thêm khách hàng"
                showModal={showModal}
                handleClose={handleClose}
                theme={<FormAddCustomer result={handleAddSuccess} />}
            />
        </>
    );
}