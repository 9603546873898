import { handleMethodEditData } from "../reuses/method"
import { handleUploadFileImage, handleSetNameFileImages } from "../reuses/UploadFile"

function handleEditDataUser(objectInput, objectFile, reset) {
    console.log(objectInput)
    console.log(objectFile)
    // Xử lý edit data.
    handleEditUser(objectInput, objectFile, reset)
}




// Xử lý edit data.
async function handleEditUser(objectInput, objectFile, reset) {
    let valueInput
    // Set path avatar.
    if (objectFile) {
     const path = handleSetNameFileImages(objectFile[0].name, "users")
      
        objectInput.avatar = path;
        valueInput = objectInput
    } else {
        valueInput = objectInput
    }

    await handleUploadFileImage(objectFile, process.env.REACT_APP_APICreateRowMarketUploadFileUser)

    // Xử lý edit.
    await handleMethodEditData(valueInput, () => {
        alert("Dữ liệu người dùng đã được cập nhật.")
        reset()
    }, process.env.REACT_APP_APIEditDataMarketUser)
}



// Edit password.
function handleEditPassword(objectInput, reset) {
    if (objectInput) {
        handleMethodEditData(objectInput, () => {
            alert("Cập nhật mật khẩu thành công.")
            reset()
       }, process.env.REACT_APP_APIEditPasswordMarketUser)
   }
}


export { handleEditDataUser, handleEditPassword }