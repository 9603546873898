import { useEffect, useState, useRef, useContext } from "react"
import { useForm } from "react-hook-form"
import { images } from "../../../assets/images"
import AppContext from "../../../handle-reuses/Contexts/CreateContexts"

import { handleEditDataUser } from "../../../handle-reuses/user/Method"

function FormEditUser({ idUser }) {
  const { dataUser } = useContext(AppContext)
  const [dataNewUser, setDataNewUser] = useState()
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm()
  const [pathAvatar, setPathAvatar] = useState("")
  const linkRef = useRef()
  const [objectFile, setObjectFile] = useState()

  // Gợi ý
  const [role, setRole] = useState()
  const [dataProject, setDataProject] = useState()

  // thêm ảnh
  const handleGetFileImage = () => {
    linkRef.current.click()
  }
  const handleUpdateAvatar = (e) => {
    const file = e.target.files
    if (file.length > 0) {
      const path = Array.from(file).map((files) => URL.createObjectURL(files))
      setPathAvatar(path[0])
      setObjectFile(file)
    }
  }
  useEffect(() => {
    // Clearnup path
    return () => {
      if (pathAvatar) {
        URL.revokeObjectURL(pathAvatar)
      }
    }
  }, [pathAvatar])

  // Get data.
  useEffect(() => {
    const fetchData = async () => {
      // Data role.
      const response1 = await fetch(process.env.REACT_APP_APIGetUserRole)
      const dataRes1 = await response1.json()
      setRole(dataRes1)
      // data User project.
      const response2 = await fetch(process.env.REACT_APP_APIGetUserProject)
      const dataRes2 = await response2.json()
      setDataProject(dataRes2)

      const response = await fetch(
        process.env.REACT_APP_APIGetDataSearchUser + `?only=${idUser}`,
      )
      const dataRes = await response.json()
      const objectUser = dataRes[0]
      setDataNewUser(objectUser)
    }

    fetchData()
  }, [])

  // Gán giá trị edit
  useEffect(() => {
    const filterUser = dataUser.find((row) => row.id === Number(idUser))
    if (filterUser) {
      setDataNewUser(filterUser)
      setPathAvatar(filterUser.avatar)
    }
  }, [])

  const onSubmit = (data) => {
    delete data.avatar
    data.id = idUser

    if (!objectFile) {
      data.avatar = dataNewUser.avatar
    }
    handleEditDataUser(data, objectFile, reset)
  }

  if (!role && !dataProject) {
    return <div className="text-center">Đang tải gợi ý...</div>
  }

  if (!dataNewUser) {
    return <div className="text-center">Đang tải dữ liệu...</div>
  }

  return (
    <div className="p-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gy-3 pb-3">
          <div className="col-xl-12">
            <input
              type="file"
              {...register("avatar")}
              onChange={handleUpdateAvatar}
              ref={linkRef}
              style={{ display: "none" }}
            />
            <div className="row">
              <div className="position-relative col-3 d-flex flex-direction-column align-items-center">
                <img
                  src={pathAvatar === "" ? images.noImage : pathAvatar}
                  width={100}
                  height={100}
                  className="rounded-mx"
                  alt="Ảnh đại diện"
                  style={{ width: "100px", height: "100px" }}
                />
                <button
                  type="button"
                  onClick={handleGetFileImage}
                  className="mt-3 rounded-3 border-1"
                >
                  Chọn ảnh
                </button>
              </div>
              <div className="col-9">
                <div className="col-12">
                  <label className="form-label mb-1">Họ tên</label>

                  <input
                    defaultValue={dataNewUser ? dataNewUser.hoten : ""}
                    {...register("hoten", { required: true })}
                    className="form-control"
                    placeholder="Ví dụ: Hoàng Văn A..."
                  />
                  {errors.hoten && (
                    <span className="text-danger fs-9">
                      *Bạn chưa đặt tên cho mình kìa !
                    </span>
                  )}
                </div>
                <div className="col-xl-12 mt-3">
                  <label className="form-label mb-1">Giới tính</label>
                  <select
                    defaultValue={dataNewUser ? dataNewUser.gioitinh : ""}
                    {...register("gioitinh", { required: true })}
                    className="form-control"
                  >
                    <option value="">Chọn giới tính</option>
                    <option value="Nam">Nam</option>
                    <option value="Nữ">Nữ</option>
                  </select>
                  {errors.gioitinh && (
                    <span className="text-danger fs-9">
                      *Bạn chưa chọn giới tính
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <label className="form-label mb-1">Phân quyền</label>
            <select
              defaultValue={dataNewUser ? dataNewUser.roleid : ""}
              {...register("roleid", { required: true })}
              className="form-control"
            >
              <option value="">Chọn quyền</option>
              {role &&
                role.map((value) => {
                  return (
                    <option key={value.id} value={value.id}>
                      {value.role}
                    </option>
                  )
                })}
            </select>
          </div>
          <div className="col-xl-6">
            <label className="form-label mb-1">Quản lý dự án</label>
            <select
              defaultValue={dataNewUser ? dataNewUser.projectid : ""}
              {...register("projectid", { required: true })}
              className="form-control"
            >
              <option value="">---</option>
              {dataProject &&
                dataProject.map((value) => {
                  return (
                    <option key={value.id} value={value.id}>
                      {value.title}
                    </option>
                  )
                })}
            </select>
          </div>
          <div className="col-xl-6">
            <label className="form-label mb-1">Khu vực hoạt động</label>
            <select
              defaultValue={dataNewUser ? dataNewUser.area : ""}
              {...register("area", { required: true })}
              className="form-control"
            >
              <option value="">---</option>
              <option value="Toàn quốc">Toàn quốc</option>
              <option value="Hải phòng">Hải phòng</option>
              <option value="Hà nội">Hà nội</option>
            </select>
          </div>
          <div className="col-xl-6">
            <label className="form-label mb-1">Đặc quyền đăng tin</label>
            <select
              defaultValue={dataNewUser ? dataNewUser.post_free : ""}
              {...register("post_free", { required: true })}
              className="form-control"
            >
              <option value="">---</option>
              <option value="1">Đăng miễn phí</option>
              <option value="0">Đăng tính phí</option>
            </select>
          </div>
        </div>
        <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-end">
          <button className="btn btn-primary-light m-1" type="submit">
            Lưu lại<i className="bi bi-plus-lg ms-2"></i>
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormEditUser
